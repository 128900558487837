import { Component, OnInit } from '@angular/core';
import { FundraiserService } from 'src/app/shared/services/fundraiser/fundraiser.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-fundraiser-dashboard',
  templateUrl: './fundraiser-dashboard.component.html',
  styleUrl: './fundraiser-dashboard.component.scss',
})
export class FundraiserDashboardComponent implements OnInit {
  public utilitiesService = UtilitiesService;

  public topTeamPopup: boolean = false;
  public topFundraiserPopup: boolean = false;

  public activeTab: number = 1;

  public today: Date = new Date();
  public oneYearAgo: Date = new Date(
    this.today.getFullYear() - 1,
    this.today.getMonth(),
    this.today.getDate(),
    this.today.getHours(),
    this.today.getMinutes(),
    this.today.getSeconds(),
    this.today.getMilliseconds()
  );

  public fundraiserDetails: any;

  public donations: any[] = [];
  public donationsFiltered: any[] = [];

  public topTeams: any[] = [];
  public allTopTeams: any[] = [];
  public topTeamsPageNo: number = 0;

  public topFundraisers: any[] = [];
  public allTopFundraisers: any[] = [];
  public topFundraiserPageNo: number = 0;

  public disableTopTeamLoadMore: boolean = false;
  public disableTopFundraiserLoadMore: boolean = false;

  constructor(private readonly fundraiserService: FundraiserService) {}

  ngOnInit(): void {
    this.getFundraiserDetails(this.oneYearAgo, this.today);
    this.getTopTeams();
    this.getTopFundraisers();
  }

  private getFundraiserDetails(startDate: Date, endDate: Date): void {
    this.fundraiserService
      .getFundraiserDashboardData(startDate, endDate)
      .subscribe((result: any) => {
        const { total_fund_raised, total_members, total_team } = result;
        this.fundraiserDetails = {
          total_fund_raised,
          total_members,
          total_team,
        };

        this.donations = result.donations;
        this.getTeamChartData(result.donations);
      });
  }

  private getTeamChartData(donations: any[]): void {
    const chartMap = new Map();
    donations.forEach((donation: any) => {
      const month = this.formatDate(donation.donation_month);
      if (chartMap.has(month)) {
        chartMap.set(month, chartMap.get(month) + donation?.amount);
      } else {
        chartMap.set(month, donation?.amount);
      }
    });

    this.donations = [];
    chartMap.forEach((value, key) => {
      this.donations.push({ key, value });
    });

    this.donations.sort(
      (a, b) => new Date(a.key).getTime() - new Date(b.key).getTime()
    );
    this.donationsFiltered = JSON.parse(JSON.stringify(this.donations));
  }

  private formatDate(value: Date): string {
    const date = new Date(value);
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);

    return `${month}, ${year}`;
  }

  public getTopTeams(): void {
    this.topTeamsPageNo += 1;
    this.fundraiserService
      .getAllTeamsPaginated(this.topTeamsPageNo, 10)
      .subscribe((result: any) => {
        result.data.forEach((element) => {
          element.team_logo = UtilitiesService.getUrl(element.team_logo);
        });
        this.allTopTeams = this.allTopTeams.concat(result.data);
        this.topTeams = this.allTopTeams.slice(0, 2);

        this.disableTopTeamLoadMore = this.allTopTeams?.length === result.total;
      });
  }

  public getTopFundraisers(): void {
    this.topFundraiserPageNo += 1;
    this.fundraiserService
      .getTopFundraisers(this.topFundraiserPageNo, 10)
      .subscribe((result: any) => {
        result?.data.forEach((element) => {
          element.profile_img = this.utilitiesService.getUrl(
            element.profile_img
          );
        });

        this.allTopFundraisers = this.allTopFundraisers.concat(result.data);
        this.topFundraisers = this.allTopFundraisers.slice(0, 5);

        this.disableTopFundraiserLoadMore =
          this.allTopFundraisers?.length === result.total;
      });
  }

  public filterDonations(e: any): void {
    this.donationsFiltered = this.donations.filter(
      (donation) =>
        new Date(donation?.created_at) >= new Date(e?.value[0]) &&
        new Date(donation?.created_at) <= new Date(e?.value[1])
    );
    this.getFundraiserDetails(new Date(e?.value[0]), new Date(e?.value[1]));
  }

  public checkScroll(): boolean {
    const scrollableDiv = document.getElementById('scrollableDiv');
    return scrollableDiv.scrollHeight > scrollableDiv.clientHeight;
  }
}
