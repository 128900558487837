import { Injectable } from '@angular/core';
import { UrlEnum } from '../../enum/url.enum';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { map, Observable } from 'rxjs';
import { ProgramModel } from '../../model/program.model';
import { ProjectModel } from '../../model/project.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private projectApi = `${UrlEnum.SHOHAY_NGO}/project`;
  private programApi = `${UrlEnum.SHOHAY_NGO}/program`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public createProject(body: ProjectModel): Observable<any> {
    return this.httpRequestService
      .post(this.projectApi, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getProgrammeByNgoId(NgoId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.programApi}/get-by-ngo/${NgoId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createProgram(body: ProgramModel): Observable<any> {
    return this.httpRequestService
      .post(this.programApi, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getProjectsByNgoId(NgoId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.projectApi}/get-by-ngo/${NgoId}`)
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) =>
          x.sort(
            this.utilitiesService.dynamicSortWithDate('created_at', 'desc')
          )
        )
      );
  }

  public deleteProject(id: string): Observable<any> {
    return this.httpRequestService
      .delete(`${this.projectApi}/delete/${id}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getProjectById(Id: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.projectApi}/get-by-id/${Id}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateProject(id: string, body: ProjectModel): Observable<any> {
    return this.httpRequestService
      .patch(`${this.projectApi}/update/${id}`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
