import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from 'src/app/shared/components/not-found/not-found.component';
import { CorporateCampaignListComponent } from './corporate-campaign/corporate-campaign-list/corporate-campaign-list.component';
import { CorporateCreateCampaignComponent } from './corporate-campaign/corporate-create-campaign/corporate-create-campaign.component';
import { CorporateEmployeeManagementComponent } from './corporate-employee-management/corporate-employee-management.component';
import { CorporateInviteEmployeeComponent } from './corporate-invite-employee/corporate-invite-employee.component';
import { CorporateCreateEmployeeAccountComponent } from './corporate-create-employee-account/corporate-create-employee-account.component';

const routes: Routes = [
  {
    path: '',
    //component: CorporateCampaignListComponent,
    redirectTo: '/corporate/campaigns/all-campaigns',
    pathMatch: 'full',
  },
  {
    path: 'campaigns/all-campaigns',
    component: CorporateCampaignListComponent,
    pathMatch: 'full',
  },
  {
    path: 'campaigns/create',
    component: CorporateCreateCampaignComponent,
    pathMatch: 'full',
  },
  {
    path: 'employee-management',
    component: CorporateEmployeeManagementComponent,
    pathMatch: 'full',
  },
  {
    path: 'employee-management/create-account',
    component: CorporateCreateEmployeeAccountComponent,
    pathMatch: 'full',
  },
  {
    path: 'employee-management/invite-employee',
    component: CorporateInviteEmployeeComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CorporateRoutingModule {}
