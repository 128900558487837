import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxTabPanelComponent } from 'devextreme-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { CampaignDonationModel } from 'src/app/shared/model/campaign-donation.model';
import { pledgeDonationModel, pledgePaymentModel } from 'src/app/shared/model/pledge-donation.model';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { FundraiserService } from 'src/app/shared/services/fundraiser/fundraiser.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-donation-summary-shared',
  templateUrl: './donation-summary-shared.component.html',
  styleUrl: './donation-summary-shared.component.scss',
})


export class DonationSummarySharedComponent {
  @Input() campaign: CampaignDonationModel = new CampaignDonationModel();
  @Input() donationSummary: any;
  @Input() ngo_id: string;
  @Input() project_id: string;
  @Input() campaign_id: string;
  @Input() isCampaignHasData: boolean;
  @ViewChild('tabPanel', { static: false }) tabPanel: DxTabPanelComponent;

  public pledgeDonationModel : pledgeDonationModel = new pledgeDonationModel();
  public pledgePaymentModel : pledgePaymentModel = new pledgePaymentModel();

  public pledgeList: any[] = [
    { name: 'Farhan Ahmed', phone: '+0178382738', date: '09-06-2024' },
    { name: 'Farhan Ahmed', phone: '+0178382738', date: '09-06-2024' },
    { name: 'Farhan Ahmed', phone: '+0178382738', date: '09-06-2024' },
    { name: 'Farhan Ahmed', phone: '+0178382738', date: '09-06-2024' },
    { name: 'Farhan Ahmed', phone: '+0178382738', date: '09-06-2024' },
  ];

  public paymentMethod: any[] = [
    'Bank Transfer',
    'Bkash',
    'Nagad',
    'Rocket',
    'Cash',
  ];

  public donationList: any;

  private donationListFetched: boolean = false;
  public donationListPopUp = false;

  public currentDonationList: any;
  public activeFilter: string = 'recent';

  public activeTab = 0;
  public pledgeListPopup: boolean = false;
  public pledgeDetailsPopup: boolean = false;
  public isSuccessful: boolean = false;
  public rejected: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly donationService: DonationService,
    private readonly utilitiesService: UtilitiesService,
    private readonly fundraiserService: FundraiserService,
    private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService
  ) {}

  public seeAll() {
    this.donationListPopUp = true;
    if (!this.donationListFetched) {
      this.utilitiesService.showSpinner(true);
      this.donationService
        .getDonationListByCampaign(
          this.ngo_id,
          this.project_id,
          this.campaign_id
        )
        .subscribe({
          next: (result) => {
            this.donationListSuccess(result);
          },
          error: (err) => {
            this.utilitiesService.showSpinner(false);
          },
        });
    } else {
      this.filterDonations('recent');
    }
  }

  public closePopup() {
    this.donationListPopUp = false;
  }

  public closePledgePopup() {
    this.pledgeListPopup = false;
  }
  public closePledgeDetailsPopup() {
    this.pledgeDetailsPopup = false;
  }

  public pledgePopHiding(tabPanel: DxTabPanelComponent) {
    this.pledgeListPopup = false;
    this.activeTab = 0;
    this.tabPanel.instance.option('selectedIndex', this.activeTab);
  }

  public pledgeDetailsPop() {
    this.pledgeListPopup = false;
    this.pledgeDetailsPopup = true;
  }

  public backToList() {
    this.pledgeDetailsPopup = false;
    this.pledgeListPopup = true;
  }

  public rejectPopup() {
    this.pledgeDetailsPopup = false;
    this.rejected = true;
  }
  public confirmPopUp() {
    this.pledgeDetailsPopup = false;
    this.isSuccessful = true;
  }
  public cancelSuccessPopup() {
    this.isSuccessful = false;
  }
  public cancelRejectPopup() {
    this.rejected = false;
  }
  private donationListSuccess(result: any) {
    this.utilitiesService.showSpinner(false);
    this.donationList = result?.donations;
    this.donationListFetched = true;
    this.filterDonations('recent');
  }

  public filterDonations(type: 'recent' | 'top') {
    this.activeFilter = type;
    if (type === 'recent') {
      this.currentDonationList = this.donationList.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    } else if (type === 'top') {
      this.currentDonationList = this.donationList.sort(
        (a, b) => b.amount - a.amount
      );
    }
  }

  public getDonationTime(createdAt: string): string {
    const donationDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDiffInMs = currentDate.getTime() - donationDate.getTime();
    const hours = Math.floor(timeDiffInMs / (1000 * 60 * 60));
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''}`;
    } else {
      return `${hours + 1} hour${hours > 1 ? 's' : ''}`;
    }
  }

  public navigateToCampaignDonation(campaign: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: campaign.id,
      ngo_id: campaign?.ngo_id,
      project_id: campaign?.project_id,
    });
    this.router.navigate(['/donate-to-campaign'], {
      queryParams: { data: encryptedParams },
    });
  }

  public onTabChange(event: any) {
    this.activeTab = event.component.option('selectedIndex');
    // if(this.activeTab === 1){
    //   this.pledgeListPopup = !this.pledgeListPopup;
    // }
  }

  public onTabClick(event: any) {
    const target = event.target as HTMLElement;
    if (target && target.textContent?.trim() === 'Pledging') {
      this.popUpOpening();
    }
  }

  public popUpOpening() {
    this.pledgeListPopup = true;
  }

  public onPaymentMethodChange(selectedMethod: string): void {
  this.pledgePaymentModel.payment_method = selectedMethod;
}
}
