import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { NgoCampaignService } from 'src/app/shared/services/ngo-campaign/ngo-campaign.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrl: './campaigns-list.component.scss',
})
export class CampaignsListComponent {
  public searchIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/search_icon.svg',
    stylingMode: 'text',
    hint: 'Search',
    onClick: () => {
      this.searchCampaign({});
    },
  };

  public crossIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/cross-icon.svg',
    stylingMode: 'text',
    hint: 'Clear',
    onClick: () => {
      this.searchText = null;
      this.getAllCampaigns(1, 6);
    },
  };

  public size: number = 6;
  public page: number = 1;
  private totalCampaigns: number = 0;
  public isShowMoreDisabled: boolean = false;

  public searchText: string;

  public allCampaignTitles: any[] = [];
  public campaignList: any[] = [];
  public filteredCampaigns: any[] = [];

  public activeTabIndex = 0;

  constructor(
    private readonly router: Router,
    private readonly spinner: NgxSpinnerService,
    private readonly utilitiesService: UtilitiesService,
    private readonly ngoCampaignService: NgoCampaignService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getAllCampaigns(1, 6);
    this.getAllNgoDonationCampaignTitles();
  }

  public onTabChange(event: any) {
    this.activeTabIndex = event.component.option('selectedIndex');
    this.filterCampaigns();
  }

  public filterCampaigns(): void {
    const statuses = ['RUNNING', 'SUSPENDED', 'STOPPED'];
    this.filteredCampaigns = this.campaignList.filter(
      (campaign) => campaign.status === statuses[this.activeTabIndex]
    );
  }

  public getAllCampaigns(page: number, size: number): void {
    this.page = page;
    this.size = size;

    this.spinner.show();
    this.ngoCampaignService.getAllNgoDonationCampaign(page, size).subscribe({
      next: (result: any) => {
        this.spinner.hide();
        this.onSuccess(result);
      },
      error: (error: any) => {
        this.onError(`${error.message}`, `${error.name}`);
      },
    });
  }

  private getAllNgoDonationCampaignTitles(): void {
    this.ngoCampaignService.getAllNgoDonationCampaignTitles().subscribe({
      next: (result: any) => {
        this.allCampaignTitles = result;
      },
      error: (error: any) => {
        this.onError(`${error.message}`, `${error.name}`);
      },
    });
  }

  public async searchCampaign(e: any) {
    if (this.searchText) {
      const idsArray = await this.getSearchedCampaignIds(this.searchText);

      this.ngoCampaignService
        .getNgoCampaignsByIds(idsArray)
        .subscribe((result: any) => {
          this.isShowMoreDisabled = true;
          this.campaignList = result;
        });
    } else {
      this.getAllCampaigns(1, 6);
    }
  }

  private getSearchedCampaignIds(text: string): string[] {
    const lowerText = text.toLowerCase();
    return this.allCampaignTitles
      .filter((item) => item.title_subtitle.includes(lowerText))
      .map((item) => item.id);
  }

  public selectCampaign(e: any): void {
    if (e.selectedItem) {
      const campaign = e.selectedItem;
      const encryptedParams = this.localStorageService.encryptObjectUrl({
        ngo_id: campaign.ngo_id,
        project_id: campaign.project_id,
        id: campaign.id,
      });
      this.router.navigate(['/admin/campaign-management/summary'], {
        queryParams: { data: encryptedParams },
      });
    }
  }

  private onSuccess(res: any) {
    const transformedCampaigns = res.data;

    this.spinner.hide();
    if (this.page === 1) {
      this.campaignList = transformedCampaigns;
      this.filterCampaigns();
      this.page = this.page + 1;
    } else {
      this.campaignList.push(...transformedCampaigns);
      this.filterCampaigns();
    }
    this.totalCampaigns = res.total;
    if (this.totalCampaigns <= this.campaignList.length) {
      this.isShowMoreDisabled = true;
    }
  }

  private onError(errorMessage: string, title: string): void {
    this.spinner.hide();
    this.utilitiesService.showSwalWithToast(
      `${title}`,
      `${errorMessage} , Something went wrong `,
      'error'
    );
  }

  public formatDate(startdate: string, endDate: string): string {
    const formattedStartDate = this.utilitiesService.formattedDateddMMyyyy(
      startdate,
      'dd-mm-yyyy',
      ' '
    );
    const formattedEndDate = this.utilitiesService.formattedDateddMMyyyy(
      endDate,
      'dd-mm-yyyy',
      ' '
    );

    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  public navigateToCampaignSummary(campaign: any) {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: campaign.id,
      ngo_id: campaign?.ngo_id,
      project_id: campaign?.project_id,
    });
    this.router.navigate(['/admin/campaign-management/summary'], {
      queryParams: { data: encryptedParams },
    });
  }
}
