<div class="progress-container">
  <div class="progress-bar" [style.width]="getScrollProgress() + '%'"></div>
</div>

<div class="article_body" *ngIf="newsArticle">
  <div class="title_img_section">
    <div class="title_body">
      <div class="type">
        <button class="type_button">
          {{ newsArticle?.blog_type }}
        </button>
      </div>
      <div class="title">
        <p class="fw-bold">{{ newsArticle.title }}</p>
      </div>
      <div class="details">
        <div class="logo-publisher">
          <img
            src="./../../../assets/images/article-news/icon/Group 39512.svg"
            alt="Author Logo"
            class="article-title-image"
          />
        </div>
        <div class="publisher">
          <p>BY {{ newsArticle.author_name }}</p>
        </div>
        <div class="date">
          <ul>
            <li>
              {{ newsArticle.createdAt | date : "MMM dd, yyyy" }}
            </li>
          </ul>
        </div>
        <div class="time">
          <ul>
            <li>
              {{ newsArticle.reading_time }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="img_body">
      <img
        [src]="newsArticle?.cover_photo"
        alt="Article Image"
        class="article-img-title"
      />
    </div>
  </div>

  <div class="article_body_section">
    <div
      *ngIf="newsArticle?.blog_type === newsArticleTypeEnum.ARTICLE"
      class="table-of-contents-section"
    >
      <p class="table-header">Table of Contents</p>
      <!-- for mobile -->
      <select
        class="table-dropdown"
        [(ngModel)]="activeSectionId"
        (ngModelChange)="onChange($event)"
      >
        <option class="option" value="Table of Content">
          Table of Contents
        </option>
        <option
          class="option"
          *ngFor="let section of newsArticle.table_of_contents"
          [value]="section.content_order"
          [selected]="section.content_order === activeSectionId"
        >
          {{ section.title }}
        </option>
      </select>
      <!-- for mobile -->

      <p
        class="table-section"
        *ngFor="let section of newsArticle.table_of_contents"
      >
        <a
          href="javascript:void(0)"
          (click)="scrollToSection(section.content_order)"
          [class.active-link]="section.content_order === activeSectionId"
          >{{ section.title }}</a
        >
      </p>
    </div>
    <div
      class="content-section"
      [ngStyle]="{
        width:
          newsArticle?.blog_type === newsArticleTypeEnum.NEWS ? '100%' : '49.6%'
      }"
    >
      <div
        class="content-with-title"
        *ngFor="let section of newsArticle.table_of_contents"
        [id]="section?.content_order"
      >
        @if(newsArticle?.blog_type === newsArticleTypeEnum.ARTICLE) {
        <div class="content-title">
          {{ section.title }}
        </div>

        <img
          *ngIf="section.content_image"
          [src]="section.content_image"
          class="content-image"
        />
        }

        <div class="content-part">
          <div *ngFor="let sub_section of section?.description">
            @if(sub_section?.type === 'paragraph') {
            <span
              class="paragraph"
              *ngFor="let p of sub_section?.children"
              [ngStyle]="{
                'font-weight': p?.bold ? 'bold' : 'normal',
                'text-style': p?.italic ? 'italic' : ''
              }"
            >
              @if(p?.type === 'text') {
              <span
                [ngStyle]="{
                  'font-weight': p?.bold ? '700' : '400',
                  'text-style': p?.italic ? 'italic' : ''
                }"
                >{{ p.text }}</span
              >
              } @else if (p?.type === 'link') {
              <a
                style="color: #0054a6; text-decoration: none"
                *ngFor="let link of p?.children"
                [href]="getLink(p)"
                target="_blank"
                [ngStyle]="{
                  'font-weight': link?.bold ? '700' : '400',
                  'text-style': link?.italic ? 'italic' : ''
                }"
              >
                {{ link?.text }}
              </a>
              }
            </span>
            }@else if(sub_section?.type === 'image') {
            <img class="w-100" [src]="getUrl(sub_section?.image?.url)" />
            }@else if(sub_section?.type === 'heading') {
            <p
              *ngFor="let heading of sub_section?.children"
              [ngClass]="'h_' + sub_section?.level"
              [ngStyle]="{
                'font-weight': heading?.bold ? '700' : '400',
                'text-style': heading?.italic ? 'italic' : ''
              }"
            >
              <!-- <li *ngFor="let li of item?.children"> -->
              {{ heading?.text }}
              <!-- </li> -->
            </p>
            }@else if(sub_section?.type === 'list') { @if(sub_section?.format
            === 'unordered'){
            <ul>
              <li *ngFor="let item of sub_section?.children">
                <span
                  *ngFor="let li of item?.children"
                  [ngStyle]="{
                    'font-weight': li?.bold ? '700' : '400',
                    'text-style': li?.italic ? 'italic' : ''
                  }"
                >
                  {{ li?.text }}
                </span>
              </li>
            </ul>
            } @else if(sub_section?.format === 'ordered') {
            <ol>
              <li *ngFor="let item of sub_section?.children">
                <span
                  *ngFor="let li of item?.children"
                  [ngStyle]="{
                    'font-weight': li?.bold ? '700' : '400',
                    'text-style': li?.italic ? 'italic' : ''
                  }"
                >
                  {{ li?.text }}
                </span>
              </li>
            </ol>
            } }
          </div>
        </div>
      </div>
      <div class="tag-section">
        <div class="type-tag">
          <button
            class="tag-button-article"
            *ngFor="let tag of newsArticle.tags"
          >
            {{ tag }}
          </button>
        </div>
      </div>
    </div>
    <div class="signup-share-section">
      <div class="share-social">
        <p class="share">Share</p>
        <div class="social-icon">
          <img
            src="../../../../assets/images/article-news/icon/2023_Facebook_icon 1.svg"
            class="ico-social"
          />
          <img
            src="../../../../assets/images/article-news/icon/Vector.svg"
            class="ico-social"
          />
          <img
            src="../../../../assets/images/article-news/icon/link.svg"
            class="linkedIN"
          />
          <img
            src="../../../../assets/images/article-news/icon/Base icons.svg"
            class="ico-social"
          />
        </div>
      </div>
      <div class="signup-for-donation">
        <div>
          <p class="Looking-for-donation">
            Looking for donation for your next NGO project?
          </p>
        </div>
        <div class="Image-div">
          <img
            src="../../../../assets/images/article-news/2e2832417f.png"
            class="image-signup"
          />
        </div>
        <div class="sign-up-button">
          <button class="yellow-button-md">Sign Up Now</button>
        </div>
      </div>
    </div>
  </div>
  <div class="article-comment-reply-section">
    <div class="article_comment_section">
      <section class="comment-item">
        <div class="comment-item1">
          <div class="comment-item2">
            <div class="message-square">
              <img
                class="comment-icon"
                alt=""
                src="../../../../assets/images/article-news/icon/comment-icon.svg"
              />
            </div>
            <a class="comments">{{ newsArticle?.comments?.length }} Comments</a>
          </div>
          <div class="dx-field d-flex justify-content-end">
            <div class="dx-field-label" style="width: 63px">Sort by:</div>
            <div class="dx-field-value">
              <dx-select-box
                [items]="[
                  { order: 1, text: 'Newest' },
                  { order: -1, text: 'Oldest' }
                ]"
                [displayExpr]="'text'"
                [valueExpr]="'order'"
                [(ngModel)]="commentSortingType"
                [inputAttr]="{ 'aria-label': 'Product With Placeholder' }"
                (onSelectionChanged)="sortComments($event)"
              ></dx-select-box>
            </div>
          </div>
        </div>
        <div *ngIf="!sessionUser" class="auth-prompt">
          <div class="login-or-register">
            Login or register to join the conversation.
          </div>
          <div class="auth-actions">
            <!-- component-1  -->
            <button class="blue-button-auth-lg" [routerLink]="'/auth/signup'">
              Create an Account
            </button>
            <button class="yellow-button-md" [routerLink]="'/auth/login'">
              Log in
            </button>
          </div>
        </div>
      </section>
      <div class="user-interaction">
        <div class="user-profile">
          <img
            class="account-circle-icon"
            alt=""
            [src]="
              sessionUser?.profile_img ||
              './assets/images/article-news/icon/account_circle.svg'
            "
          />
        </div>
        <div class="share-prompt">
          <dx-text-area
            id="text-area"
            [(ngModel)]="comment"
            [readOnly]="sessionUser === null"
            value="Share your thoughts..."
            [width]="'100%'"
            [autoResizeEnabled]="true"
            [inputAttr]="{ 'aria-label': 'Notes' }"
          >
            <dx-validator validationGroup="validationGroup">
              <dxi-validation-rule
                type="required"
                message="Field can't be empty"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-area>
        </div>
      </div>
      <div class="d-flex w-100 justify-content-end">
        <button
          class="blue-button-sm"
          [disabled]="sessionUser === null"
          (click)="postComments()"
        >
          Post
        </button>
      </div>
    </div>
    <!-- all comment section -->
    <section class="root2">
      <div
        *ngFor="let comment of newsArticle?.comments"
        class="d-flex w-100 mt-3"
      >
        <div class="user-profile">
          <img
            class="account-circle-icon"
            alt="User Profile"
            [src]="
              comment?.user_profile_img ||
              './assets/images/article-news/icon/account_circle.svg'
            "
          />
        </div>
        <div class="comment-person-name">
          <div class="person-date">
            <a class="name">{{ comment?.user_name }}</a>
            <div class="date-2">
              {{ comment?.updatedAt | date : "dd MMM yyyy 'at' hh:mm a" }}
            </div>
          </div>
          <div class="comment-body">
            {{ comment?.comment }}
          </div>
          <!-- <div class="actions1">
          <div class="icons2">
            <img
              class="corner-down-left-icon1"
              loading="lazy"
              alt="Reply Icon"
              src="../../../../assets/images/article-news/icon/corner-down-left.svg"
            />
            <div class="replay1">Replay</div>
          </div>
          <div class="icons3">
            <img
              class="thumbs-up-icon1"
              loading="lazy"
              alt="Like Icon"
              src="../../../../assets/images/article-news/icon/thumbs-up.svg"
            />
            <div class="like1">Like</div>
          </div>
        </div> -->
        </div>
      </div>
    </section>
  </div>
  <div class="root">
    <div class="news-container">
      <h1 class="stay-updated-with">You might also be interested in</h1>
    </div>

    <section class="news-cards">
      <div *ngFor="let article of articleNewsList" class="cards">
        <div
          class="rectangle-parent"
          [style]="'background-image: url(' + article?.cover_photo + ');'"
        >
          <div class="article-wrapper">
            <div class="article">
              {{
                article?.blog_type === newsArticleTypeEnum.ARTICLE
                  ? "Article"
                  : "News"
              }}
            </div>
          </div>
        </div>
        <div class="primary-education-bangladesh">
          {{ article?.topic }}
        </div>
        <div class="development-ngos-arm">
          {{ article?.title }}
        </div>
        <div class="frame-group">
          <a
            [routerLink]="['/news-article']"
            [queryParams]="{
              newsArticleId: article?.news_article_id,
              id: article?.id
            }"
          >
            Read More</a
          >
          <img
            class="arrow-right-alt-icon"
            loading="lazy"
            alt=""
            src="../../../../../assets/images/icon/arrow-right-alt.svg"
          />
        </div>
      </div>
    </section>
  </div>
</div>
