import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReceiptModel } from '../../model/receipt.model';
import { CommonSharedServiceService } from '../../shared-services/common-shared-service/common-shared-service.service';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrl: './receipts.component.scss',
})
export class ReceiptsComponent implements OnInit {
  public showPopup: boolean = false;
  @Input() receipt: ReceiptModel = new ReceiptModel({
    invoice_no: '71NXMA9X',
    invoice_to: 'Md. Rashedul Hasan',
    invoice_date: 'Monday, June 09, 2024',
    to_address: '53, Paltan, Dhaka',
    payment_method: 'Card',
    paid_to: 'Campaigns',
    col1Name: 'Organization',
    col1Value: 'Brac Bangladesh',
    col2Name: 'Campaign',
    col2Value: 'Flood Relief Fund - 2024',
    col3Name: 'Amount',
    col3Value: '10000',
    total_amount: '10000',
  });

  constructor(private readonly sharedService: CommonSharedServiceService) {}

  ngOnInit(): void {
    this.sharedService.receiptPopupState$.subscribe((state) => {
      this.showPopup = state;
    });
  }
}
