<div class="recent_donation_data_grid">
  <dx-data-grid
    class="donor_list_data_grid"
    [dataSource]="allFundraisers"
    [showBorders]="false"
    [showColumnLines]="false"
    [showRowLines]="false"
    [allowColumnResizing]="true"
    columnResizingMode="widget"
    [focusedRowEnabled]="false"
    [rowAlternationEnabled]="false"
    rowTemplate="rowDonationList"
    [wordWrapEnabled]="true"
    [columnAutoWidth]="true"
    (onOptionChanged)="loadMorePage($event)"
  >
    <dxo-paging [pageSize]="10" [pageIndex]="0"> </dxo-paging>
    <dxo-pager
      *ngIf="allFundraisers?.length > 10"
      [visible]="true"
      [showPageSizeSelector]="false"
      [allowedPageSizes]="[10]"
      [showInfo]="false"
      [showNavigationButtons]="true"
    >
    </dxo-pager>
    <dxi-column
      caption="Name"
      dataField="fundraiser_name"
      alignment="left"
    ></dxi-column>
    <dxi-column caption="Team" alignment="center"> </dxi-column>
    <dxi-column
      caption="Total Collection"
      dataField="total_fund_raised"
      alignment="center"
    >
    </dxi-column>
    <dxi-column
      caption="Total Supporters"
      dataField="total_donors"
      alignment="center"
    >
    </dxi-column>
    <dxi-column
      caption="Joining Date"
      dataField="total_donors"
      alignment="center"
      style="border: none"
    >
    </dxi-column>

    <tbody class="bg-white" *dxTemplate="let row of 'rowDonationList'">
      <tr style="display: contents !important">
        <td
          style="border-radius: 8px 0 0 8px !important"
          class="cursor"
          [routerLink]="['/admin/donation-management/fundraiser-profile']"
          [queryParams]="{ id: row?.data?.user_id }"
        >
          <div class="donor-profile cursor row_white">
            <div class="donor-number">{{ row?.rowIndex + 1 }}</div>
            <div class="profile-avatar">
              <img
                class="profile_img"
                loading="lazy"
                [src]="
                  row?.data?.profile_img || './assets/images/round_profile.png'
                "
                alt=""
              />
            </div>
            <div class="profile-details">
              <div class="donor-name">{{ row?.data?.fundraiser_name }}</div>
              <div class="donor-email">{{ row?.data?.fundraiser_email }}</div>
            </div>
          </div>
        </td>
        <td>
          <div class="logo-container row_white d-flex justify-content-center">
            @if (row?.data?.team_info?.length > 0) {
            <div
              class="logo_div"
              [ngStyle]="{
                left: row?.data?.team_info?.length * 6 - 6 + 'px'
              }"
            >
              <div
                *ngFor="let logo of row?.data?.team_info; let i = index"
                class="logo"
                [ngStyle]="{ left: i > 0 ? -i * 12 + 'px' : '0px' }"
              >
                <img
                  class="logo_img"
                  *ngIf="i <= 2"
                  [src]="logo?.team_logo"
                  [alt]="logo?.team_name"
                  [title]="logo?.team_name"
                />
                <div *ngIf="i === 3" class="logo_count">
                  <div>{{ row?.data?.team?.length - 3 }}</div>
                  <div>+</div>
                </div>
              </div>
            </div>
            } @else {
            <div>No Team</div>
            }
          </div>
        </td>
        <td>
          <div class="text-center row_white">
            <div class="donor_amount">
              ৳ {{ row?.data?.total_fund_raised | number }}
            </div>
          </div>
        </td>
        <td>
          <div class="donor_amount row_white">
            {{ (row?.data?.total_donors | number) || 0 }}
          </div>
        </td>
        <td style="border-radius: 0px 8px 8px 0px !important">
          <div class="date_time row_white">
            {{ row?.data?.joining_date | date : "dd-MM-yyyy | hh:mm a" }}
          </div>
        </td>
      </tr>
    </tbody>
  </dx-data-grid>
</div>