import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  DevExtremeModule,
  DxTextBoxModule,
  DxAutocompleteModule,
} from 'devextreme-angular';
import { NewsArticleCardsComponent } from '../components/news-article-cards/news-article-cards.component';
import { AnnouncementComponent } from '../components/ngo-campaign/announcement/announcement.component';
import { CampaignDetailsComponent } from '../components/ngo-campaign/campaign-details/campaign-details.component';
import { DonorExperienceComponent } from '../components/ngo-campaign/donor-experience/donor-experience.component';
import { FundraisersListComponent } from '../components/ngo-campaign/fundraisers-list/fundraisers-list.component';
import { JoinAsFundraiserComponent } from '../components/ngo-campaign/join-as-fundraiser/join-as-fundraiser.component';
import { CampainSummaryComponent } from '../components/ngo-campaign/campain-summary/campain-summary.component';
import { DonationSummarySharedComponent } from '../components/ngo-campaign/donation-summary-shared/donation-summary-shared.component';
import { FormsModule } from '@angular/forms';
import { TermsAndConditionsComponent } from '../components/footer-components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '../components/footer-components/privacy-policy/privacy-policy.component';
import { PricingComponent } from '../components/footer-components/pricing/pricing.component';
import { CampaignGalleryComponent } from '../components/ngo-campaign/campaign-gallery/campaign-gallery.component';
import { HallOfFameComponent } from '../components/hall-of-fame/hall-of-fame.component';
import { CampaignWallComponent } from '../components/campaign-wall/campaign-wall.component';
import { ReceiptsComponent } from '../components/receipts/receipts.component';
import { LoggedInHeaderComponent } from 'src/app/layout/logged-in-layout/logged-in-header/logged-in-header.component';
// import { NullWithDefaultPipe } from '../pipe/null-with-default.pipe';

// function HttpLoaderFactory(http: HttpBackend) {
//   return new TranslateHttpLoader(new HttpClient(http));
// }

// const I18N_CONFIG = {
//   // defaultLanguage: 'english',
//   loader: {
//     provide: TranslateLoader,
//     useFactory: HttpLoaderFactory,
//     // deps: [HttpClient],
//     deps: [HttpBackend],
//   },
// };

@NgModule({
  imports: [
    DevExtremeModule,
    DxAutocompleteModule,
    RouterModule,
    CommonModule,
    DxTextBoxModule,
    FormsModule,
    // TranslateModule.forRoot(I18N_CONFIG),
  ],
  declarations: [
    NewsArticleCardsComponent,
    AnnouncementComponent,
    CampaignDetailsComponent,
    DonorExperienceComponent,
    FundraisersListComponent,
    JoinAsFundraiserComponent,
    CampainSummaryComponent,
    DonationSummarySharedComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    PricingComponent,
    CampaignGalleryComponent,
    HallOfFameComponent,
    CampaignWallComponent,
    ReceiptsComponent,
    LoggedInHeaderComponent
  ],
  exports: [
    NewsArticleCardsComponent,
    AnnouncementComponent,
    CampaignDetailsComponent,
    DonorExperienceComponent,
    FundraisersListComponent,
    JoinAsFundraiserComponent,
    CampainSummaryComponent,
    DonationSummarySharedComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    PricingComponent,
    CampaignGalleryComponent,
    HallOfFameComponent,
    CampaignWallComponent,
    ReceiptsComponent,
    LoggedInHeaderComponent
  ],
})
export class SharedModule {}
