export class ReceiptModel {
  invoice_no: string = null;
  invoice_to: string = null;
  invoice_date: Date = null;
  to_address: string = null;
  payment_method: string = null;
  paid_to: string = null;
  col1Name: string = null;
  col1Value: string = null;
  col2Name: string = null;
  col2Value: string = null;
  col3Name: string = null;
  col3Value: string = null;
  total_amount: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}