export class NgoCampaignModel {
  ngo_id: string = null;
  ngo_name: string = null;
  project_id: string = null;
  project_name: string = null;
  title: string = null;
  subtitle: string = null;
  description: string = null;
  story_rich_text: string = null;
  cover_img_vdo: string = null;
  fundraising_target: number = null;
  is_fundraiser_private: boolean = false;
  start_date: Date = null;
  end_date: Date = null;
  emails: string[] = [];
  message: string = null;

  contact_person_name: string = null;
  contact_person_job_title: string = null;
  contact_person_information: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}

export class NgoCampaignUpdateModel {
  title: string = null;
  subtitle: string = null;
  description: string = null;
  story_rich_text: string = null;
  contact_person_name: string = null;
  contact_person_job_title: string = null;
  contact_person_information: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}

export class BankAccountModel {
  bank_name: string = null;
  account_holder_name: string = null;
  account_no: number = null;
  routing_no: number = null;

  constructor(text?: string) {
    if (text) {
      const obj = JSON.parse(text);
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}
