import { UtilitiesService } from './../../utilities/utilities.service';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UrlEnum } from 'src/app/shared/enum/url.enum';
import { HttpRequestService } from '../../http-request/http-request.service';
import { CorporateCampaignModel } from 'src/app/shared/model/corporate/corporate-campaign.model';

@Injectable({
  providedIn: 'root',
})
export class CorporateCampaignService {
  private corporateCampaignUrl = `${UrlEnum.SHOHAY_NGO}/corporate-campaign`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public getCorporateCampaignsByCompany(companyId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.corporateCampaignUrl}/get-campaign-by-company/${companyId}`)
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((campaigns: any[]) =>
          campaigns.map((campaign: any) => ({
            ...campaign,
            cover_img_vdo: this.utilitiesService.jsonParse(
              campaign.cover_img_vdo
            ),
          }))
        ),
        map((x: any[]) =>
          x.sort(
            this.utilitiesService.dynamicSortWithDate('created_at', 'desc')
          )
        )
      );
  }

  public createCorporateCampaign(
    campaignBody: CorporateCampaignModel
  ): Observable<any> {
    return this.httpRequestService
      .post(`${this.corporateCampaignUrl}/create`, campaignBody)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
