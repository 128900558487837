import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CommonSharedServiceService {
  private showSearchModalValue = new BehaviorSubject<boolean>(false); // Default value
  currentSearchModalValue = this.showSearchModalValue.asObservable();

  constructor(private readonly authService: AuthService) {}

  changeSearchModalValue(value: boolean) {
    this.showSearchModalValue.next(value);
  }

  private ngoSelectionActionSource = new Subject<void>();
  ngoSelectionAction$ = this.ngoSelectionActionSource.asObservable();

  public ngoSelected() {
    this.ngoSelectionActionSource.next();
  }

  //To Handle IsLoggedIn across the system
  private isLoggedIn = new BehaviorSubject<boolean>(
    this.authService.isLoggedIn()
  );
  public isLoggedIn$ = this.isLoggedIn.asObservable();

  public updateLoginState(): void {
    this.isLoggedIn.next(this.authService.isLoggedIn());
  }

  //To handle shared receipt popup state
  private receiptPopupState = new BehaviorSubject<boolean>(false);
  receiptPopupState$ = this.receiptPopupState.asObservable();

 public updateReceiptPopupState(state: boolean): void {
    this.receiptPopupState.next(state);
  }
}
