<div class="donor-container">
  @if(isCampaignHasData){
  <div class="abcd text-start mt-4 mb-4 w-100">
    <div class="recent_donation">Recent Donation</div>
    <img
      loading="lazy"
      src="../../../../../assets/images/icon/trending_up_icon.svg"
      alt="Donor"
      title="donor"
      class="for_donor"
    />
    <span class="font_16 recent_donated_text"
      >{{ campaign.total_donors > 0 ? campaign.total_donors : 0 }} people just
      donated</span
    >
  </div>
  <!-- (onSelectionChanged)="onTabChange($event)" -->
  <div class="donor_list_container">
    <dx-tab-panel
      (onSelectionChanged)="onTabChange($event)"
      (click)="onTabClick($event)"
      style="box-shadow: none"
      class="all_tabs"
      [swipeEnabled]="true"
    >
      <dxi-item title="Donation"> </dxi-item>
      <dxi-item [disabled]="true" title="Pledging"> </dxi-item>
    </dx-tab-panel>
    <div class="donor_list">
      <div class="d-flex gap-2">
        <div class="donor_icon_div">
          <img
            src="../../../../../assets/images/icon/donor_icon_blue.png"
            alt="Donor"
            title="donor"
          />
        </div>
        <div class="d-flex gap-2 flex-column">
          <span class="font_18"
            >{{ donationSummary.recent_donation.name }}
          </span>
          <span class="font_16">
            ৳{{
              donationSummary.recent_donation.amount === (0 | number)
                ? "Hidden"
                : (donationSummary.recent_donation.amount | number)
            }}
            <span class="font_14" style="text-decoration: underline"
              >Recent Donation</span
            >
          </span>
        </div>
      </div>
    </div>

    <div class="donor_list">
      <div class="d-flex gap-2">
        <div class="donor_icon_div">
          <img
            src="../../../../../assets/images/icon/donor_icon_blue.png"
            alt="Donor"
            title="donor"
          />
        </div>
        <div class="d-flex gap-2 flex-column">
          <span class="font_18">{{ donationSummary.top_donation.name }} </span>
          <span class="font_16">
            ৳{{
              donationSummary.top_donation.amount === (0 | number)
                ? "Hidden"
                : (donationSummary.top_donation.amount | number)
            }}
            <span class="font_14" style="text-decoration: underline"
              >Top Donation</span
            >
          </span>
        </div>
      </div>
    </div>
    <div class="donor_list">
      <div class="d-flex gap-2">
        <div class="donor_icon_div">
          <img
            src="../../../../../assets/images/icon/donor_icon_blue.png"
            alt="Donor"
            title="donor"
          />
        </div>
        <div class="d-flex gap-2 flex-column">
          <span class="font_18"
            >{{ donationSummary.first_donation.name }}
          </span>
          <span class="font_16">
            ৳{{
              donationSummary.first_donation.amount === (0 | number)
                ? "Hidden"
                : (donationSummary.first_donation.amount | number)
            }}
            <span class="font_14" style="text-decoration: underline"
              >First Donation</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="white-button-md see-all-btn"
    (click)="seeAll()"
  >
    See All
  </div>
  }@else {
  <div class="font_24">No Donation History is available</div>
  }
</div>

<dx-popup
  [maxWidth]="721"
  [maxHeight]="802"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="donationListPopUp"
  [showTitle]="false"
>
  <div class="donation_list_popup">
    <div class="all_donation_container">
      <div class="cross_button_conatiner">
        <div class="cross_button_div">
          <button class="cross_button" (click)="closePopup()">
            <img
              src="../../../../../assets/images/icon/cross_icon.svg"
              alt="cross"
              loading="lazy"
              title="close"
            />
          </button>
        </div>
        <div class="donation_list_text">
          Donations List ( {{ donationList?.length }})
        </div>
      </div>
      <div class="button_container">
        <button
          (click)="filterDonations('recent')"
          [class.most_recent_button]="activeFilter === 'recent'"
          [class.white-button-md]="activeFilter !== 'recent'"
          style="width: 48%"
        >
          Most Recent
        </button>
        <button
          (click)="filterDonations('top')"
          [class.most_recent_button]="activeFilter === 'top'"
          [class.white-button-md]="activeFilter !== 'top'"
          style="width: 48%"
        >
          Top Donations
        </button>
      </div>
    </div>
    <dx-scroll-view width="100%" height="70%">
      <div class="all_donation_container">
        <div
          class="donar_container"
          *ngFor="let donor of currentDonationList; let i = index"
        >
          <div class="donor_list_">
            <div class="donor_icon_div">
              <img
                src="../../../../../assets/images/icon/donor_icon_blue.png"
                alt="Donor"
                title="donor"
              />
            </div>
            <div class="d-flex gap-2 flex-column">
              <span class="font_18">
                {{ donor.is_hidden_name ? "Anonymous" : donor.name }}
              </span>
              <span class="font_16">
                ৳{{
                  donor.is_hidden_name_and_amount
                    ? "Hidden"
                    : (donor.amount | number)
                }}
                <span class="hours"
                  >in {{ getDonationTime(donor?.created_at) }}</span
                >
              </span>
            </div>
          </div>
          <!-- <div *ngIf="(i + 1) % 3 === 0" class="join_list">
            <div class="joint_list_text">Join this list</div>
            <button
              class="donate_now_button"
              (click)="navigateToCampaignDonation(campaign)"
            >
              Donate Now
            </button>
          </div> -->
        </div>
      </div>
    </dx-scroll-view>
    <div class="donation_button">
      <button
        class="blue-button-lg w-100"
        (click)="navigateToCampaignDonation(campaign)"
      >
        <!-- [routerLink]="['/donate-to-campaign']"
          [queryParams]="{
            ngo_id: campaign?.ngo_id,
            project_id: campaign?.project_id,
            id: campaign?.id
          }" -->
        Donate Now
      </button>
    </div>
  </div>
</dx-popup>

<dx-popup
  [maxWidth]="846"
  [height]="'auto'"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="pledgeListPopup"
  [showTitle]="false"
  (onHiding)="pledgePopHiding(tabPanel)"
>
  <div class="cross_button_conatiner">
    <div class="cross_button_div" style="margin-right: 20px">
      <button class="cross_button" (click)="closePledgePopup()">
        <img
          src="../../../../../assets/images/icon/x-circle.svg"
          alt="cross"
          loading="lazy"
          title="close"
        />
      </button>
    </div>
  </div>

  <div
    class="all_donation_container"
    style="
      width: 511px;
      margin-inline: auto;
      margin-top: 32px;
      height: 697px;
      overflow-x: auto;
    "
  >
    <div class="pledgeTitle">pledging( {{ pledgeList.length }} )</div>
    <dx-scroll-view width="100%" height="100%">
      <div
        class="donor_list_pledge cursor"
        (click)="pledgeDetailsPop()"
        *ngFor="let item of pledgeList"
      >
        <div class="icon_list">
          <div class="donor_icon_div">
            <img
              src="../../../../../assets/images/icon/donate-up.svg"
              alt="Donor"
              title="donor"
            />
          </div>
          <div class="d-flex gap-1 flex-column">
            <span class="font_18_bold">
              {{ item.name }}
            </span>
            <span class="font_18">
              {{ item.phone }}
            </span>
            <span class="font_18">
              {{ item.date }}
            </span>
          </div>
        </div>
        <div class="donor_icon_div">
          <img
            src="../../../../../assets/images/icon/arrow-blue-left.svg"
            alt="Donor"
            title="donor"
          />
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  [maxWidth]="846"
  [height]="'auto'"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="pledgeDetailsPopup"
  [showTitle]="false"
>
  <!-- (onHiding)="pledgePopHiding()" -->
  <div class="cross_button_conatiner" style="height: 80vh; overflow-x: auto">
    <div class="cross_button_div_" style="margin-right: 20px">
      <div class="back_button">
        <button class="back" (click)="backToList()">
          <img
            src="../../../../../assets/images/icon/arrow_back_icon.svg"
            alt="cross"
            loading="lazy"
            title="close"
          />
        </button>
        Back To List
      </div>
      <button class="cross_button" (click)="closePledgeDetailsPopup()">
        <img
          src="../../../../../assets/images/icon/x-circle.svg"
          alt="cross"
          loading="lazy"
          title="close"
        />
      </button>
    </div>

    <div class="pledgeTitle">Confirm Pledging</div>

    <dx-scroll-view width="100%" height="100%">
      <div class="pic_name">
        <img
          src="../../../../../assets/images/round_profile.png"
          alt="profile_pic"
        />
        <div class="font_18_bold">Farhan Ahmed</div>
        <div class="font_18">493494398</div>
        <div class="font_18">whoisashik&#64;gmail.com</div>
      </div>

      <div class="fields">
        <div style="display: flex; justify-content: space-between">
          <div class="value">Pledged Amount</div>
          <div class="value money">৳20,180</div>
        </div>
        <div>
          <div class="value">Messages</div>
          <div class="popup_value">
            {{pledgeDonationModel.comment}}
          </div>
        </div>
        <div>
          <div class="value">Whatsapp</div>
          <div class="popup_value">{{pledgeDonationModel.whatsapp_no}}</div>
        </div>
        <div>
          <label class="value">Received Amount</label>
          <dx-number-box
            class="input_field"
            [showClearButton]="true"
            format="৳ #,##0"
            [invalidValueMessage]="'Value must be a number'"
            [(ngModel)]="pledgePaymentModel.received_amount"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Target amount is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>
        <div>
          <label class="value">Payment Method</label>
          <dx-select-box
            class="input_field"
            [dataSource]="paymentMethod"
            [(value)]="paymentMethod[0]"
            [showClearButton]="true"
            [placeholder]="'select a method'"
            [(value)]="pledgePaymentModel.payment_method"
            (valueChange)="onPaymentMethodChange($event)"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule type="required" message="Area is required.">
              </dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
        <div>
          <label class="value">Transaction ID</label>
          <dx-text-box class="input_field" [showClearButton]="true" [(ngModel)]="pledgePaymentModel.transaction_id">
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="NGO name is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </div>

      <div class="buttons">
        <dx-button class="yellow-button-lg" (click)="rejectPopup()"
          >Reject</dx-button
        >
        <dx-button class="blue-button-lg" (click)="confirmPopUp()"
          >Confirm Pledging</dx-button
        >
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  [maxWidth]="721"
  [height]="449"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="isSuccessful"
  [showTitle]="false"
>
  <div class="root">
    <div class="bookmarks">
      <img
        class="icons8-no-bookmark-100-1"
        loading="lazy"
        alt=""
        src="../../../../assets/images/icon/success-mark.svg"
      />
    </div>
    <section class="you-havent-bookmarked">Confirm Pledging</section>
    <div class="feedback">Are you really wants to confirm this Pledging ?</div>
    <!-- [routerLink]="['dashboard/manage/form-preview']"
  [queryParams]="{ id: formIdOnParam }" -->
    <div class="bookmarks">
      <div class="yellow-button-lg cursor" (click)="cancelSuccessPopup()">
        Cancel
      </div>
      <div (click)="cancelSuccessPopup()" class="blue-button-lg cursor">
        Confirm
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [maxWidth]="721"
  [height]="449"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="rejected"
  [showTitle]="false"
>
  <div class="root">
    <div class="bookmarks">
      <img
        class="icons8-no-bookmark-100-1"
        loading="lazy"
        alt=""
        src="../../../../assets/images/icon/remove-big.svg"
      />
    </div>
    <section class="you-havent-bookmarked">Reject Pledging</section>
    <div class="feedback">Are you really wants to confirm this Pledging ?</div>
    <!-- [routerLink]="['dashboard/manage/form-preview']"
  [queryParams]="{ id: formIdOnParam }" -->
    <div class="bookmarks">
      <div class="yellow-button-lg cursor" (click)="cancelRejectPopup()">
        Cancel
      </div>
      <div (click)="cancelRejectPopup()" class="blue-button-lg cursor">
        Confirm
      </div>
    </div>
  </div>
</dx-popup>
