import { Component, OnInit } from '@angular/core';
import { CallBookingModel } from 'src/app/shared/model/call-booking.model';
import {
  ContactTypeEnum,
  NgoStatusEnum,
} from 'src/app/shared/enum/call-booking.enum';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { ContactRequestService } from 'src/app/shared/services/contact-request/contact-request.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-hp9-contact-form',
  templateUrl: './hp9-contact-form.component.html',
  styleUrls: ['./hp9-contact-form.component.scss'],
})
export class Hp9ContactFormComponent implements OnInit {
  public today: Date = new Date();
  public contactType = ContactTypeEnum;
  public ngoStatus = NgoStatusEnum;
  public contactForm: CallBookingModel = new CallBookingModel();

  public ngoSlide: any = [
    {
      company_logo: './assets/images/logo/bmqa-logo.png',
      description:
        "Shohay significantly streamlined our charity event planning, enabling us to manage our initiatives more efficiently so we could stay focused on making a real impact on the ground in our charity campaign for flood victims in Sherpur.",
      image:'./assets/profile-pic-masum-vay.png',
      representative_name: 'Masum Billah',
      title: 'Campaign Organizer, BMQAS',
    },
    {
      company_logo: './assets/images/logo/bmqa-logo.png',
      description:
        "Organizing a charity campaign involves juggling countless details. Shohay took the complexity out of the process, allowing us to devote more time and energy to hands-on, on-site activities in blanket distribution amongst the underprivileged people in North Bengal.",
      image: './assets/profile-pic-mainul-vay.png',
      representative_name: 'Moinul Hasan',
      title: 'Campaign Organizer, BMQAS',
    }
  ];
  
  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly contactRequestService: ContactRequestService
  ) {}

  ngOnInit(): void {}

  public changeContactType(contactType: ContactTypeEnum): void {
    this.contactForm.contact_type = contactType;
  }

  public async submit() {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      const response = await this.utilitiesService.showSwalAndReturn({
        type: 'warning',
        title: `Are you sure?`,
        message: 'Do You want shohay team contact with you?',
        confirmButtonText: 'Save',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      });
      if (response.isConfirmed) {
        this.utilitiesService.showSpinner(true);
        this.contactRequestService.bookACall(this.contactForm).subscribe({
          next: (result: any) => {
            this.utilitiesService.showSpinner(false);
            this.utilitiesService.showSwalWithToast(
              'Success',
              `${
                this.contactForm.contact_type === this.contactType.CALL
                  ? 'Your call is booked'
                  : 'Message send'
              } successfully.`
            );
            ValidationEngine.resetGroup('validationGrp');
          },
          error: (error: any) => {
            this.utilitiesService.showSpinner(false);
            this.utilitiesService.showSwalWithToast(
              error.error.error_name,
              error.error.message,
              'error'
            );
          },
        });
      }
    }
  }
}
