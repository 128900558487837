import { CorporateCampaignService } from './../../../../shared/services/corporate/corporate-campaign/corporate-campaign.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, of } from 'rxjs';
import { ApprovalStatusEnum } from 'src/app/shared/enum/approval-category.enum';
import { CampaignStatusEnum } from 'src/app/shared/enum/campaign-status.enum';
import { FileService } from 'src/app/shared/services/file/file.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-corporate-campaign-list',
  templateUrl: './corporate-campaign-list.component.html',
  styleUrl: './corporate-campaign-list.component.scss',
})
export class CorporateCampaignListComponent {
  public campaignStatusEnum = CampaignStatusEnum;
  public approvalStatusEnum = ApprovalStatusEnum;

  public activeTab = 0;
  public searchKeyword = '';

  public sharePopUp = false;
  public url = '';

  public allCampaign: any = [];
  public filteredCampaign: any = [];

  private companyId: string;

  constructor(
    private readonly router: Router,
    private readonly fileService: FileService,
    private readonly spinner: NgxSpinnerService,
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService,
    private readonly corporateCampaignService: CorporateCampaignService
  ) {}

  async ngOnInit() {
    // this.companyId = this.localStorageService.getSessionUser()?.company_id;
    // if (this.companyId) {
    this.getAllCampaign();
    // }
  }

  private getAllCampaign() {
    this.allCampaign = [];
    this.allCampaign = [
      {
        company_id: '05f81eb5-d3b0-4c05-97b7-beb7bd9a0aff',
        id: '4350b75c-f39c-4217-a38b-8c82990ae618',
        company_name: 'Health for All Foundation',
        contact_person_information: 'rabbi@gmail.com',
        contact_person_job_title: 'pilot',
        contact_person_name: 'rabbi',
        cover_img_vdo: 'https://example.com/images/clean_water_project.jpg',
        created_at: '2025-01-19T06:55:50.920Z',
        created_by: '76d52b74-061c-4150-b178-b1970efa123e',
        description:
          'This campaign aims to provide clean drinking water to communities lacking access to safe water.',
        donation_count: 0,
        donation_summary:
          '{"recent_donation":null,"top_donation":null,"first_donation":null}',
        end_date: null,
        fundraising_target: 150000,
        is_active: true,
        is_fundraiser_private: false,
        matching_budget: 32432,
        matching_limit: 343,
        matching_percentage: 5.400000095367432,
        payment_type: 'OWN',
        start_date: '2024-10-01T00:00:00.000Z',
        status: 'STOPPED',
        story_rich_text:
          '<p>Many communities around the world suffer from the lack of clean water. This project...</p>',
        subtitle: 'Bring Clean Water to Underserved Communities',
        title: 'Fazle rabbi campaign',
        total_fund_raised: 0,
        updated_at: '2025-01-19T06:55:50.920Z',
        updated_by: null,
      },
      {
        company_id: 'b8230d5f-6d17-4f1d-9f57-9880e749c4a4',
        id: 'b01fc2ae-4495-4971-9f27-81042f3766eb',
        company_name: 'Food for Everyone',
        contact_person_information: 'jane.doe@email.com',
        contact_person_job_title: 'Director',
        contact_person_name: 'Jane Doe',
        cover_img_vdo: 'https://example.com/images/food_distribution.jpg',
        created_at: '2025-01-18T12:30:22.450Z',
        created_by: '81f52a91-82b5-4fa2-b1a3-cb184ef65aa5',
        description:
          'This campaign focuses on feeding underprivileged communities with nutritious meals.',
        donation_count: 12,
        donation_summary:
          '{"recent_donation":100,"top_donation":500,"first_donation":50}',
        end_date: '2025-06-30T00:00:00.000Z',
        fundraising_target: 50000,
        is_active: true,
        is_fundraiser_private: false,
        matching_budget: 12000,
        matching_limit: 200,
        matching_percentage: 10.0,
        payment_type: 'DONATION',
        start_date: '2024-11-15T00:00:00.000Z',
        status: 'ACTIVE',
        story_rich_text:
          '<p>Every day, millions go hungry. With your support, we can make a difference...</p>',
        subtitle: 'Providing Food to the Needy',
        title: 'Feed the World Campaign',
        total_fund_raised: 13400,
        updated_at: '2025-01-18T14:10:05.350Z',
        updated_by: '81f52a91-82b5-4fa2-b1a3-cb184ef65aa5',
      },
    ];
    this.filteredCampaign = JSON.parse(JSON.stringify(this.allCampaign));
    // this.corporateCampaignService
    //   .getCorporateCampaignsByCompany(this.companyId)
    //   .subscribe({
    //     next: (result) => {
    //       this.allCampaign = result;
    //this.filteredCampaign = result;
    //     },
    //   });
  }

  public buttonClick(e: any, campgn) {
    let campaign = JSON.parse(JSON.stringify(campgn));
    campaign.cover_img_vdo = JSON.stringify(campaign.cover_img_vdo);
    switch (e.itemData.id) {
      case 'request_approval':
        this.requestForApproval(campaign);
        break;
      case 'request_featured_approval':
        this.requestForFeatured(campaign);
        break;
      case 'cancel_featured':
        this.cancelFeatured(campaign);
        break;
      case 'edit_campaign':
        this.navigateToCreateCampaign(campaign);
        break;
      case 'end_campaign':
        this.stopCampaign(campaign.id);
        break;
      case 'start_campaign':
        this.startCampaign(campaign.id);
        break;
      case 'delete_campaign':
        this.deleteCampaign(campaign);
        break;
      case 'share_campaign':
        this.shareCampaign(campaign.ngo_id, campaign.project_id, campaign.id);
        break;
      default:
        break;
    }
  }

  public async requestForApproval(campaign: any) {
    // const body = this.approvalService.getApprovalBody(
    //   campaign,
    //   ApprovalCategoryEnum.CAMPAIGN
    // );
    // this.approvalService.makeRequest(body).subscribe({
    //   next: (result: any) => {},
    // });
  }

  public async requestForFeatured(campaign: any) {
    const response = await this.utilitiesService.showSwalAndReturn({
      title: 'This campaign will be highlighted in the featured section.',
      type: 'warning',
      confirmButtonText: 'Continue',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });

    if (response.isConfirmed) {
      // const body = this.approvalService.getApprovalBody(
      //   campaign,
      //   ApprovalCategoryEnum.FEATURED_CAMPAIGN
      // );
      // this.approvalService.makeRequest(body).subscribe({
      //   next: (result: any) => {},
      // });
    }
  }

  public async cancelFeatured(campaign: any) {
    // const response = await this.utilitiesService.showSwalAndReturn({
    //   title: 'This campaign will be removed from the highlighted campaigns section.',
    //   type: 'error',
    //   confirmButtonText: 'Continue',
    //   showCancelButton: true,
    //   cancelButtonText: 'Cancel',
    // });
    // if (response.isConfirmed) {
    // }
  }

  private async startCampaign(campaignId: string) {
    const response = await this.confirmAction(
      'You want to start the campaign?',
      'Yes, Start'
    );
    if (response.isConfirmed) {
      // const currentDate = new Date().toISOString();
      // this.spinner.show();
      // this.ngoCampaignService
      //   .startCampaign(
      //     this.selectedNgo.id,
      //     this.selectedProject.id,
      //     campaignId,
      //     currentDate
      //   )
      //   .subscribe({
      //     next: (res) => {
      //       this.spinner.hide();
      //       this.utilitiesService.showSwal(
      //         'success',
      //         'Campaign started successfully!'
      //       );
      //       this.updateCampaignStatus(campaignId, CampaignStatusEnum.RUNNING);
      //     },
      //     error: (err) => {
      //       this.spinner.hide();
      //       this.utilitiesService.showSwal(
      //         'error',
      //         'Error starting the campaign'
      //       );
      //     },
      //   });
    }
  }

  private async stopCampaign(campaignId: string) {
    const response = await this.confirmAction(
      'You want to stop the campaign?',
      'Yes, Stop'
    );
    if (response.isConfirmed) {
      const currentDate = new Date().toISOString();
      this.spinner.show();

      // this.ngoCampaignService
      //   .stopCampaign(
      //     this.selectedNgo.id,
      //     this.selectedProject.id,
      //     campaignId,
      //     currentDate
      //   )
      //   .subscribe({
      //     next: (res) => {
      //       this.spinner.hide();
      //       this.utilitiesService.showSwal(
      //         'success',
      //         'Campaign stopped successfully!'
      //       );
      //       this.updateCampaignStatus(campaignId, CampaignStatusEnum.STOPPED);
      //     },
      //     error: (err) => {
      //       this.spinner.hide();
      //       this.utilitiesService.showSwal(
      //         'error',
      //         'Error stopped the campaign'
      //       );
      //     },
      //   });
    }
  }

  private async deleteCampaign(campaign: any) {
    if (campaign.status === CampaignStatusEnum.RUNNING) {
      this.utilitiesService.showSwal(
        'error',
        'Stop the campaign first',
        'You need to stop the campaign in order to delete'
      );
    } else {
      const response = await this.confirmAction(
        'You want to delete the campaign?',
        'Yes, Delete'
      );
      if (response.isConfirmed) {
        this.spinner.show();

        // this.ngoCampaignService.deleteCampaign(campaign.id).subscribe({
        //   next: (res) => {
        //     this.onSuccessDeleteCampaign(res, campaign);
        //   },
        //   error: (err) => {
        //     this.onErrorDeleteCampaign(err);
        //   },
        // });
      }
    }
  }

  private onSuccessDeleteCampaign(res: any, campaign: any) {
    this.spinner.hide();
    this.utilitiesService.showSwal('success', 'Campaign deleted successfully!');
    this.allCampaign = this.allCampaign.filter(
      (item) => item.id !== campaign.id
    );
    this.filteredCampaign = this.filteredCampaign.filter(
      (item) => item.id !== campaign.id
    );
    this.deleteFile(campaign);
  }

  private onErrorDeleteCampaign(err: any) {
    this.spinner.hide();
    this.utilitiesService.showSwal(
      'error',
      err?.error?.message || 'Error deleting the campaign'
    );
  }

  private deleteFile(campaign: any): void {
    let file = campaign.cover_img_vdo;
    this.utilitiesService.showSpinner(true);

    this.fileService
      .deleteFile(file.file_id)
      .pipe(
        catchError((error) => {
          return of({ success: false, fileId: file.file_id });
        })
      )
      .subscribe({
        next: (result: any) => {
          this.utilitiesService.showSpinner(false);
          if (result && result.success === false) {
            this.utilitiesService.showSwal(
              'error',
              'Failed to delete file.',
              `File id: ${result.fileId}`
            );
          } else {
            this.utilitiesService.showSwal(
              'success',
              'File deleted successfully.'
            );
          }
        },
        error: (err) => {
          this.utilitiesService.showSpinner(false);
          this.utilitiesService.showSwal('error', 'Failed to delete file.');
        },
      });
  }

  public stopClickEvent(event: MouseEvent) {
    event.stopPropagation();
  }

  private updateCampaignStatus(campaignId: string, status: string) {
    this.allCampaign = this.allCampaign.map((campaign) =>
      campaign.id === campaignId ? { ...campaign, status } : campaign
    );

    this.filteredCampaign = this.filteredCampaign.map((campaign) =>
      campaign.id === campaignId ? { ...campaign, status } : campaign
    );
  }

  public get activeDataSource() {
    const filterDataSource = this.getCurrentDataSource();
    return this.filterDataSource(filterDataSource);
  }

  private getCurrentDataSource() {
    switch (this.activeTab) {
      case 0:
        return this.allCampaign;
      // .filter(
      //   (cmp) => cmp?.status === CampaignStatusEnum.RUNNING
      // );
      case 1:
        return this.allCampaign;
      // .filter(
      //   (cmp) => cmp?.status === CampaignStatusEnum.STOPPED
      // );
      default:
        return [];
    }
  }

  private filterDataSource(dataSource: any[]) {
    if (!this.searchKeyword) {
      return dataSource;
    }
    const keyword = this.searchKeyword.toLowerCase();
    return dataSource.filter(
      (campaign) =>
        campaign.title.toLowerCase().includes(keyword) ||
        campaign.subtitle.toLowerCase().includes(keyword)
    );
  }

  public onTabChange(event: any) {
    this.activeTab = event.component.option('selectedIndex');
  }

  public onSearchKeywordChanged(event: any) {
    this.searchKeyword = event.event.target.value;
  }

  private async confirmAction(message: string, confirmButtonText: string) {
    return await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: 'Are you sure?',
      message: message,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
  }

  private showWarning(): Promise<any> {
    return this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: `Please Select a project first`,
      confirmButtonText: 'OK',
      showCancelButton: false,
    });
  }

  private shareCampaign(ngoId: string, projectId: string, campaignId: string) {
    this.sharePopUp = true;
    this.generateUrl(ngoId, projectId, campaignId);
  }

  private generateUrl(ngoId: string, projectId: string, campaignId: string) {
    this.url = `https://shohay.net/campaign?ngo_id=${ngoId}&project_id=${projectId}&id=${campaignId}`;
  }

  public copyText(): void {
    navigator.clipboard.writeText(this.url).then(() => {
      this.utilitiesService.showSwalWithToast(
        `URL Copied`,
        `You copied the url`,
        'success'
      );
    });
  }

  public shareOnSocialMedia(socialMedia: string) {
    let shareUrl = '';

    switch (socialMedia) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          this.url
        )}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          this.url
        )}`;
        break;
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          this.url
        )}`;
        break;
      case 'twitter':
        shareUrl = `https://x.com/intent/tweet?url=${encodeURIComponent(
          this.url
        )}`;
        break;
      default:
        return;
    }
    window.open(shareUrl, '_blank');
  }

  public navigateToCampaignSummary(campaign: any) {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: campaign.id,
      ngo_id: campaign?.ngo_id,
      project_id: campaign?.project_id,
    });
    this.router.navigate(['/corporate/campaigns/summary'], {
      queryParams: { data: encryptedParams },
    });
  }

  private navigateToCreateCampaign(campaign: any) {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: campaign.id,
      ngo_id: campaign?.ngo_id,
      project_id: campaign?.project_id,
    });
    this.router.navigate(['/corporate/campaigns/create'], {
      queryParams: { data: encryptedParams },
    });
  }
}
