<div *ngIf="!fullScreenPopup" class="main_div">
  <div>
    <span class="colored">Home / Campaigns /</span>
    Create Campaign
  </div>

  <div class="second_main_div">
    <div class="title_div">
      <h1 class="title">Create New Campaign</h1>
      <h4 class="title_des">
        Start a new campaign to raise funds for your cause.
      </h4>
    </div>

    <div class="form_indicator_div">
      <div class="form_indicator">
        <div
          [ngClass]="
            activeForm === 1 ||
            activeForm === 2 ||
            activeForm === 3 ||
            activeForm === 4 ||
            activeForm === 5
              ? 'form_round active_form'
              : 'form_round'
          "
        >
          <span style="justify-content: center; display: flex">1</span>
        </div>
        <div
          [ngClass]="
            activeForm === 2 ||
            activeForm === 3 ||
            activeForm === 4 ||
            activeForm === 5
              ? 'form_line active_form_line'
              : 'form_line'
          "
        ></div>
        <div
          [ngClass]="
            activeForm === 2 ||
            activeForm === 3 ||
            activeForm === 4 ||
            activeForm === 5
              ? 'form_round active_form'
              : 'form_round'
          "
        >
          <span style="justify-content: center; display: flex">2</span>
        </div>
        <div
          [ngClass]="
            activeForm === 3 || activeForm === 4 || activeForm === 5
              ? 'form_line active_form_line'
              : 'form_line'
          "
        ></div>
        <div
          [ngClass]="
            activeForm === 3 || activeForm === 4 || activeForm === 5
              ? 'form_round active_form'
              : 'form_round'
          "
        >
          <span style="justify-content: center; display: flex">3</span>
        </div>
        <div
          [ngClass]="
            activeForm === 4 || activeForm === 5
              ? 'form_line active_form_line'
              : 'form_line'
          "
        ></div>
        <div
          [ngClass]="
            activeForm === 4 || activeForm === 5
              ? 'form_round active_form'
              : 'form_round'
          "
        >
          <span style="justify-content: center; display: flex">4</span>
        </div>
        <div
          [ngClass]="
            activeForm === 5 ? 'form_line active_form_line' : 'form_line'
          "
        ></div>
        <div
          [ngClass]="activeForm === 5 ? 'form_round active_form' : 'form_round'"
        >
          <span style="justify-content: center; display: flex">5</span>
        </div>
      </div>

      <div class="form_indicator_des">
        <span [ngClass]="activeForm === 1 ? 'active_form_indicator_des' : ''">
          Campaign Details
        </span>
        <span [ngClass]="activeForm === 2 ? 'active_form_indicator_des' : ''">
          Set Matching Metrics
        </span>
        <span [ngClass]="activeForm === 3 ? 'active_form_indicator_des' : ''">
          Set Target
        </span>
        <span [ngClass]="activeForm === 4 ? 'active_form_indicator_des' : ''">
          Build Team
        </span>
        <span [ngClass]="activeForm === 5 ? 'active_form_indicator_des' : ''">
          Payment Settings
        </span>
      </div>
    </div>

    <div class="form_div_main">
      <div class="form_div_title">
        <h3 class="form_title" *ngIf="activeForm === 1">
          Tell Us About Your Campaign
        </h3>
        <h3 class="form_title" *ngIf="activeForm === 2">
          Set Matching Metrics
        </h3>
        <h3 class="form_title" *ngIf="activeForm === 3">
          Set Fundraising Target & End Date
        </h3>
        <h3 class="form_title" *ngIf="activeForm === 4">Add Employee Team</h3>
        <h3 class="form_title" *ngIf="activeForm === 5">Payment Settings</h3>
        <h6 class="must_be_provided">
          (<span class="requiredMark"></span>) Required
        </h6>
      </div>

      <div *ngIf="activeForm === 1" class="form_div">
        <div class="input_div">
          <label class="field_label requiredMark">Title of Campaign</label>
          <dx-text-box
            class="input_field"
            [(ngModel)]="campaignModel.title"
            placeholder="Enter a concise and impactful campaign title"
            [showClearButton]="true"
            [readOnly]="has_donation"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Campaign title is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">Subtitle of Campaign </label>
          <dx-text-box
            class="input_field"
            [(ngModel)]="campaignModel.subtitle"
            placeholder="Enter a concise and impactful campaign subtitle"
            [showClearButton]="true"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Subtitle is required."
              >
              </dxi-validation-rule>
              <dxi-validation-rule
                type="stringLength"
                [max]="68"
                [trim]="false"
                message="68 characters maximum"
              >
              </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">Story of Campaign</label>
          <dx-html-editor
            #htmlEditor
            height="314"
            [(value)]="campaignModel.story_rich_text"
            style="background-color: white"
            [allowSoftLineBreak]="true"
          >
            <dxo-toolbar [multiline]="true">
              <dxi-item name="undo"></dxi-item>
              <dxi-item name="redo"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item
                name="size"
                [acceptedValues]="[
                  '8px',
                  '10px',
                  '12px',
                  '14px',
                  '16px',
                  '18px',
                  '20px',
                  '22px',
                  '24px',
                  '26px',
                  '28px',
                  '30px',
                  '32px',
                  '34px',
                  '36px',
                  '38px'
                ]"
                [options]="{ inputAttr: { 'aria-label': 'Font size' } }"
              ></dxi-item>
              <dxi-item
                name="font"
                [acceptedValues]="[
                  'Arial',
                  'Courier New',
                  'Georgia',
                  'Impact',
                  'Inter',
                  'Lucida Console',
                  'Tahoma',
                  'Times New Roman',
                  'Verdana'
                ]"
                [options]="{ inputAttr: { 'aria-label': 'Font family' } }"
              ></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="bold"></dxi-item>
              <dxi-item name="italic"></dxi-item>
              <dxi-item name="strike"></dxi-item>
              <dxi-item name="underline"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="alignLeft"></dxi-item>
              <dxi-item name="alignCenter"></dxi-item>
              <dxi-item name="alignRight"></dxi-item>
              <dxi-item name="alignJustify"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="orderedList"></dxi-item>
              <dxi-item name="bulletList"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item
                name="header"
                [acceptedValues]="[false, 1, 2, 3, 4, 5]"
                [options]="{ inputAttr: { 'aria-label': 'Header' } }"
              ></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="color"></dxi-item>
              <dxi-item name="background"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="link"></dxi-item>
              <dxi-item name="image"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="clear"></dxi-item>
              <dxi-item name="blockquote"></dxi-item>
            </dxo-toolbar>
            <dxo-media-resizing [enabled]="true"> </dxo-media-resizing>
            <dxo-image-upload
              [tabs]="['file', 'url']"
              fileUploadMode="base64"
              [fileUploaderOptions]="{}"
            >
            </dxo-image-upload>
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="You must write a short story about your campaign"
              >
              </dxi-validation-rule>
            </dx-validator>
          </dx-html-editor>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">Story Summery </label>
          <dx-text-area
            class="input_field"
            [height]="68"
            [(ngModel)]="campaignModel.description"
            placeholder="Write a short story summery"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Story Summery is required."
              >
              </dxi-validation-rule>
              <dxi-validation-rule
                type="stringLength"
                [max]="102"
                [trim]="false"
                message="102 characters maximum"
              >
              </dxi-validation-rule
            ></dx-validator>
          </dx-text-area>
        </div>
        <div class="form-group">
          <div class="input_div">
            <label class="field-label requiredMark">Cover Photo/Video</label>
            <div
              class="file-upload-container"
              (drop)="onDropSuccess($event)"
              (dragover)="onDragOver($event)"
            >
              <label class="w-100">
                <dx-text-box
                  class="uploader_input_field w-100"
                  placeholder="Drag and drop files here or browse file"
                  [ngModel]="coverImg.name"
                  [readOnly]="true"
                  [showClearButton]="false"
                >
                  <dx-validator validationGroup="validationGrp">
                    <dxi-validation-rule
                      type="required"
                      message="Cover image or video is required."
                    >
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
                <input
                  *ngIf="!coverImg?.url"
                  #ngoLogoInput
                  type="file"
                  class="box-file"
                  id="ngoLogoInput"
                  (change)="onFileChange($event)"
                  aria-hidden="true"
                  accept=".jpg, .jpeg, .png"
                />
              </label>
              <div
                *ngIf="!coverImg?.url"
                class="blue-button-md"
                type="button"
                (click)="triggerFileInput('ngoLogoInput')"
              >
                Browse File
              </div>
              <img
                class="cursor"
                title="Remove"
                *ngIf="coverImg.url && !edit_mode"
                src="./assets/images/icon/cross-icon.svg"
                (click)="removeCoverImgVdo()"
              />
            </div>
            <div class="field_footer_label">
              Note: Files should be in JPG, JPEG, PNG or MP4 format and size
              should be minimum 624x312px
            </div>
          </div>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">Contact Person Name</label>
          <dx-text-box
            class="input_field"
            [(ngModel)]="campaignModel.contact_person_name"
            placeholder="Name of contact Person"
            [showClearButton]="true"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Name is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark"
            >Contact Person Job Title</label
          >
          <dx-text-box
            class="input_field"
            [(ngModel)]="campaignModel.contact_person_job_title"
            placeholder="Contact person's job title"
            [showClearButton]="true"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Job title is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">Contact Information</label>
          <dx-text-box
            class="input_field"
            [(ngModel)]="campaignModel.contact_person_information"
            placeholder="Contact information (Email/Phone)"
            [showClearButton]="true"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Contact information is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </div>

      <div *ngIf="activeForm === 2" class="form_div">
        <div class="input_div">
          <label class="field_label requiredMark"
            >Specify the corporate match rate</label
          >
          <dx-number-box
            class="input_field"
            [(ngModel)]="campaignModel.matching_percentage"
            placeholder="Matching Percentage"
            [showClearButton]="true"
            format="#,##0'%'"
            [invalidValueMessage]="'Value must be a number'"
            [min]="0"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Percentage amount is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark"
            >Matching Limit per Employee (BDT)</label
          >
          <dx-number-box
            class="input_field"
            [(ngModel)]="campaignModel.matching_limit"
            placeholder="e.g, ৳ 5,000"
            [showClearButton]="true"
            format="৳ #,##0"
            [invalidValueMessage]="'Value must be a number'"
            [min]="0"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Limit amount is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">
            Enter the overall corporate matching budget (BDT)</label
          >
          <dx-number-box
            class="input_field"
            [(ngModel)]="campaignModel.matching_budget"
            placeholder="e.g, ৳ 500,000"
            [showClearButton]="true"
            format="৳ #,##0"
            [invalidValueMessage]="'Value must be a number'"
            [min]="0"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Budget amount is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>
      </div>

      <div *ngIf="activeForm === 3" class="form_div">
        <div class="input_div">
          <label class="field_label requiredMark">Fundraising Target</label>
          <dx-number-box
            class="input_field"
            [(ngModel)]="campaignModel.fundraising_target"
            placeholder="Enter the desired fundraising target amount (e.g. Min $XXX)"
            [showClearButton]="true"
            format="৳ #,##0"
            [invalidValueMessage]="'Value must be a number'"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Target amount is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">Select Date</label>
          <dx-select-box
            class="input_field"
            [(ngModel)]="selectDate"
            [dataSource]="[
              '1 Month',
              '2 Month',
              '3 Month',
              '4 Month',
              '5 Month',
              'Rolling',
              'Custom'
            ]"
            placeholder="Select..."
            (onValueChanged)="selectDateChange($event)"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Date is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
        <div
          *ngIf="selectDate === 'Custom'"
          class="d-flex justify-content-between"
        >
          <div class="input_div">
            <label class="field_label requiredMark">Start Date</label>
            <dx-date-box
              class="input_field"
              width="276px"
              [(ngModel)]="campaignModel.start_date"
              placeholder="dd/mm/yy"
              [displayFormat]="'dd/MM/yyyy'"
              [showClearButton]="true"
              [max]="campaignModel.end_date"
            >
              <dx-validator validationGroup="validationGrp">
                <dxi-validation-rule
                  type="required"
                  message="Start Date is required"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-date-box>
          </div>

          <div class="input_div">
            <label class="field_label requiredMark">End Date</label>
            <dx-date-box
              class="input_field"
              width="276px"
              [(ngModel)]="campaignModel.end_date"
              placeholder="dd/mm/yy"
              [displayFormat]="'dd/MM/yyyy'"
              [showClearButton]="true"
              [min]="campaignModel.start_date"
            >
              <dx-validator validationGroup="validationGrp">
                <dxi-validation-rule
                  type="required"
                  message="End Date is required"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-date-box>
          </div>
        </div>
      </div>

      <div *ngIf="activeForm === 4" class="form_div">
        <div class="input_div">
          <label class="field_label requiredMark"
            >Choose to run the campaign</label
          >
          <dx-radio-group
            class="input_field radio_group_field"
            [items]="[
              { name: 'With a Fundraising Team', value: true },
              { name: 'Without a Fundraising Team', value: false }
            ]"
            displayExpr="name"
            valueExpr="value"
            [(ngModel)]="hasFundraisingTeam"
            layout="horizontal"
          >
            <dx-validator validationGroup="validationGrp"> </dx-validator>
          </dx-radio-group>
          <div class="field_footer_label">
            Note: Choose to lead the campaign with a team or rely on
            organization-led efforts.
          </div>
        </div>
        @if(hasFundraisingTeam) {
        <div class="input_div">
          <label class="field_label requiredMark"
            >Manage Fundraiser Member</label
          >
          <dx-radio-group
            class="input_field radio_group_field"
            [items]="[
              { name: 'Public', value: false },
              { name: 'Private', value: true }
            ]"
            displayExpr="name"
            valueExpr="value"
            [(ngModel)]="campaignModel.is_fundraiser_private"
            layout="horizontal"
          >
            <dx-validator validationGroup="validationGrp"> </dx-validator>
          </dx-radio-group>
          <div class="field_footer_label">
            Note: Set team visibility to either Public (open to all) or Private
            (by invitation only).
          </div>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">Email</label>
          <dx-tag-box
            class="input_field"
            [dataSource]="emailsList"
            placeholder="Select or type member email"
            [acceptCustomValue]="true"
            (onCustomItemCreating)="addEmail($event)"
            [showClearButton]="true"
            [searchEnabled]="true"
            [searchTimeout]="100"
            [showDropDownButton]="true"
            [multiline]="false"
            selectAllMode="allPages"
            [maxDisplayedTags]="3"
            [showMultiTagOnly]="false"
            [(value)]="campaignModel.emails"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Email is required"
              ></dxi-validation-rule>
              <dxi-validation-rule
                type="email"
                message="Invalid email address"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-tag-box>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">Message</label>
          <dx-text-area
            class="text_area_field"
            placeholder="Your message to fundraiser members"
            [(ngModel)]="campaignModel.message"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Message is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-area>
        </div>
        }
      </div>

      <div *ngIf="activeForm === 5" class="form_div">
        <div class="input_div">
          <label class="field_label requiredMark"
            >Select Your Payment Method</label
          >
          <dx-radio-group
            class="input_field radio_group_field payment"
            [disabled]="true"
            height="136"
            [items]="[
              { name: 'Shohay Payment System', value: true },
              { name: 'Your Own Payment System', value: false },
              { name: 'Redirect to your website', value: false }
            ]"
            displayExpr="name"
            valueExpr="value"
            [(ngModel)]="hasFundraisingTeam"
            layout="vertical"
          >
            <dx-validator validationGroup="validationGrp"> </dx-validator>
          </dx-radio-group>
          <div class="field_footer_label">
            Note: Organizations can enter payment details using their own
            gateway or Shohay's built-in option.
          </div>
        </div>
        <div class="input_div">
          <label class="field_label"
            >Payments will be transferred to your registered bank
            account.</label
          >
          <div class="bank_accounts">
            <div class="add_method">
              Withdrawal methods
              <button
                class="white-button-sm"
                (click)="openAddMethodPopup(true)"
              >
                Add Method
              </button>
            </div>
            <div class="mt-3">
              <dx-radio-group
                [items]="bankAccounts"
                [(value)]="selectedBankAccount"
              >
                <div *dxTemplate="let account of 'item'; let ac = index">
                  <div class="account">
                    <div>{{ account?.bank_name }}</div>
                    <div class="d-flex gap-2">
                      <img
                        title="Edit"
                        (click)="editAccount(ac)"
                        src="assets/images/icon/edit_icon_active.svg"
                      />
                      <img
                        title="Delete"
                        (click)="deleteAccount(ac)"
                        src="assets/images/icon/delete_icon_red.svg"
                      />
                    </div>
                  </div>
                </div>
              </dx-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div class="button_group">
        <div class="d-flex gap-3">
          <dx-button
            class="blue-button-md"
            text="< Back"
            [disabled]="activeForm === 1"
            (onClick)="nextForm(-1)"
            [disabled]="edit_mode"
          ></dx-button>
          <dx-button
            class="blue-button-md"
            [text]="activeForm === 5 ? 'Preview' : 'Next >'"
            (onClick)="activeForm === 5 ? showHidePopup(true) : nextForm(1)"
            [disabled]="edit_mode"
          ></dx-button>
          <dx-button
            *ngIf="edit_mode"
            class="blue-button-md"
            [text]="'Update'"
            (onClick)="updateCampaign()"
          ></dx-button>
        </div>
        <dx-button
          class="blue-button-md"
          text="Cancel"
          (click)="cancelFunctionality()"
        ></dx-button>
      </div>
    </div>
  </div>
</div>

<dx-popup
  [(visible)]="isOpenAddMethodPopup"
  [minWidth]="1000"
  [minHeight]="620"
  [maxWidth]="1000"
  [maxHeight]="620"
  [showTitle]="true"
  title="Bank Information"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div style="font-size: 18px; font-weight: 700">
    <span>Get access to your funds in 3-5 business days with no fees. </span>
    <span style="text-decoration: underline">See how bank transfers work</span>
    <div class="breadcrumb">
      Incorrect information can mean a delay in receiving funds or fees.
    </div>
  </div>

  <div class="preview_div" style="justify-content: start; height: auto">
    <div class="preview_content_div" style="height: 326px; margin-top: 0px">
      <div class="input_div">
        <label class="field_label requiredMark">Name of Bank</label>
        <dx-text-box
          class="input_field"
          [(ngModel)]="bankAccount.bank_name"
          [showClearButton]="true"
        >
          <dx-validator validationGroup="accountValidationGrp">
            <dxi-validation-rule
              type="required"
              message="Bank Name required"
            ></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
      <div class="input_div">
        <label class="field_label requiredMark">Account holder name</label>
        <dx-text-box
          class="input_field"
          [(ngModel)]="bankAccount.account_holder_name"
          [showClearButton]="true"
        >
          <dx-validator validationGroup="accountValidationGrp">
            <dxi-validation-rule
              type="required"
              message="Account holder name is required"
            ></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
      <div class="account_routing">
        <div class="input_div" style="width: 60%">
          <label class="field_label requiredMark">Account Number</label>
          <dx-text-box
            class="input_field"
            [(ngModel)]="bankAccount.account_no"
            [showClearButton]="true"
          >
            <dx-validator validationGroup="accountValidationGrp">
              <dxi-validation-rule
                type="required"
                message="Account number is required"
              ></dxi-validation-rule>
              <dxi-validation-rule
                type="pattern"
                [pattern]="'^[0-9]+$'"
                message="Invalid account number."
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <div class="input_div" style="width: 40%">
          <label class="field_label requiredMark">Routing Number</label>
          <dx-text-box
            class="input_field"
            [(ngModel)]="bankAccount.routing_no"
            [showClearButton]="true"
          >
            <dx-validator validationGroup="accountValidationGrp">
              <dxi-validation-rule
                type="required"
                message="Routing number is required"
              ></dxi-validation-rule>
              <dxi-validation-rule
                type="pattern"
                [pattern]="'^[0-9]+$'"
                message="Invalid routing number."
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </div>
    </div>

    <div class="breadcrumb">
      <div>
        By adding this bank account you are confirming that you are the owner
        and have full authorization to this bank account.
      </div>
      <div class="caution">
        <img src="assets/images/icon/knowledge_icon.svg" />
        <span>This payment method will become active in 3 days.</span>
      </div>
    </div>

    <div class="preview_button_div" style="height: 44px; margin-top: 0px">
      <button class="yellow-button-md" (click)="openAddMethodPopup(false)">
        Cancel
      </button>
      <button
        class="blue-button-md"
        (click)="editBankAccount ? saveEditedAccount() : addBankAccount()"
      >
        {{ editBankAccount ? "Save" : "Add Bank Account" }}
      </button>
    </div>
  </div>
</dx-popup>

<dx-popup
  [(visible)]="showPopUp"
  [minWidth]="1000"
  [minHeight]="550"
  [maxWidth]="1000"
  [maxHeight]="800"
  [showTitle]="true"
  title="Preview Campaign"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <dxi-toolbar-item
    cssClass="expand_button"
    widget="dxButton"
    toolbar="top"
    location="after"
    [options]="expandButtonOption"
  >
  </dxi-toolbar-item>
  <div class="preview_div">
    <div class="preview_content_div">
      <img class="preview_img" [src]="coverImg.url" />
      <div class="preview_author_div">
        <img
          class="verified_logo"
          style="border-radius: 50%"
          [src]="
            sessionUser?.company_logo_url
              ? sessionUser?.company_logo_url?.url
              : './assets/images/icon/profile_icon_blue.svg'
          "
        />
        <span>Organized by </span>
        <span style="font-weight: 500">{{ campaignModel.company_name }}</span>
        <img
          class="verified_logo"
          [src]="'./assets/images/icon/verified_icon_green.svg'"
        />
      </div>
      <div class="preview_title_div">
        <div class="preview_title">
          {{ campaignModel.title }}
        </div>
        <div class="preview_subtitle">
          {{ campaignModel.subtitle }}
        </div>
      </div>
      <div
        class="preview_story"
        [innerHTML]="campaignModel.story_rich_text"
      ></div>
    </div>
    <div class="preview_button_div">
      <button class="yellow-button-md" (click)="showHidePopup(false)">
        Back to edit
      </button>
      <button class="blue-button-md" (click)="submit()">Create Campaign</button>
    </div>
  </div>
</dx-popup>

<div *ngIf="fullScreenPopup" class="main_div">
  <div class="preview_div">
    <div class="preview_content_div">
      <img class="preview_img" [src]="coverImg.url" />
      <div class="preview_author_div">
        <img
          class="verified_logo"
          style="border-radius: 50%"
          [src]="
            sessionUser?.company_logo_url
              ? sessionUser?.company_logo_url?.url
              : './assets/images/icon/profile_icon_blue.svg'
          "
        />
        <span>Organized by </span>
        <span style="font-weight: 500">{{ campaignModel.company_name }}</span>
        <img
          class="verified_logo"
          [src]="'./assets/images/icon/verified_icon_green.svg'"
        />
      </div>
      <div class="preview_title_div">
        <div class="preview_title">
          {{ campaignModel.title }}
        </div>
        <div class="preview_subtitle">
          {{ campaignModel.subtitle }}
        </div>
      </div>
      <div
        class="preview_story"
        [innerHTML]="campaignModel.story_rich_text"
      ></div>
    </div>
    <div class="preview_button_div">
      <button class="yellow-button-md" (click)="showHidePopup(false)">
        Back to edit
      </button>
      <button class="blue-button-md" (click)="submit()">Create Campaign</button>
    </div>
  </div>
</div>
