import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UrlEnum } from '../../enum/url.enum';
import { CampaignDonationTransactionModel } from '../../model/campaign-donation.model';
import { EmergencyDonationModel } from '../../model/emergency-donation.model';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';
import { DonationStatusModel } from '../../model/donation-status.model';
import { NgoCampaignUpdateModel } from '../../model/ngo-campaign.model';

@Injectable({
  providedIn: 'root',
})
export class DonationService {
  public DonationFeeRate: number = 0.014;

  // Unused Api Url
  private emergencyDonationUrl = `${UrlEnum.SHOHAY_NGO}/emergency-donation`;
  ///

  private campaignUrl = `${UrlEnum.SHOHAY_NGO}/ngo-donation/campaign`;
  private donationUrl = `${UrlEnum.SHOHAY_NGO}/ngo-donation/donation`;
  private commentUrl = `${UrlEnum.SHOHAY_NGO}/ngo-donation/comment`;
  private donorUrl = `${UrlEnum.SHOHAY_NGO}/ngo-donation/donor`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public emergencyDonationRequest(
    body: EmergencyDonationModel
  ): Observable<any> {
    return this.utilitiesService.responseHandlerWithAlert(
      this.httpRequestService.post(
        `${this.emergencyDonationUrl}/create`,
        body,
        {
          skipAuth: true,
        }
      ),
      true
    );
  }

  public getAllDonation(): Observable<any> {
    return this.httpRequestService
      .get(`${this.emergencyDonationUrl}/get-all`, {
        skipAuth: true,
      })
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) =>
          x.sort(
            this.utilitiesService.dynamicSortWithDate('created_at', 'desc')
          )
        )
      );
  }

  public getRecentDonations(pageNo: number, size: number): Observable<any> {
    return this.httpRequestService
      .get(`${this.donationUrl}/get-all?page=${pageNo}&size=${size}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getDonationCampaignById(
    ngoId: string,
    projectId: string,
    id: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('ngo_id', ngoId)
      .set('project_id', projectId)
      .set('id', id);

    return this.httpRequestService
      .get(`${this.campaignUrl}/get-by-id`, { params, skipAuth: true })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public getDonationListByCampaign(
    ngoId: string,
    projectId: string,
    campaignId: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('ngo_id', ngoId)
      .set('project_id', projectId)
      .set('campaign_id', campaignId);

    return this.httpRequestService
      .get(`${this.donationUrl}/get-by-campaign`, { params, skipAuth: true })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public getAllDonationForSuperAdmin(
    page: number,
    size: number
  ): Observable<any> {
    return this.httpRequestService
      .get(`${this.donationUrl}/get-all?page=${page}&size=${size}`)
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public makeCampaignDonation(
    body: CampaignDonationTransactionModel
  ): Observable<any> {
    return this.httpRequestService
      .post(`${this.donationUrl}/create`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getDonationCampaignCommentReply(campaignId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.commentUrl}/get-by-campaign/${campaignId}`, {
        skipAuth: true,
      })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public getDonationCampaignDonorExperienceComment(
    campaignId: string
  ): Observable<any> {
    return this.httpRequestService
      .get(`${this.commentUrl}/get-all-donor-experience/${campaignId}`, {
        skipAuth: true,
      })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public makeReply(replyBody: any): Observable<any> {
    return this.httpRequestService
      .post(`${this.commentUrl}/create-reply/${replyBody.parent_id}`, replyBody)
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public updateDonationCampaign(
    body: NgoCampaignUpdateModel,
    id: string
  ): Observable<any> {
    return this.httpRequestService
      .patch(`${this.campaignUrl}/update/${id}`, body)
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public getDonationByEmail(email: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.donationUrl}/get-by-email?email=${email}`, {
        skipAuth: true,
      })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public updateStatus(id: string, body: DonationStatusModel): Observable<any> {
    return this.httpRequestService
      .patch(`${this.donationUrl}/update-status/${id}`, body)
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public updateComment(id: string, body: {comment: string}): Observable<any> {
    return this.httpRequestService
      .patch(`${this.donationUrl}/update-comment/${id}`, body)
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public getDonationWallComment(): Observable<any> {
    return this.httpRequestService
      .get(`${this.commentUrl}/get-wall-comment`, {
        skipAuth: true,
      })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public addCommentToExperienceWall(
    commentId: string,
    add: boolean
  ): Observable<any> {
    return this.httpRequestService
      .patch(`${this.commentUrl}/update-donor-experience/${commentId}`, {
        is_donor_experience: add,
      })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public deleteComment(campaignId: string, commentId: string): Observable<any> {
    return this.httpRequestService
      .delete(
        `${this.commentUrl}/delete-by-id/campaigns/${campaignId}/id/${commentId}`
      )
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public getTopCampaignWithDonation(year: string): Observable<any> {
    const params = new HttpParams().set('limit', 10);
    return this.httpRequestService
      .get(`${this.campaignUrl}/get-top-campaign-with-top-donation/${year}`, {
        params,
        skipAuth: true,
      })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public getLastMonthDonorList(): Observable<any> {
    const currentDate = new Date();
    const lastMonth = currentDate.getMonth();
    const lastMonthNumber = lastMonth === 0 ? 12 : lastMonth;

    const params = new HttpParams().set('limit', 10);
    return this.httpRequestService
      .get(`${this.donorUrl}/get-by-month/${lastMonthNumber}`, {
        params,
        skipAuth: true,
      })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public getAllDonorWithPagination(
    page: number,
    size: number
  ): Observable<any> {
    return this.httpRequestService
      .get(`${this.donorUrl}/get-all?page=${page}&size=${size}`)
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }

  public getDonorProfile(userId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.donorUrl}/get-profile-by-id/${userId}`)
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }
}
