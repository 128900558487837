import { Component } from '@angular/core';

@Component({
  selector: 'app-donor-profile',
  templateUrl: './donor-profile.component.html',
  styleUrl: './donor-profile.component.scss',
})
export class DonorProfileComponent {
  public activeTabIndex = 0;
  public editMode = false;
  public showDonations: boolean = false;
  public showDeletePopup: boolean = false;
  public showConfirmDeletePopup: boolean = false;

  public showDeactivatePopup: boolean = false;
  public isNewPasswordVisible = false;

  public toggleStates = {
    recentDonationSwitchButton: false,
    campaignsSwitchButton: false,
    activityToOtherUserSwitchButton: false,
    receiveShohayUpdateSwitchButton: false,
    campaignAlertsSwitchButton: false,
    smsNotificationSwitchButton: false,
    milestonesSwitchButton: false,
    donationRemindersSwitchButton: false,
  };
  radioOptions = [
    {
      text: '',
      description:
        'Deactivating your account is temporary. Your account and profile will be deactivated and your name will be removed from most campaigns.',
      value: 'deactivate',
    },
  ];

  connectedList: any = [
    {
      img : 'assets/images/icon/usaid_icon.svg',
    },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
  ];

  public onTabChange(event: any) {
    this.activeTabIndex = event.component.option('selectedIndex');
  }

  public toggleEditMode() {
    this.editMode = !this.editMode;
  }

  public onToggle(event: any): void {
    console.log('Toggle state:', event.value);
  }

  public deactivateButton() {
    this.showDeactivatePopup = true;
  }
  public deleteButton() {
    this.showDeletePopup = true;
  }
  public confirmDelete() {
    this.showDeactivatePopup = false;
    this.showConfirmDeletePopup = true;
  }

  public toggleNewPasswordVisibility = () => {
    this.isNewPasswordVisible = !this.isNewPasswordVisible;
  };

  public onToggleChange(toggleKey: string, value: boolean) {
    this.toggleStates[toggleKey] = value;
    console.log('Updated Toggle States:', this.toggleStates);
  }
}
