import { Component, OnInit } from '@angular/core';
import { FundraiserService } from 'src/app/shared/services/fundraiser/fundraiser.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-all-fundraisers',
  templateUrl: './all-fundraisers.component.html',
  styleUrl: './all-fundraisers.component.scss',
})
export class AllFundraisersComponent implements OnInit {
  public utilitiesService = UtilitiesService;

  public allFundraisers: any[] = [];

  // public currentPage = 0;
  // public totalPages = 1;
  // public pages: null[] = [];

  constructor(private readonly fundraiserService: FundraiserService) {}

  ngOnInit(): void {
    this.getAllFundraisers();
  }

  public getAllFundraisers(): void {
    this.fundraiserService.getTopFundraisers(1, 10).subscribe((result: any) => {
      result?.data.forEach((element) => {
        element.profile_img = this.utilitiesService.getUrl(element.profile_img);
        element?.team_info?.forEach((team) => {
          team.team_logo = this.utilitiesService.getUrl(team.team_logo);
        });
      });

      if (result?.total > 10) {
        this.allFundraisers = Array(result?.total).fill(null);
        this.allFundraisers.splice(0, result?.data.length, ...result?.data);
      } else {
        this.allFundraisers = result?.data;
      }
    });
  }

  public loadMorePage(e: any): void {
    if (e?.fullName === 'paging.pageIndex') {
      const index = e?.value * 10;
      if (this.allFundraisers[e?.value * 10] === null) {
        this.fundraiserService
          .getTopFundraisers(e?.value + 1, 10)
          .subscribe((result: any) => {
            result?.data.forEach((element) => {
              element.profile_img = this.utilitiesService.getUrl(
                element.profile_img
              );
              element?.team_info?.forEach((team) => {
                team.team_logo = this.utilitiesService.getUrl(team.team_logo);
              });
            });

            this.allFundraisers.splice(
              e?.value * 10,
              result?.data.length,
              ...result?.data
            );
          });
      }
    }
  }
}
