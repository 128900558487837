<div class="main">
  <div class="body">
    <div class="hero">
      <div class="hero-right">
        <img class="w-100" src="assets/images/50_years.png" />
      </div>
      <div class="hero-left">
        <div class="hero-title">
          Stay updated with the latest insights from Shohay.
        </div>
        <div class="hero-description">
          Discover inspiring stories, expert insights, and the latest news from
          Shohay.
        </div>
        <div class="ngo-search-wrapper">
          <input
            class="ngo-search-input"
            type="text"
            placeholder="Search article"
            [(ngModel)]="searchValue"
            (keyup.enter)="onSearchClick()"
          />
          <button class="blue-button-md" (click)="onSearchClick()">
            Search
          </button>
        </div>
      </div>
    </div>

    <div class="row d-flex gap-3">
      <div *ngFor="let article of searchResults" class="card">
        <div class="cover_pic">
          <img [src]="article.cover_photo" />
        </div>
        <div class="ngo">
          <div>
            {{ article.related_organization }}
            <img src="assets/images/icon/checkmark-badge.svg" />
          </div>
          <div class="date">{{ article.createdAt | date : "dd MMM yyyy" }}</div>
        </div>
        <div class="title">
          {{ article.title }}
        </div>
        <div class="topic">{{ article.topic }}</div>
        <div
          class="read_more"
          (click)="goToArticleNews(article.id, article.news_article_id)"
        >
          Read More<img
            class="ml-2"
            src="assets/images/icon/arrow-right-alt.svg"
          />
        </div>
      </div>
    </div>

    <button
      class="blue-button-md"
      [disabled]="pagination?.page === pagination?.pageCount"
      (click)="loadNextPage()"
    >
      Load More
    </button>
  </div>
</div>
