import { Injectable } from '@angular/core';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { UrlEnum } from '../../enum/url.enum';
import { map, Observable } from 'rxjs';
import {
  FundraiserModel,
  FundraiserTeamModel,
} from '../../model/fundraiser.model';

@Injectable({
  providedIn: 'root',
})
export class FundraiserService {
  private fundraiserAPI = `${UrlEnum.SHOHAY_NGO}/ngo-donation/fund-raiser`;
  private topFundraiserAPI = `${UrlEnum.SHOHAY_NGO}/ngo-donation/top-fund-raiser`;
  private fundraiserTeamAPI = `${UrlEnum.SHOHAY_NGO}/ngo-donation/fund-raiser-team`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public getAllTeams(campaignId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.fundraiserTeamAPI}/get-by-campaign/${campaignId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getAllTeamsPaginated(pageNo: number, size: number): Observable<any> {
    return this.httpRequestService
      .get(
        `${this.fundraiserTeamAPI}/get-all-paginated?page=${pageNo}&size=${size}`
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getAllTeamsWithFundraisers(campaignId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.fundraiserAPI}/get-all-by-campaign/${campaignId}`, {
        skipAuth: true,
      })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createTeam(teamBody: FundraiserTeamModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.fundraiserTeamAPI}/create`, teamBody)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public joinAsFundraiser(body: FundraiserModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.fundraiserAPI}/create`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getFundraiserHistoryByUserID(UserId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.fundraiserAPI}/get-by-user/${UserId}`, {
        skipAuth: true,
      })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getFundraiserProfile(userId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.fundraiserAPI}/get-profile-by-user/${userId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getFundraisingTeamHistory(
    userId: string,
    campaignId: string,
    teamId: string
  ): Observable<any> {
    return this.httpRequestService
      .get(
        `${this.fundraiserAPI}/get-all-by-campaign-and-team/${userId}/${campaignId}/${teamId}`
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getTopFundraisers(pageNo: number, size: number): Observable<any> {
    return this.httpRequestService
      .get(`${this.topFundraiserAPI}/get-all?page=${pageNo}&size=${size}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getFundraiserDashboardData(
    startDate: Date,
    endDate: Date
  ): Observable<any> {
    return this.httpRequestService
      .get(
        `${this.fundraiserAPI}/get-dashboard-summary?start_date=${startDate}&end_date=${endDate}`
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
