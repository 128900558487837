import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';

@Component({
  selector: 'app-donor-profile',
  templateUrl: './donor-profile.component.html',
  styleUrl: './donor-profile.component.scss',
})
export class DonorProfileComponent {
  public donorInfo: any;
  public recentDonations: any[];
  public teamItems: any[];
  public userId: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly donationService: DonationService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const encryptedString = params['data'];
      const decryptedParams =
        this.localStorageService.decryptObjectUrl(encryptedString);
      if (decryptedParams) {
        this.userId = decryptedParams.user_id;
        this.getDonorProfile();
      }
    });
  }

  private getDonorProfile() {
    this.donationService.getDonorProfile(this.userId).subscribe({
      next: (response) => {
        this.donorInfo = response.donor_info;
        this.recentDonations = response.recent_donations;
        this.teamItems = this.donorInfo.teams.map((team: any) => ({
          text: team?.team_name,
          icon: JSON.parse(team?.team_logo).url,
        }));
      },
    });
  }
}
