<div class="root">
  <div class="first_div_div">
    <div class="w-1280 first_div row mt-4">
      <div
        class="d-flex gap-4 flex-column justify-content-around px-0 col-md-12 col-lg-6"
      >
        <div class="transform">Transform the Way Your NGO Works</div>
        <div class="streamline">
          <span style="font-weight: 700">EFFORTLESSLY</span> - Streamline
          operations, build trust with donors maximize impact.
        </div>
        <div class="d-flex gap-4 mb-4">
          <button class="blue-button-lg">Try Shohay Beta</button>
          <button class="yellow-button-lg">Explore Features</button>
        </div>
      </div>

      <div class="px-0 col-md-12 col-lg-6">
        <img width="100%" src="/assets/images/Group_39578.jpg" />
      </div>
    </div>
  </div>

  <div class="w-1280 second_div">
    <div class="our_product mb-3">Our Product Suite</div>
    <div class="whatever_your text-center w-75 mb-5">
      Whatever your organization’s doing, just don’t do it - ENABLE it. Drive
      your organization’s effort to greater efficiency with the help of Shohay
    </div>

    <div class="row mt-5">
      <div
        class="d-flex gap-4 flex-column justify-content-around col-md-12 col-lg-6"
      >
        <div class="our_product">Fundraising Simplified</div>
        <!-- <div class="streamline">
          Simplify your fundraising process with tools designed for quick setup
          and effective communication. Ensure smooth transactions and compliance
          with detailed reporting and flexible payment options.
        </div> -->
        <div class="whatever_your fw-bolder">Features:</div>
        <div class="features_div">
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Pre-designed donor forms for quick campaign setup.</span
          >
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Real-time communication tools (WhatsApp/call integration).</span
          >
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Detailed donation reports for pledged vs. received amounts.</span
          >
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Options to choose between Shohay’s payment gateway or NGO’s
            own.</span
          >
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Compliance with fund disbursement documentation.</span
          >
        </div>
        <button class="white-button-lg mb-4">
          Feature Now <img src="/assets/images/icon/arrow-right-alt.svg" />
        </button>
      </div>

      <div class="col-md-12 col-lg-6">
        <img width="100%" src="/assets/images/Group_39581.jpg" />
      </div>
    </div>
  </div>

  <div class="w-1280 py-0">
    <div class="row mt-5">
      <div class="col-md-12 col-lg-6">
        <img width="100%" src="/assets/images/Group_39579.jpg" />
      </div>

      <div
        class="d-flex gap-4 flex-column justify-content-around col-md-12 col-lg-6"
      >
        <div class="our_product">Customizable Data Collection Tool</div>
        <!-- <div class="streamline">
          Empower your data gathering with versatile tools that work both online
          and offline. Transform collected data into meaningful insights to
          drive your project and campaign decisions.
        </div> -->
        <div class="whatever_your fw-bolder">Features:</div>
        <div class="features_div">
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Easy-to-use tools for creating surveys and forms.</span
          >
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Real-time and offline data collection with visual
            dashboards.</span
          >
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Actionable insights for improving project and campaign
            strategies.</span
          >
        </div>
        <button class="white-button-lg mb-4">
          Create Forms <img src="/assets/images/icon/arrow-right-alt.svg" />
        </button>
      </div>
    </div>
  </div>

  <div class="w-1280">
    <div class="row mt-5">
      <div
        class="d-flex gap-4 flex-column justify-content-around col-md-12 col-lg-6"
      >
        <div class="our_product">Dashboard & Reporting</div>
        <!-- <div class="streamline">
          Gain a comprehensive view of your fundraising activities with advanced
          analytics. Track progress, understand donor behavior, and optimize
          your strategies for better outcomes.
        </div> -->
        <div class="whatever_your fw-bolder">Features:</div>
        <div class="features_div">
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Dashboards for tracking donations, fundraisers engagement and fund
            usage.</span
          >
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Donation campaigns overview - List of ongoing, past campaign and
            total raised per campaign
          </span>
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Real-time donation insights (e.g., pledged vs. received tracking,
            target vs goal achieved).</span
          >
        </div>
        <button class="white-button-lg mb-4">
          My Insights <img src="/assets/images/icon/arrow-right-alt.svg" />
        </button>
      </div>

      <div class="col-md-12 col-lg-6">
        <img width="100%" src="/assets/images/Group_39555.jpg" />
      </div>
    </div>
  </div>

  <div class="w-1280 second_div py-3">
    <div class="our_product mb-3">Coming Soon on Shohay</div>
    <div class="streamline text-center w-80 mb-5">
      At Shohay, we know the challenges and opportunities that NGOs face in
      Bangladesh. Our integrated suite offers the resources and backing
      necessary to gather knowledge, consolidate processes, enhance efficacy,
      and drive societal progress.
    </div>

    <div
      class="row justify-content-between gap-5 t-5"
      style="padding-inline: 1%"
    >
      <div class="coming_shohay_card col-3">
        <img src="/assets/images/icon/donor_yellow.svg" />
        <div class="whatever_your fw-bolder">Donor Connect</div>
        <div class="engage_retain">
          Engage, retain, and expand your donor base.
        </div>
      </div>
      <div class="coming_shohay_card col-3">
        <img src="/assets/images/icon/program_master_yellow.svg" />
        <div class="whatever_your fw-bolder">Program Master</div>
        <div class="engage_retain">
          Manage large-scale, multi-project initiatives.
        </div>
      </div>
      <div class="coming_shohay_card col-3">
        <img src="/assets/images/icon/project_flow_yellow.svg" />
        <div class="whatever_your fw-bolder">Project Flow</div>
        <div class="engage_retain">
          Deliver projects with clear timelines, task delegation, and
          collaboration.
        </div>
      </div>
      <div class="coming_shohay_card col-3">
        <img src="/assets/images/icon/raise_pro_yellow.svg" />
        <div class="whatever_your fw-bolder">Raise Pro</div>
        <div class="engage_retain">
          Create impactful campaigns and maximize fundraising efforts.
        </div>
      </div>
      <div class="coming_shohay_card col-3">
        <img src="/assets/images/icon/good_giving_yellow.svg" />
        <div class="whatever_your fw-bolder">Good Giving</div>
        <div class="engage_retain">
          Maximize corporate giving efficiency and measure ROI.
        </div>
      </div>
      <div class="coming_shohay_card col-3">
        <img src="/assets/images/icon/impact_360_yellow.svg" />
        <div class="whatever_your fw-bolder">Impact 360</div>
        <div class="engage_retain">
          Streamline monitoring & evaluation with precision.
        </div>
      </div>
      <div class="coming_shohay_card col-3">
        <img src="/assets/images/icon/grant_builder_yellow.svg" />
        <div class="whatever_your fw-bolder">Grant Builder</div>
        <div class="engage_retain">
          Secure grants and partnerships with minimal effort and cost.
        </div>
      </div>
      <div class="coming_shohay_card col-3">
        <img src="/assets/images/icon/volunteer_yellow.svg" />
        <div class="whatever_your fw-bolder">Volunteer Link</div>
        <div class="engage_retain">
          Enable intuitive volunteer and participant management.
        </div>
      </div>
    </div>
  </div>

  <div class="w-1280 second_div py-3 mt-5">
    <div class="our_product mb-3">Why Choose Shohay?</div>
    <div class="streamline text-center w-80 mb-5">
      At Shohay, we know the challenges and opportunities that NGOs face in
      Bangladesh. Our integrated suite offers the resources and backing
      necessary to gather knowledge, consolidate processes, enhance efficacy,
      and drive societal progress.
    </div>

    <div class="row justify-content-between w-100 t-5">
      <div class="py-3 px-0 col-6">
        <div class="why_choose_shohay_card" style="margin-right: 16px">
          <img src="/assets/images/icon/infinity_blue.svg" />
          <div class="d-flex gap-3 flex-column">
            <div class="whatever_your fw-bolder">All-in-One Solution</div>
            <div class="engage_retain">
              Shohay centralizes all aspects of NGO information and management
              in one intuitive platform.
            </div>
          </div>
        </div>
      </div>
      <div class="py-3 px-0 col-6">
        <div class="why_choose_shohay_card" style="margin-left: 16px">
          <img src="/assets/images/icon/diversion_blue.svg" />
          <div class="d-flex gap-3 flex-column">
            <div class="whatever_your fw-bolder">Total Flexibility</div>
            <div class="engage_retain">
              Customize your experience. Access exactly what you need, when you
              need it.
            </div>
          </div>
        </div>
      </div>
      <div class="py-3 px-0 col-6">
        <div class="why_choose_shohay_card" style="margin-right: 16px">
          <img src="/assets/images/icon/location_blue.svg" />
          <div class="d-flex gap-3 flex-column">
            <div class="whatever_your fw-bolder">Local Expertise</div>
            <div class="engage_retain">
              Shohay platform is built by people who have extensive knowledge of
              NGOs in Bangladesh.
            </div>
          </div>
        </div>
      </div>
      <div class="py-3 px-0 col-6">
        <div class="why_choose_shohay_card" style="margin-left: 16px">
          <img src="/assets/images/icon/click_blue.svg" />
          <div class="d-flex gap-3 flex-column">
            <div class="whatever_your fw-bolder">Click and Connect</div>
            <div class="engage_retain">
              Before Shohay, connection between donors and impactful NGOs has
              never been this easier.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-1280" style="max-width: 1254px">
    <div class="last_div row">
      <div class="col-md-12 col-lg-6">
        <div class="our_product text-light mb-3">
          Raise funds faster, with Shohay
        </div>
        <div class="streamline text-light mb-5">
          Have an NGO? Simplify management with our advanced platform, or
          Starting a New NGO? Register today and kickstart your initiatives with
          our comprehensive support
        </div>
      </div>

      <div
        class="col-md-12 col-lg-6 d-flex align-items-center gap-3 justify-content-between"
        style="padding-left: 32px"
      >
        <button
          class="yellow-button-lg w-50"
          style="border: 2px solid #ffd602"
          routerLink="/auth/signup"
        >
          Sign Up Now - It’s FREE
        </button>
        <button
          class="blue-button-lg w-50"
          style="border: 2px solid white; color: white"
          routerLink="/contact-us"
        >
          Request a demo <img src="/assets/images/icon/arrow_right_icon.svg" />
        </button>
      </div>
    </div>
  </div>
</div>
