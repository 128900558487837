<dx-popup
  [(visible)]="showPopup"
  [width]="1000"
  [height]="650"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div class="fs_18">
    <div class="d-flex w-100 justify-content-between align-items-center mb-5">
      <div>
        <img
          width="142px"
          height="35px"
          src="./assets/images/logo/shohay_logo_full.svg"
        />
      </div>
      <div class="fs_24">
        <span>Payment Receipt: </span>
        <span class="c_0054a6">{{ receipt?.invoice_no }}</span>
      </div>
    </div>

    <div class="fs_24 fw-bold mb-3">Invoice to:</div>

    <div class="d-flex justify-content-between mb-2">
      <div>Name: {{ receipt?.invoice_to }}</div>
      <div>Date: {{ receipt?.invoice_date | date : "MMM dd, yyyy" }}</div>
    </div>
    <div class="d-flex justify-content-between mb-5">
      <div>Address: {{ receipt?.to_address }}</div>
      <div>
        <span>Payment Method: </span>
        <span class="method">{{ receipt?.payment_method }}</span>
      </div>
    </div>

    <div class="fs_24 fw-bold c_0054a6 mb-2">{{ receipt?.paid_to }}</div>

    <div class="table_row bc_f2f9ff border-bottom-0">
      <div class="col-3">{{ receipt?.col1Name }}</div>
      <div class="col-7">{{ receipt?.col2Name }}</div>
      <div class="col-2 text-end">{{ receipt?.col3Name }}</div>
    </div>
    <div class="table_row mb-3">
      <div class="col-3 fw-normal text-decoration-underline">
        {{ receipt?.col1Value }}
      </div>
      <div class="col-7 fw-normal text-decoration-underline">
        {{ receipt?.col2Value }}
      </div>
      <div class="text-dark fw-bolder col-2 text-end">
        ৳ {{ receipt?.col3Value | number }}
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <div class="total">
        <div class="fs_18 fw-bolder">Total</div>
        <div class="fs_16 fw-bolder c_0054a6">
          ৳ {{ receipt?.total_amount | number }}
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end mt-5">
    <button class="blue-button-md">Download</button>
  </div>
</dx-popup>
