[
  {
    "index": 1,
    "id": null,
    "href": null,
    "routerLink": "/corporate/dashboard",
    "name": "Dashboard",
    "activeIcon": "./assets/images/icon/dashboard_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/dashboard_icon_inactive.svg",
    "submenu": []
  },
  {
    "index": 2,
    "id": null,
    "href": null,
    "routerLink": "/corporate/campaigns/all-campaigns",
    "name": "Campaigns",
    "activeIcon": "./assets/images/icon/mike_tilted_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/mike_tilted_icon_inactive.svg",
    "submenu": []
  },
  {
    "index": 3,
    "id": null,
    "href": null,
    "routerLink": "/corporate/employee-engagement",
    "name": "Employee Engagement",
    "activeIcon": "./assets/images/icon/productivity_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/productivity_icon_inactive.svg",
    "submenu": []
  },
  {
    "index": 4,
    "id": null,
    "href": null,
    "routerLink": "/corporate/employee-management",
    "name": "Employees Management",
    "activeIcon": "./assets/images/icon/emp_management_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/emp_management_icon_inactive.svg",
    "submenu": []
  }
]
