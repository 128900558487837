import { GalleryService } from './../../../services/galley/gallery.service';
import { FileService } from 'src/app/shared/services/file/file.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-gallery',
  templateUrl: './campaign-gallery.component.html',
  styleUrl: './campaign-gallery.component.scss',
})
export class CampaignGalleryComponent implements OnInit {
  public actionTitle: string = '';

  @Input() galleryId: string;
  public gallery: any[] = [];
  private inputImageList: any;
  public selectedImages: boolean[] = [];
  private selectedImagesId: string[] = [];

  constructor(
    private readonly fileService: FileService,
    private readonly galleryService: GalleryService
  ) {}

  ngOnInit(): void {
    if (this.galleryId) {
      this.getGalleryImages();
    }
  }

  private getGalleryImages(): void {
    this.galleryService.getGallery(this.galleryId).subscribe((res: any) => {
      this.gallery = res;
      this.selectedImages = Array(this.gallery?.length).fill(false);
      this.selectedImagesId = [];
    });
  }

  public onFileChange(e: any): void {
    const input = e.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.inputImageList = input.files;
      this.addGalleryImages();
    }
  }

  private addGalleryImages(): void {
    this.fileService
      .uploadGalleryFiles(this.inputImageList, this.galleryId)
      .subscribe((res: any) => {
        this.getGalleryImages();
      });
  }

  public changeActionTitle(e: any): void {
    this.actionTitle = e.itemData.text;
  }

  public action(): void {
    this.selectedImagesId = this.gallery
      .filter((_, index) => this.selectedImages[index])
      .map((obj) => obj.file_id);
    if (this.selectedImagesId?.length > 0) {
      this[this.actionTitle.toLowerCase() + 'Images']();
    }
  }

  private deleteImages(): void {
    this.fileService
      .deleteFileMultiple(this.selectedImagesId)
      .subscribe((res: any) => {
        this.getGalleryImages();
      });
  }
  private shareImages(): void {
    console.log('shareImages');
  }
  private downloadImages(): void {
    console.log('downloadImages');
  }

  public triggerFileInput(inputId: string): void {
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  public extractedText(text: string): string {
    return text.split('/')[0].toUpperCase();
  }
}
