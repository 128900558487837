<div class="recent_donation_data_grid">
  <dx-data-grid
    class="donor_list_data_grid"
    [dataSource]="donorList"
    [showBorders]="false"
    [showColumnLines]="false"
    [showRowLines]="false"
    [allowColumnResizing]="true"
    columnResizingMode="widget"
    [focusedRowEnabled]="false"
    [rowAlternationEnabled]="false"
    [rowTemplate]="'rowDonationList'"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
  >
    <dxi-column
      caption="Name"
      dataField="name"
      alignment="center"
      [allowSorting]="false"
    ></dxi-column>
    <dxi-column caption="Team" alignment="center" [allowSorting]="false">
    </dxi-column>
    <dxi-column
      caption="Total Donated Amount"
      dataField="total_donated_amount"
      alignment="center"
      [allowSorting]="false"
    >
    </dxi-column>
    <dxi-column
      caption="Last Donated Amount"
      dataField="last_donated_amount"
      alignment="center"
      [allowSorting]="false"
    >
    </dxi-column>
    <dxi-column
      caption="Last Donation Date"
      dataField="updated_at"
      alignment="center"
      style="border: none"
      [allowSorting]="false"
    >
    </dxi-column>

    <tbody class="bg-white" *dxTemplate="let row of 'rowDonationList'">
      <tr style="display: contents !important">
        <td style="border-radius: 8px 0 0 8px !important">
          <div
            class="donor-profile cursor row_white"
            (click)="navigateToDonorProfile(row?.data?.user_id)"
          >
            <div class="donor-number">
              {{ row?.rowIndex + (currentPage - 1) * 7 + 1 }}
            </div>
            <div class="profile-avatar">
              <img
                class="profile_img"
                loading="lazy"
                [src]="
                  '../../../../../assets/images/icon/profile_icon_gray.png'
                "
                alt="profile_pic"
              />
            </div>
            <div class="profile-details">
              <div class="donor-name">
                {{ row?.data?.name || "Not Available" }}
              </div>
              <div class="donor-email">{{ row?.data?.email }}</div>
            </div>
          </div>
        </td>
        <td>
          <div class="logo-container row_white d-flex justify-content-center">
            <div *ngIf="row?.data?.teams?.length; else noDataTemplate">
              <div
                class="logo_div"
                [ngStyle]="{
                  left: getTeams(row?.data?.teams)?.length * 6 - 6 + 'px'
                }"
              >
                <div
                  *ngFor="let logo of getTeams(row?.data?.teams); let i = index"
                  class="logo"
                  [ngStyle]="{ left: i > 0 ? -i * 12 + 'px' : '0px' }"
                >
                  <img
                    class="logo_img"
                    *ngIf="i <= 2"
                    [src]="makeUrlParsed(logo.team_logo)"
                    [alt]="logo.alt"
                    [title]="i"
                  />
                  <div *ngIf="i === 3" class="logo_count">
                    <div>{{ row?.data?.teams?.length - 3 }}</div>
                    <div>+</div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #noDataTemplate>
              <div class="no-data">No Teams Available</div>
            </ng-template>
          </div>
        </td>
        <td>
          <div class="text-center row_white">
            <div class="donor_amount">
              ৳ {{ row?.data?.total_amount }}
            </div>
          </div>
        </td>
        <td>
          <div class="donor_amount row_white">
            ৳ {{ row?.data?.last_donation_amount }}
          </div>
        </td>
        <td style="border-radius: 0px 8px 8px 0px !important">
          <div class="date_time row_white">
            {{ row?.data?.updated_at | date : "MM-dd-yyyy | h:mm a" }}
          </div>
        </td>
      </tr>
    </tbody>
  </dx-data-grid>
</div>

<div class="w_100">
  <div class="paginator">
    <button
      class="prev-button"
      (click)="goToPrevPage()"
      [disabled]="currentPage === 1"
    >
      <img
        class="chevorn"
        loading="lazy"
        [src]="'../../../../../assets/images/icon/chevron-left.svg'"
        alt="prev"
      />
    </button>
    <ng-container *ngFor="let page of pages">
      <button
        *ngIf="page !== '...'"
        (click)="goToPage(page)"
        [class.active]="currentPage === page"
      >
        {{ page }}
      </button>
      <span class="fw-bold" *ngIf="page === '...'">....</span>
    </ng-container>
    <button
      class="next-button"
      (click)="goToNextPage()"
      [disabled]="currentPage === totalPages"
    >
      <img
        class="chevorn"
        loading="lazy"
        [src]="'../../../../../assets/images/icon/chevron_right.png'"
        alt="prev"
      />
    </button>
  </div>
</div>
