import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import menu from './menu.json';

@Component({
  selector: 'app-corporate-menu',
  templateUrl: './corporate-menu.component.html',
  styleUrl: './corporate-menu.component.scss',
})
export class CorporateMenuComponent implements OnInit {
  public menuItems;

  public activeIndex: number;
  public activeSubmenuIndex: number;
  public menuOpen: boolean = true;
  public open = new Map<number, boolean>();

  public sessionUser: any;

  constructor(
    private readonly router: Router,
    public readonly localStorageService: LocalStorageService
  ) {
    this.menuItems = menu;
    this.menuItems.forEach((item: any) => {
      if (item.submenu?.length > 0) {
        this.open.set(item.index, false);
      }
    });
  }

  public ngOnInit(value: any = null) {
    const aclMenu = value || this.getACLFromUrl();
    this.activeIndex = aclMenu?.menu_index;
    this.activeSubmenuIndex = this.getActiveSubmenuIndex();

    this.sessionUser = this.localStorageService.getSessionUser();

    // if (this.open.has(this.activeIndex)) {
    //   this.open.set(this.activeIndex, true);
    // }
  }

  public isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  public toggleMenu(index: number): void {
    if (this.open.has(index)) {
      this.toggleOpen(index);
    }
  }

  private toggleOpen(index: number): void {
    const value = this.open.get(index);
    this.open.set(index, !value);
  }

  private getACLFromUrl(): any {
    const value = this.router.url;

    if (value === '/admin/workplace') {
      return { menu_index: 1 };
    } else if (value.includes('admin/ngo-management')) {
      this.handleNavBarOpen(2, 'submenu2');
      return { menu_index: 2 };
    } else if (value.includes('admin/campaign-management')) {
      this.handleNavBarOpen(3, 'submenu3');
      return { menu_index: 3 };
    } else if (value.includes('admin/donation-management')) {
      this.handleNavBarOpen(4, 'submenu4');
      return { menu_index: 4 };
    } else if (value === '/admin/contact-requests') {
      return { menu_index: 5 };
    } else {
      return null;
    }
  }

  private handleNavBarOpen(index: number, className: string): void {
    // this.open[index] = true;
    // var myCollapse = document.getElementById(className);
    // myCollapse.classList.add('show');
  }

  public getActiveSubmenuIndex(): number {
    const url = this.router.url;
    if (
      url === '/admin/ngo-management/all-ngos' ||
      url.includes('/admin/ngo-management/ngo-details')
    ) {
      return 21;
    } else if (url === '/admin/ngo-management/ngo-approval-request') {
      return 22;
    } else if (
      url === '/admin/campaign-management/all-donation-campaigns' ||
      url.includes('/admin/campaign-management/summary')
    ) {
      return 31;
    } else if (url === '/admin/campaign-management/all-featured-campaign') {
      return 32;
    } else if (url === '/admin/campaign-management/all-requested-campaign') {
      return 33;
    } else if (
      url === '/admin/donation-management/all-donor' ||
      url.includes('/admin/donation-management/donor-profile')
    ) {
      return 41;
    } else if (
      url === '/admin/donation-management/all-fundraisers' ||
      url.includes('/admin/donation-management/fundraiser-profile')
    ) {
      return 42;
    } else if (url === '/admin/donation-management/all-donations') {
      return 43;
    } else {
      return null;
    }
  }
}
