import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { RichTextService } from 'src/app/shared/services/rich-text/rich-text.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-donor-experience',
  templateUrl: './donor-experience.component.html',
  styleUrl: './donor-experience.component.scss',
})
export class DonorExperienceComponent {
  @Input() showMoreButton: boolean;
  @Input() isCommentHasData: boolean;

  public comment_reply: any[] = [];

  public currentIndex: number = 4;
  public displayedComments: any[] = [];
  public loadMoreDisabled = false;

  public imgsrc = '../../../../assets/images/winter_help.png';

  public ngo_id: string;
  public project_id: string;
  public campaign_id: string;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly donationService: DonationService,
    public readonly utilitiesService: UtilitiesService,
    public readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const encryptedString = params['data'];
      const decryptedParams =
        this.localStorageService.decryptObjectUrl(encryptedString);
      if (decryptedParams) {
        this.campaign_id = decryptedParams.id;
        this.ngo_id = decryptedParams.ngo_id;
        this.project_id = decryptedParams.project_id;
        this.getComments(this.campaign_id);
      } else {
        this.router.navigateByUrl('/dashboard/manage/campaign');
        console.error('Decryption failed');
      }
    });
  }

  get commentsFiltering(): any[] {
    if (this.currentIndex >= this.comment_reply.length) {
      this.loadMoreDisabled = true;
    }
    return (this.displayedComments = this.comment_reply.slice(
      0,
      this.currentIndex
    ));
  }

  public loadMoreComments() {
    this.currentIndex += 4;
    this.displayedComments = this.comment_reply.slice(0, this.currentIndex);

    if (this.currentIndex >= this.comment_reply.length) {
      this.loadMoreDisabled = true;
    }
  }

  public getComments(campaign_id: string) {
    this.utilitiesService.showSpinner(true);
    this.donationService
      .getDonationCampaignDonorExperienceComment(campaign_id)
      .subscribe({
        next: (result) => {
          this.utilitiesService.showSpinner(false);
          if (result.length > 0) {
            this.isCommentHasData = true;
            this.comment_reply = result
              .map((comment: any) => ({
                ...comment,
                isAnonymous: false,
                replies: comment.replies.map((reply: any) => ({
                  ...reply,
                  isAnonymous: false,
                })),
              }))
              .sort(
                (a: any, b: any) =>
                  new Date(b.updated_at).getTime() -
                  new Date(a.updated_at).getTime()
              );
          }
        },
        error: (err) => {
          this.utilitiesService.showSpinner(false);
        },
      });
  }
}
