<div class="main_div">
  <div>
    <span class="fs_16 c_0054a6">Home / </span>
    <span class="fs_16">Employee Management</span>
  </div>

  <div class="title">Employee Management</div>

  <div class="d-flex gap_32">
    <div class="chart_card">
      <div class="d-flex gap-3 align-items-center" style="width: 340px">
        <img src="./assets/images/icon/add_round_icon.svg" />
        <div class="title">Create Employee Account</div>
      </div>
      <img
        class="cursor"
        width="24"
        height="24"
        src="./assets/images/icon/expand_icon_blue.svg"
        title="Create"
        [routerLink]="['create-account']"
      />
    </div>

    <div class="chart_card">
      <div class="d-flex gap-3 align-items-center" style="width: 310px">
        <img src="./assets/images/icon/invite_round_icon.svg" />
        <div class="title">Invite Employee</div>
      </div>
      <img
        class="cursor"
        width="24"
        height="24"
        src="./assets/images/icon/expand_icon_blue.svg"
        title="Invite"
        [routerLink]="['invite-employee']"
      />
    </div>
  </div>

  <div class="title">Employee List</div>

  <div>
    <dx-data-grid
      class="donor_list_data_grid"
      [dataSource]="allFundraisers"
      [showBorders]="false"
      [showColumnLines]="false"
      [showRowLines]="false"
      [allowColumnResizing]="true"
      columnResizingMode="widget"
      [focusedRowEnabled]="false"
      [rowAlternationEnabled]="false"
      rowTemplate="rowDonationList"
      [wordWrapEnabled]="true"
      [columnAutoWidth]="true"
    >
      <dxo-paging [pageSize]="10" [pageIndex]="0"> </dxo-paging>
      <dxo-pager
        *ngIf="allFundraisers?.length > 10"
        [visible]="true"
        [showPageSizeSelector]="false"
        [allowedPageSizes]="[10]"
        [showInfo]="false"
        [showNavigationButtons]="true"
      >
      </dxo-pager
      ><dxi-column
        caption="#SL"
        [width]="80"
        dataField="slNo"
        alignment="center"
      >
      </dxi-column>
      <dxi-column
        caption="Employee Name"
        dataField="donorName"
        alignment="left"
      ></dxi-column>
      <dxi-column
        caption="Department"
        dataField="transactionId"
        alignment="left"
      >
      </dxi-column>
      <dxi-column caption="Email Address" dataField="date" alignment="left">
      </dxi-column>
      <dxi-column
        caption="Contribution"
        dataField="amount"
        alignment="left"
        style="border: none"
        [width]="160"
      >
      </dxi-column>
      <dxi-column
        caption="Campaign Participated"
        dataField="campaign"
        style="border: none"
        [width]="215"
      >
      </dxi-column>
      <dxi-column
        caption="View Credential"
        dataField="status"
        alignment="center"
        style="border: none"
        [width]="170"
      >
      </dxi-column>

      <tbody class="bg-white" *dxTemplate="let row of 'rowDonationList'">
        <tr style="display: contents !important">
          <td>
            <div class="donor-name text-center">
              {{ row?.data?.slNo }}
            </div>
          </td>
          <td>
            <div class="donor-profile row_white">
              <img
                class="profile_img"
                loading="lazy"
                [src]="
                  row?.data?.profile_img || './assets/images/round_profile.png'
                "
                alt=""
              />
              <div class="donor-name c_0054a6">
                {{ row?.data?.donorName }}
              </div>
            </div>
          </td>
          <td>
            <div class="donor-name">{{ "Product Development" }}</div>
          </td>
          <td>
            <div class="donor-name">
              {{ "mahmudulhasan@gmail.com" }}
            </div>
          </td>
          <td>
            <div class="donor-name">
              ৳ {{ (row?.data?.amount | number) || 0 }}
            </div>
          </td>
          <td>
            <div class="donor-profile row_white">
              <img
                loading="lazy"
                width="24"
                height="24"
                [src]="'./assets/images/icon/mike_tilted_icon_active.svg'"
                alt=""
              />
              <div class="donor-name c_0054a6">
                {{ "(12)" }}
              </div>
            </div>
          </td>
          <td>
            <div class="donor-name text-center">
              <button
                class="blue-button-sm"
                (click)="credentials = row?.data; showCredentials = true"
              >
                View
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </dx-data-grid>
  </div>
</div>

<dx-popup
  class="donor_activity"
  [(visible)]="showCredentials"
  [width]="1000"
  [height]="600"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div class="h-100">
    <div class="w-100 title text-center mb-7">
      {{ "Mahmudul Hasan" }}'s Account Details
    </div>

    <div class="d-flex gap-3 my-5 px-5">
      <div class="d-flex flex-column align-items-center w-50 mt-5">
        <div>
          <div class="">
            <div class="title fw-normal">Employee Name</div>
            <div class="title mt-2">{{ "Mahmudul Hasan" }}</div>
          </div>
          <div class="mt-5">
            <div class="title fw-normal">Account Password</div>
            <div class="title mt-2">{{ "8Z-9J*[5*kGM" }}</div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center w-50 mt-5">
        <div>
          <div class="">
            <div class="title fw-normal">Email Address</div>
            <div class="title mt-2">{{ "mahmudulhasan@gmail.com" }}</div>
          </div>
          <div class="mt-5">
            <div class="title fw-normal">Account Created</div>
            <div class="title mt-2">{{ "June 15, 2022 | 09-12 PM" }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 d-flex w-100 justify-content-center gap-5">
      <button class="yellow-button-md mt-5" style="width: 280px">
        Close Account
      </button>
      <button class="mt-5 blue-button-md" style="width: 280px">
        Share Credential
      </button>
    </div>
  </div>
</dx-popup>
