import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { NgoService } from 'src/app/shared/services/ngo/ngo.service';

@Component({
  selector: 'app-ngo-list',
  templateUrl: './ngo-list.component.html',
  styleUrls: ['./ngo-list.component.css'],
})
export class NgoListComponent implements OnInit {
  public ngoSearchQuery: string = '';
  public currentPage = 1;
  public totalPages = 1;
  public data: any[] = [];

  constructor(
    private readonly ngoService: NgoService,
    private readonly router: Router,
    private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService
  ) {}

  public ngOnInit(): void {
    this.loadNGoPage(this.currentPage);
  }

  public onSearchClick(): void {
    if (!this.ngoSearchQuery) {
      return;
    }
    this.router.navigateByUrl('/search-ngo?query=' + this.ngoSearchQuery);
  }

  public goToNgoPage(ngo: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: ngo.id,
    });
    this.router.navigate(['/ngo'], {
      queryParams: { data: encryptedParams },
    });
  }

  private loadNGoPage(page: number) {
    this.spinner.show();

    this.ngoService.getNgoWithPagination(page).subscribe({
      next: (response) => {
        if (response) {
          this.totalPages = Math.ceil(response.total / 10);
          this.data = response.data;
        }
        this.spinner.hide();
      },
      error: (err) => {
        this.spinner.hide();
      },
    });
  }

  public goToPage(page: number | string) {
    if (typeof page === 'number' && page > 0 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadNGoPage(this.currentPage);
    }
  }

  public goToPrevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadNGoPage(this.currentPage);
    }
  }

  public goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loadNGoPage(this.currentPage);
    }
  }

  public get pages() {
    const pages = [];
    const maxVisiblePages = 3;

    if (this.totalPages <= 10) {
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (this.currentPage > maxVisiblePages + 1) {
        pages.push('...');
      }

      const startPage = Math.max(2, this.currentPage - 2);
      const endPage = Math.min(this.totalPages - 1, this.currentPage + 2);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (this.currentPage < this.totalPages - (maxVisiblePages + 1)) {
        pages.push('...');
      }
      pages.push(this.totalPages);
    }
    return pages;
  }
}
