<div class="root">
  <footer class="nested-list">
    <div class="list-element">
      <div class="list-element1">
        <div>
          <div class="list-element2">
            <img
              class="list-element-child"
              loading="lazy"
              alt=""
              src="../../../../../assets/group-39512.svg"
            />

            <div class="list-element3">
              <a class="shohay">Shohay</a>
            </div>
          </div>
          <div class="copyright">{{ "Footer.copyright" | translate }}</div>
        </div>
        <div>
          <p class="follow-us">Follow us</p>
          <div class="social-media">
            <img
              class="social-media-icon-squareinsta"
              loading="lazy"
              alt=""
              src="../../../../../assets/images/icon/fb.svg"
            />
            <img
              class="social-media-icon-squareinsta"
              loading="lazy"
              alt=""
              src="../../../../../assets/images/icon/ln.svg"
            />
            <img
              class="social-media-icon-squareinsta twitter"
              loading="lazy"
              alt=""
              src="../../../../../assets/images/icon/x.svg"
            />
            <img
              class="social-media-icon-squareinsta"
              loading="lazy"
              alt=""
              src="../../../../../assets/images/icon/yt.svg"
            />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-column">
          <a class="footer-title">{{ "Footer.footer-title" | translate }}</a>
          <div class="footer-links">
            <!-- <div class="link">
              <div class="master-link">
                <a class="features">{{ "Footer.features" | translate }}</a>
              </div>
            </div>
            <div class="link">
              <div class="master-link cursor" [routerLink]="'pricing'">
                <a class="features">{{ "Footer.Pricing" | translate }}</a>
              </div>
            </div> -->
            <div class="link cursor" [routerLink]="'all-products'">
              <div class="master-link">
                <a class="features cursor">Operation Management</a>
              </div>
            </div>
            <div class="link cursor" [routerLink]="'all-donation-campaigns'">
              <div class="master-link">
                <a class="features cursor">Donation Campaign</a>
              </div>
            </div>
            <!-- <div class="link">
              <div class="master-link">
                <a class="features">Corporate Matching</a>
              </div>
            </div> -->
          </div>
        </div>
        <div class="footer-column">
          <a class="footer-title">{{ "Footer.Company" | translate }}</a>
          <div class="footer-links">
            <div class="link">
              <div class="master-link cursor" [routerLink]="'about-us'">
                <a class="features">{{ "Footer.About" | translate }}</a>
              </div>
            </div>
            <div class="link">
              <div class="master-link cursor" [routerLink]="'contact-us'">
                <a class="features">{{ "Footer.Contact" | translate }}</a>
              </div>
            </div>
            <div class="link">
              <div class="master-link cursor" [routerLink]="'all-news-articles'">
                <a class="features">Articles and News</a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="footer-column">
          <a class="footer-title">{{ "Footer.Support" | translate }}</a>
          <div class="footer-links">
            <div class="link">
              <div class="master-link">
                <a class="features">{{ "Footer.Getting" | translate }}</a>
              </div>
            </div>
            <div class="link">
              <div class="master-link">
                <a class="features">{{ "Footer.Help" | translate }}</a>
              </div>
            </div>
            <div class="link">
              <div class="master-link">
                <a class="features">{{ "Footer.Server" | translate }}</a>
              </div>
            </div>
            <div class="link">
              <div class="master-link">
                <a class="features"> {{ "Footer.Report" | translate }}</a>
              </div>
            </div>
            <div class="link">
              <div class="master-link">
                <div class="features">
                  {{ "Footer.Chat" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="last-column">
          <a class="footer-title"> {{ "Footer.Contact" | translate }}</a>
          <div class="footer-links address_company">
            <div>
              <div>Bangladesh</div>
              <div class="link">
                <div class="master-link">
                  <img
                    class="line-roundedemail-icon"
                    loading="lazy"
                    alt=""
                    src="../../../../../assets/line-roundedemail.svg"
                  />

                  <div class="link-item master-2" style="margin-bottom: 8px">
                    {{ "Footer.info" | translate }}
                  </div>
                </div>
              </div>
              <div class="link">
                <div class="master-link">
                  <img
                    class="line-roundedemail-icon"
                    loading="lazy"
                    alt=""
                    src="../../../../../assets/line-roundedphone.svg"
                  />

                  <div class="link-item master-2" style="margin-bottom: 8px">
                    {{ "Footer.number" | translate }}
                  </div>
                </div>
              </div>
              <div class="master-link">
                <img
                  class="line-roundedemail-icon"
                  loading="lazy"
                  alt=""
                  src="../../../../../assets/spacer-icon-wrapper.svg"
                />
                <span class="master-">
                  {{ "Footer.address" | translate }}
                </span>
              </div>
            </div>
            <div>
              <div>USA</div>
              <div class="link">
                <div class="master-link">
                  <img
                    class="line-roundedemail-icon"
                    loading="lazy"
                    alt=""
                    src="../../../../../assets/line-roundedemail.svg"
                  />

                  <div class="link-item master-2" style="margin-bottom: 8px">
                    {{ "Footer.info" | translate }}
                  </div>
                </div>
              </div>
              <div class="link">
                <div class="master-link">
                  <img
                    class="line-roundedemail-icon"
                    loading="lazy"
                    alt=""
                    src="../../../../../assets/line-roundedphone.svg"
                  />

                  <div class="link-item master-2" style="margin-bottom: 8px">
                    {{ "Footer.usa-number" | translate }}
                  </div>
                </div>
              </div>
              <div class="master-link">
                <img
                  class="line-roundedemail-icon"
                  loading="lazy"
                  alt=""
                  src="../../../../../assets/spacer-icon-wrapper.svg"
                />
                <div class="master-" style="margin-bottom: 8px">
                  <div>{{ "Footer.usa-address" | translate }}</div>
                  <div>
                    <!-- {{ "Footer.country" | translate }} -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line-parent">
      <div class="footer-content">
        <div class="footer-left cursor">
          {{ "Footer.rights" | translate }}
        </div>
        <div class="footer-right cursor">
          <span class="terms-and-conditions"  [routerLink]="'terms-and-conditions'">{{ 
            "Footer.Terms" | translate
          }}</span>
          |
          <span class="privacy-policy"  [routerLink]="'privacy-policy'">{{ "Footer.Privacy" | translate }}</span>
        </div>
      </div>
    </div>
  </footer>
</div>
