import { Component, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginBodyModel } from 'src/app/shared/model/auth/login.model';
import { OtpResendModel } from 'src/app/shared/model/auth/otp-resend.model';
import { OtpValidationModel } from 'src/app/shared/model/auth/otp-validation.model';
import { SignupBodyModel } from 'src/app/shared/model/auth/signup.model';
import {
  FundraiserModel,
  FundraiserTeamModel,
} from 'src/app/shared/model/fundraiser.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { FileService } from 'src/app/shared/services/file/file.service';
import { FundraiserService } from 'src/app/shared/services/fundraiser/fundraiser.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { CommonSharedServiceService } from 'src/app/shared/shared-services/common-shared-service/common-shared-service.service';

@Component({
  selector: 'app-join-as-fundraiser',
  templateUrl: './join-as-fundraiser.component.html',
  styleUrl: './join-as-fundraiser.component.scss',
})
export class JoinAsFundraiserComponent {
  public sessionUser: any;
  @Input() ngo_id: string;
  @Input() project_id: string;
  @Input() campaign_id: string;

  public showSignUpPopup: boolean = false;
  public showOtpPopUp: boolean = false;
  public showTimer: boolean = true;
  public showLoginPopup: boolean = false;
  public showFundraiserPopUp: boolean = false;
  public showLastPopUp: boolean = false;

  public signUpModel: SignupBodyModel = new SignupBodyModel();
  public confirmPassword: string;

  public isPasswordVisible: boolean = false;
  public isConfirmPasswordVisible: boolean = false;

  public disableResendOtp: boolean = true;
  public timer: any = null;
  public otpModel: OtpValidationModel = new OtpValidationModel();
  private resendOtpModel: OtpResendModel = new OtpResendModel();

  public loginModel: LoginBodyModel = new LoginBodyModel();

  public fundraiserFormTab: number = 1;
  public fundraiserTeams: any[] = [];
  public selectedFundraiserTeam: any;

  public fundraiserModel: FundraiserModel = new FundraiserModel();
  public fundraiserTeamModel: FundraiserTeamModel = new FundraiserTeamModel();
  public teamLogoUrl: string = null;
  public teamLogoFile: File = null;

  constructor(
    private readonly authService: AuthService,
    private readonly fileService: FileService,
    private readonly spinner: NgxSpinnerService,
    public readonly utilitiesService: UtilitiesService,
    private readonly fundraiserService: FundraiserService,
    private readonly localStorageService: LocalStorageService,
    private readonly sharedServices: CommonSharedServiceService,
  ) {}

  public showHideSignUpPopup(e: boolean): void {
    if (this.authService.isLoggedIn()) {
      this.sessionUser = this.localStorageService.getSessionUser();
      this.getAllTeams();
      this.showLastPopUp = true;
    } else {
      this.showSignUpPopup = e;
    }
  }

  public signUp(): void {
    const { isValid } = ValidationEngine.validateGroup('signUpValidationGrp');
    if (isValid) {
      this.authService.signup(this.signUpModel).subscribe({
        next: (result: any) => {
          this.localStorageService.setResendOtpDisabledTimestamp();
          this.resendOtpModel.email = this.signUpModel.email;
          this.showSignUpPopup = false;
          this.countDownTimer();
          this.showOtpPopUp = true;
        },
        error: (error: any) => {
          this.showToast('Error', error.error.message, 'error');
        },
      });
    }
  }

  private countDownTimer(time: any = null): void {
    this.disableResendOtp = true;
    let remainingTime = time || 299;

    const interval = setInterval(() => {
      const minutes = Math.floor(remainingTime / 60);
      const seconds = remainingTime % 60;

      this.timer = minutes > 0 ? `${minutes}min ${seconds}s` : `${seconds}s`;

      if (remainingTime <= 0) {
        clearInterval(interval);
        this.showTimer = false;
        this.disableResendOtp = false;
      } else {
        remainingTime--;
      }
    }, 1000);
  }

  public verifyOtp(): void {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      this.spinner.show();
      this.otpModel.email = this.resendOtpModel.email;
      this.authService.validateOtp(this.otpModel).subscribe({
        next: (result) => {
          this.spinner.hide();
          this.localStorageService.clearResendOtpDisabledTimestamp();
          this.showToast('Success', 'OTP verified successfully', 'success');
          this.showOtpPopUp = false;
          this.showLoginPopup = true;
        },
        error: (err) => {
          this.spinner.hide();
          this.showToast('Error', 'Invalid OTP', 'error');
        },
      });
    }
  }

  public resendOtp(): void {
    this.spinner.show();
    this.authService.resendOtp(this.resendOtpModel).subscribe({
      next: (result) => {
        this.spinner.hide();
        this.localStorageService.setResendOtpDisabledTimestamp();
        this.countDownTimer();
      },
      error: (err) => {
        this.spinner.hide();
        this.showToast(
          'Error',
          'OTP could not be send. Try again later',
          'error'
        );
      },
    });
  }

  public login(): void {
    const { isValid } = ValidationEngine.validateGroup('loginValidationGrp');
    if (isValid) {
      this.spinner.show();
      this.resendOtpModel.email = this.loginModel.email;

      this.authService.login(this.loginModel).subscribe({
        next: (result) => {
          this.spinner.hide();
          this.handleLoggedInUser(result.result);
        },
        error: (err) => {
          this.spinner.hide();
          if (err?.error?.message === 'Requested user is not verified.') {
            this.handleUserNotVerified();
          } else {
            this.showToast('Error', err?.error?.message, 'error');
          }
        },
      });
    }
  }

  private handleLoggedInUser(res: any): void {
    this.localStorageService.setToken(res.access_token);
    this.localStorageService.setSessionUser(res.user);
    this.sessionUser = this.localStorageService.getSessionUser();

    this.sharedServices.updateLoginState();

    if (this.localStorageService.getResendOtpDisabledTimestamp()) {
      this.localStorageService.clearResendOtpDisabledTimestamp();
    }

    this.showLoginPopup = false;
    this.showFundraiserPopUp = true;
    this.getAllTeams();
  }

  private handleUserNotVerified(): void {
    this.showToast('Error', 'Verify your email first', 'error');

    const otpSentTime =
      this.localStorageService.getResendOtpDisabledTimestamp();
    const now = new Date().getTime();

    if (otpSentTime && now - otpSentTime >= 300000) {
      this.resendOtp();
    } else {
      this.countDownTimer(300 - Math.floor((now - otpSentTime) / 1000));
    }
    this.showLoginPopup = false;
    this.showOtpPopUp = true;
  }

  public toggleFundraiserFormTab(tab: number): void {
    this.fundraiserFormTab = tab;
  }

  private getAllTeams(): void {
    this.fundraiserService.getAllTeams(this.campaign_id).subscribe({
      next: (result: any) => {
        this.fundraiserTeams = result;
      },
    });
  }

  public joinOrCreateTeam(action: string): void {
    const { isValid } = ValidationEngine.validateGroup(
      'fundraiserValidationGrp'
    );
    if (isValid) {
      this.fundraiserTeamModel.ngo_id = this.ngo_id;
      this.fundraiserTeamModel.project_id = this.project_id;
      this.fundraiserTeamModel.campaign_id = this.campaign_id;
      this.fundraiserTeamModel.fundraiser_name = this.sessionUser.name;

      if (action === 'join') {
        this.joinTeam();
      } else {
        if (this.teamLogoFile) {
          this.createTeam();
        } else {
          this.utilitiesService.showSwalAndReturn({
            type: 'error',
            title: `You must add team logo!`,
            confirmButtonText: 'OK',
            showCancelButton: false,
          });
        }
      }
    }
  }

  private joinTeam(): void {
    this.fundraiserModel = new FundraiserModel(this.fundraiserTeamModel);
    this.fundraiserModel.team_id = this.selectedFundraiserTeam.id;
    this.fundraiserModel.team_name = this.selectedFundraiserTeam.team_name;

    this.fundraiserService.joinAsFundraiser(this.fundraiserModel).subscribe({
      next: (result: any) => {
        this.showFundraiserPopUp = false;
        this.fundraiserModel = new FundraiserModel();
        this.showToast(
          'Success',
          'Successfully joined as a fundraiser',
          'success'
        );
      },
    });
  }

  private createTeam(): void {
    this.fileService.uploadSingleFile(this.teamLogoFile).subscribe({
      next: (result: any) => {
        this.sessionUser = this.localStorageService.getSessionUser();
        this.fundraiserTeamModel.team_logo = JSON.stringify(result[0]);

        this.fundraiserService.createTeam(this.fundraiserTeamModel).subscribe({
          next: (result: any) => {
            this.showFundraiserPopUp = false;
            this.fundraiserTeamModel = new FundraiserTeamModel();
            this.showToast(
              'Success',
              'Successfully created fundraiser team',
              'success'
            );
          },
        });
      },
      error: (err: any) => {
        this.spinner.hide();
        this.showToast('Error', 'Error uploading image file', 'error');
      },
    });
  }

  public onFileChange(e: any, fileName: string): void {
    const input = e.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this[fileName + 'File'] = input.files[0];
      this[fileName + 'Url'] = URL.createObjectURL(input.files[0]);
    }
  }

  public triggerFileInput(inputId: string): void {
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  public toggleSignupLogin(target: string): void {
    if (target === 'signup') {
      this.signUpModel = new SignupBodyModel();
      this.showLoginPopup = false;
      this.showSignUpPopup = true;
    } else {
      this.loginModel = new LoginBodyModel();
      this.showSignUpPopup = false;
      this.showLoginPopup = true;
    }
  }

  public toggleFundraiserPopup(e: boolean): void {
    this.showLastPopUp = false;
    this.showFundraiserPopUp = e;
  }

  public passwordComparison = () => this.signUpModel.password;

  public togglePasswordVisibility = () => {
    this.isPasswordVisible = !this.isPasswordVisible;
  };

  public toggleConfirmPasswordVisibility = () => {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  };

  private showToast(
    title: string,
    message: string,
    type: 'error' | 'success'
  ): void {
    this.utilitiesService.showSwalWithToast(title, message, type);
  }
}
