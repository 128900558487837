<div class="container-fluid" style="height: 100vh">
  <div class="row flex-nowrap h-100">
    <div
      class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 w-auto"
      style="padding-left: 0px !important"
    >
      <div class="align-items-sm-start menu_section">
        <a class="mb-md-0 me-md-auto logo_section">
          <img
            *ngIf="menuOpen"
            class="pointer"
            routerLink="/"
            src="./assets/images/logo/shohay_logo_full.svg"
          />
          <div class="super_admin">
            <div class="super_admin_content">Corporate</div>
          </div>
        </a>

        <!-- Company -->
        <div class="dropdown-container">
          <div class="dropdown">
            <div
              class="dropdown-button"
              style="padding: 8px"
            >
              <div class="left_side" style="width: 174px">
                <img
                  [src]="
                    sessionUser?.company_name ||
                    './assets/images/icon/organization_icon.svg'
                  "
                  alt="icon"
                  class="company_logo"
                />
                <div class="organization" style="width: 119px">
                  <div class="fs_16">
                    {{ sessionUser?.company_name || "Not Available" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Menu Start -->
        <ul
          class="nav nav-pills nav_bar mb-sm-auto align-items-sm-start"
          id="menu"
          [ngStyle]="{ width: menuOpen ? '225px !important' : '55px' }"
        >
          <li class="menu_item_a" *ngFor="let item of menuItems">
            @if(item?.submenu?.length === 0) {
            <a
              [routerLink]="item.routerLink"
              [ngClass]="
                isActive(item.index)
                  ? 'nav-link high_height active'
                  : open.get(item.index)
                  ? 'nav-link high_height open'
                  : 'nav-link high_height'
              "
              (click)="activeIndex = item.index"
            >
              <div class="border-none">
                <img
                  class="icon_padding"
                  [src]="
                    isActive(item.index) ? item.activeIcon : item.inactiveIcon
                  "
                />
                <div *ngIf="menuOpen" class="ms-1 d-none d-sm-inline">
                  {{ item.name }}
                </div>
              </div>
            </a>
            } @else if (item?.submenu?.length > 0) {
            <a
              [href]="item.href"
              data-bs-toggle="collapse"
              [ngClass]="
                isActive(item.index)
                  ? 'nav-link high_height active'
                  : open.get(item.index)
                  ? 'nav-link high_height open'
                  : 'nav-link high_height'
              "
              (click)="toggleMenu(item.index)"
            >
              <div class="menu_item">
                <img
                  class="icon_padding"
                  [src]="
                    isActive(item.index) ? item.activeIcon : item.inactiveIcon
                  "
                />
                <div *ngIf="menuOpen" class="ms-1 d-none d-sm-inline">
                  {{ item.name }}
                </div>
              </div>
              <img
                *ngIf="menuOpen"
                [src]="
                  open.get(item.index)
                    ? isActive(item.index)
                      ? './assets/images/icon/up_arrow_icon_active.svg'
                      : './assets/images/icon/up_arrow_icon_inactive.svg'
                    : isActive(item.index)
                    ? './assets/images/icon/down_arrow_icon_active.svg'
                    : './assets/images/icon/down_arrow_icon_inactive.svg'
                "
              />
            </a>
            <ul
              class="collapse multi-collapse nav flex-column ms-1 pl_40"
              [attr.id]="item.id"
            >
              <li
                *ngFor="let subItem of item.submenu"
                class="high_height"
                (click)="
                  activeSubmenuIndex = subItem.index; activeIndex = item.index
                "
              >
                <a
                  class="nav-link"
                  [ngClass]="
                    getActiveSubmenuIndex() === subItem.index
                      ? 'nav-link high_height2 active2'
                      : 'nav-link high_height2'
                  "
                  [routerLink]="subItem.routerLink"
                >
                  <img
                    class="icon_padding"
                    [src]="
                      getActiveSubmenuIndex() === subItem.index
                        ? subItem.activeIcon
                        : subItem.inactiveIcon
                    "
                  />
                  <span class="d-sm-inline d-none">{{ subItem.name }}</span>
                </a>
              </li>
            </ul>
            }
          </li>
        </ul>
      </div>
    </div>
    <div class="col dashboard_body">
      <app-logged-in-header></app-logged-in-header>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
