import { Injectable } from '@angular/core';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { UrlEnum } from '../../enum/url.enum';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DonorService {
  private donorApi = `${UrlEnum.SHOHAY_NGO}/ngo-donation/donor`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public getDonorDashboardDetails(
    startDate: Date,
    endDate: Date
  ): Observable<any> {
    return this.httpRequestService
      .get(
        `${this.donorApi}/get-dashboard-summary?start_date=${startDate}&end_date=${endDate}`
      )
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response))
      );
  }
}