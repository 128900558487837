import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-logged-in-header',
  templateUrl: './logged-in-header.component.html',
  styleUrl: './logged-in-header.component.scss'
})
export class LoggedInHeaderComponent implements OnInit {
  private userId: string = null;
  public userName: string = '';
  public userEmail: string = '';
  public userNameFirstLetter: string = '';


  public currentUrl: string = '';
  public isBookmarkPage: boolean = false;

  public notifications: any[] = [];
  public unreadNotifications: number;
  public showNotification: boolean = false;

  constructor(
    public readonly router: Router,
    public readonly authService: AuthService,
    public readonly utilitiesService: UtilitiesService,
    public readonly localStorageService: LocalStorageService,
    //public readonly DashboardMenuComponent: DashboardMenuComponent
  ) {}

  ngOnInit(): void {
    const sessionUser = this.localStorageService.getSessionUser();
    this.userId = sessionUser?.id;
    this.userName = sessionUser?.name;
    this.userEmail = sessionUser?.email;
    this.userNameFirstLetter = this.userName.charAt(0);

    this.getBookmarks();
    this.getNoOfUnreadNotifications();

    this.getCurrentUrl();
    this.isBookmarkPage = this.router.url === '/dashboard/bookmarked-ngos';
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isBookmarkPage = event.url === '/dashboard/bookmarked-ngos';
      }
      this.getCurrentUrl();
    });
  }

  private getBookmarks(): void {
    for (let i = 0; i < 2; i++) {
      this.notifications.push([]);
      for (let j = 0; j < 5; j++) {
        this.notifications[i].push({ read: Math.random() >= 0.5 });
      }
    }
  }

  private getNoOfUnreadNotifications(): void {
    this.unreadNotifications = this.notifications
      .flat()
      .filter((item) => !item.read).length;
  }

  private getCurrentUrl(): void {
    const value = this.router.url;
    if (value.includes('bookmarked-ngos')) {
      this.currentUrl = 'Bookmarked NGOs';
    } else if (value.includes('manage/my-organization')) {
      this.currentUrl = 'Manage My NGOs';
    } else if (value.includes('register-new-ngo')) {
      this.currentUrl = 'Register New NGO';
    } else if (value.includes('claim-your-ngo')) {
      this.currentUrl = 'Claim Your NGO';
    } else if (value.includes('update-ngo-profile')) {
      this.currentUrl = 'Manage My NGO';
    } else if (value.includes('create-survey-forms')) {
      this.currentUrl = 'Project: Wash';
    } else if (value.includes('/form/all-questions')) {
      this.currentUrl = 'Project: Wash';
    } else if (value === '/dashboard') {
      this.currentUrl = 'Home';
    }
  }

  public async bookmarkedClicked(): Promise<void> {
    this.router.navigateByUrl('dashboard/bookmarked-ngos');
    //this.DashboardMenuComponent.ngOnInit({ menu_name: '', menu_index: null });
  }

  public openNotification(): void {
    this.showNotification = !this.showNotification;
  }

  get isAllNotRead(): boolean {
    return this.notifications
      .flat()
      .some((notification) => notification.read === false);
  }

  public markAllAsRead(): void {
    if (this.isAllNotRead) {
      this.notifications.forEach((days) => {
        days.forEach((notification) => {
          notification.read = true;
        });
      });
    }
    this.getNoOfUnreadNotifications();
  }

  public notificationRead(i: number, j: number): void {
    this.notifications[i][j] = true;
    this.getNoOfUnreadNotifications();
  }

  public logOut(): void {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }
}
