<div class="main_div">
  <div>
    <span class="fs_16 c_0054a6">Home / Employee Management / </span>
    <span class="fs_16">Invite Employee</span>
  </div>

  <div>
    <div class="title">Invite Employee</div>
    <div class="fs_18 c_686868 mt-1" style="width: 600px">
      Send email invitations to join campaigns.
    </div>
  </div>

  <div class="w-100 d-flex justify-content-center">
    <div class="form_body">
      <label class="label requiredMark"> Enter Email* </label>
      <dx-tag-box
        class="custom-fill"
        [dataSource]="emailsList"
        placeholder="e.g. someone@gmail.com"
        [acceptCustomValue]="true"
        (onCustomItemCreating)="addEmail($event)"
        [showClearButton]="true"
        [searchEnabled]="true"
        [searchTimeout]="100"
        [showDropDownButton]="false"
        [multiline]="true"
        selectAllMode="allPages"
        [showMultiTagOnly]="false"
        (value)="([])"
      >
        <dx-validator validationGroup="validationGrp">
          <dxi-validation-rule
            type="required"
            message="Email is required"
          ></dxi-validation-rule>
          <dxi-validation-rule
            type="email"
            message="Invalid email address"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-tag-box>

      <button class="blue-button-md" style="width: 100%; margin-top: 24px">
        Send
      </button>
    </div>
  </div>

  <div class="title">Invitation History</div>

  <div>
    <dx-data-grid
      class="donor_list_data_grid"
      [dataSource]="allFundraisers"
      [showBorders]="false"
      [showColumnLines]="false"
      [showRowLines]="false"
      [allowColumnResizing]="true"
      columnResizingMode="widget"
      [focusedRowEnabled]="false"
      [rowAlternationEnabled]="false"
      rowTemplate="rowDonationList"
      [wordWrapEnabled]="true"
      [columnAutoWidth]="true"
    >
      <dxo-paging [pageSize]="10" [pageIndex]="0"> </dxo-paging>
      <dxo-pager
        *ngIf="allFundraisers?.length > 10"
        [visible]="true"
        [showPageSizeSelector]="false"
        [allowedPageSizes]="[10]"
        [showInfo]="false"
        [showNavigationButtons]="true"
      >
      </dxo-pager>
      <dxi-column caption="Email Address" dataField="date" alignment="left">
      </dxi-column>
      <dxi-column
        caption="Status"
        dataField="donorName"
        alignment="center"
      ></dxi-column>
      <dxi-column caption="Action" dataField="transactionId" alignment="center">
      </dxi-column>
      <dxi-column caption="Date & Time" dataField="date" alignment="center">
      </dxi-column>

      <tbody class="bg-white" *dxTemplate="let row of 'rowDonationList'">
        <tr style="display: contents !important">
          <td>
            <div class="donor-name text-start" style="padding-left: 29px">
              {{ "mahmudulhasan@gmail.com" }}
            </div>
          </td>
          <td>
            <div class="donor-name c_039855">Sent</div>
          </td>
          <td>
            <div class="donor-name c_0054a6 text-decoration-underline cursor">
              Resent
            </div>
          </td>
          <td>
            <div class="profile-details">
              <div class="donor-name">
                {{ row?.data?.updated_at | date : "MMM dd, yyyy" }}
              </div>
              <div class="donor-email">
                {{ row?.data?.updated_at | date : "hh:mm a" }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </dx-data-grid>
  </div>
</div>
