<div class="container-fluid">
  <div class="row flex-nowrap">
    <div
      class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 w-auto"
      style="padding-left: 0px !important"
    >
      <div class="menu_section">
        <a class="mb-md-0 me-md-auto logo_section">
          <img
            *ngIf="menuOpen"
            class="pointer"
            routerLink="/"
            src="./assets/images/logo/shohay_logo_full.svg"
          />
        </a>

        <!-- No Ngo is available -->
        <div class="dropdown-container" *ngIf="userNgoList.length === 0">
          <div class="dropdown">
            <div
              class="dropdown-button"
              style="padding: 8px"
              [title]="'Add Organization'"
              routerLink="/dashboard/manage/my-organization"
            >
              <div class="left_side" style="width: 174px">
                <img
                  src="../../../../../assets/images/icon/organization_icon.svg"
                  alt="icon"
                  class=""
                />
                <div class="organization" style="width: 119px">
                  <div
                    style="font-weight: 400; line-height: 21px; color: #939393"
                  >
                    No Organization
                  </div>
                  <div class="ngo_name">Add Organization</div>
                </div>
              </div>
              <div class="right_side">
                <span class="dropdown-icon"
                  ><img src="./assets/images/icon/add_circle_black_icon.svg"
                /></span>
              </div>
            </div>
          </div>
        </div>

        <!-- Ngo Select Dropdown -->
        <div class="dropdown-container" *ngIf="userNgoList.length > 0">
          <!-- Ngo Select Dropdown Button -->
          <div class="dropdown">
            <div
              class="dropdown-button"
              (click)="isOpen = !isOpen"
              [title]="selectedNgo?.name"
            >
              <div class="left_side">
                <div class="img_div">
                  <img
                    *ngIf="selectedNgo?.name"
                    src="../../../../../assets/images/icon/brac_icon_small.svg"
                    alt="icon"
                    class="icon"
                  />
                </div>
                <div class="organization">
                  <div
                    *ngIf="selectedNgo?.name"
                    style="font-weight: 400; line-height: 21px; color: #939393"
                  >
                    {{
                      selectedNgo?.creation_type === "CLAIMED"
                        ? "NGO"
                        : "Non-Profit"
                    }}
                  </div>
                  <div class="ngo_name">
                    {{
                      selectedNgo?.name ? selectedNgo?.name : "Select an NGO"
                    }}
                  </div>
                </div>
              </div>
              <div class="right_side">
                <span class="dropdown-icon"
                  ><img
                    [ngStyle]="{
                      transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                    }"
                    src="./assets/images/icon/down_arrow_icon_black.svg"
                /></span>
              </div>
            </div>
            <!-- Ngo Select Dropdown Button End-->

            <!-- Ngo Select Dropdown List -->
            <div
              *ngIf="isOpen"
              [ngClass]="
                isOpen
                  ? 'dropdown-content drop-down_opened'
                  : 'dropdown-content'
              "
            >
              <div
                class="dropdown-item"
                *ngFor="let ngo of userNgoList"
                (click)="selectOrganization(ngo)"
              >
                <div
                  *ngIf="!ngo?.is_verified"
                  style="font-size: 14px; gap: 8px; display: flex"
                >
                  <span>Status: </span>
                  <span
                    style="
                      color: #dc6803;
                      background-color: #fbf0e6;
                      padding-inline: 8px;
                      border-radius: 12px;
                    "
                  >
                    In Review
                  </span>
                </div>
                <div
                  class="left_side"
                  style="width: -webkit-fill-available"
                  [title]="ngo?.name"
                >
                  <div class="img_div">
                    <img
                      src="../../../../../assets/images/icon/brac_icon_small.svg"
                      alt="icon"
                      class="icon"
                    />
                  </div>
                  <div class="organization" style="width: 164px">
                    <div
                      style="
                        font-weight: 400;
                        line-height: 21px;
                        color: #939393;
                      "
                    >
                      {{
                        ngo?.creation_type === "CLAIMED" ? "NGO" : "Non-Profit"
                      }}
                    </div>
                    <div class="ngo_name">
                      {{ ngo?.name }}
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <button
                    class="white-button-sm"
                    (click)="routeToNgoProfilePage(ngo?.id)"
                  >
                    View Profile
                  </button>
                </div>
              </div>
              <div
                style="font-size: 16px; margin-top: 16px"
                [routerLink]="'./manage/my-organization'"
              >
                <img src="./assets/images/icon/add_circle_white_icon.svg" />
                Add Organization
              </div>
            </div>
          </div>
          <!-- Ngo Select Dropdown List End-->
        </div>
        <!-- Ngo Select Dropdown End-->

        <!-- Menu Start -->
        <ul
          class="nav nav-pills nav_bar mb-sm-auto align-items-sm-start"
          id="menu"
          [ngStyle]="{ width: menuOpen ? '225px !important' : '55px' }"
        >
          <li class="menu_item_a" *ngFor="let item of menuItems">
            @if(item?.submenu?.length === 0) {
            <a
              [routerLink]="item.routerLink"
              [ngClass]="
                isActive(item.index)
                  ? 'nav-link high_height active'
                  : open.get(item.index)
                  ? 'nav-link high_height open'
                  : 'nav-link high_height'
              "
              (click)="
                activeIndex = item.index;
                item.name === 'Manage' && userNgoList?.length === 0
                  ? toggleMenu(item)
                  : null
              "
            >
              <div class="border-none">
                <img
                  class="icon_padding"
                  [src]="
                    isActive(item.index) ? item.activeIcon : item.inactiveIcon
                  "
                />
                <div *ngIf="menuOpen" class="ms-1 d-none d-sm-inline">
                  {{ item.name }}
                </div>
              </div>
            </a>
            } @else if (item?.submenu?.length > 0) {
            <a
              [href]="item.href"
              data-bs-toggle="collapse"
              [ngClass]="
                isActive(item.index)
                  ? 'nav-link high_height active'
                  : open.get(item.index)
                  ? 'nav-link high_height open'
                  : 'nav-link high_height'
              "
              (click)="toggleMenu(item)"
            >
              <div class="menu_item">
                <img
                  class="icon_padding"
                  [src]="
                    isActive(item.index) ? item.activeIcon : item.inactiveIcon
                  "
                />
                <div *ngIf="menuOpen" class="ms-1 d-none d-sm-inline">
                  {{ item.name }}
                </div>
              </div>
              <img
                *ngIf="menuOpen"
                [src]="
                  open.get(item.index)
                    ? isActive(item.index)
                      ? './assets/images/icon/up_arrow_icon_active.svg'
                      : './assets/images/icon/up_arrow_icon_inactive.svg'
                    : isActive(item.index)
                    ? './assets/images/icon/down_arrow_icon_active.svg'
                    : './assets/images/icon/down_arrow_icon_inactive.svg'
                "
              />
            </a>
            <ul
              class="collapse multi-collapse nav flex-column ms-1 pl_40"
              [attr.id]="item.id"
            >
              <li
                *ngFor="let subItem of item.submenu"
                class="high_height"
                (click)="setActiveSubmenuIndex(item.index, subItem.index)"
              >
                <a
                  class="nav-link"
                  [ngClass]="
                    getActiveSubmenuIndex() === subItem.index
                      ? 'nav-link high_height2 active2'
                      : 'nav-link high_height2'
                  "
                  [routerLink]="subItem.routerLink"
                >
                  <img
                    class="icon_padding"
                    [src]="
                      getActiveSubmenuIndex() === subItem.index
                        ? subItem.activeIcon
                        : subItem.inactiveIcon
                    "
                  />
                  <span class="d-sm-inline d-none">{{ subItem.name }}</span>
                </a>
              </li>
            </ul>
            }
          </li>
        </ul>
      </div>
    </div>
    <div class="col py-3 dashboard_body">
      <app-logged-in-header></app-logged-in-header>
      <router-outlet></router-outlet>
    </div>
  </div>

  <dx-popup
    class="popup_div"
    [(visible)]="popupVisible"
    [maxWidth]="724"
    [maxHeight]="400"
    [showTitle]="false"
    [showCloseButton]="false"
    [dragEnabled]="true"
    [resizeEnabled]="true"
    [dragEnabled]="true"
    position="center"
  >
    <div class="submit_create_popup d-flex justify-content-center">
      <div class="select_div">
        <div class="select_header">
          <span *ngIf="projectList.length > 0" class="select_text">
            Select a project.
          </span>
          <span
            *ngIf="projectList.length === 0"
            class="select_text"
            style="margin-top: 50px"
          >
            No projects in workspace yet.
          </span>
          <span *ngIf="projectList.length > 0" class="continue_text">
            Please select a project to continue.
          </span>
          <span *ngIf="projectList.length === 0" class="continue_text">
            Create a new projects to get started.
          </span>
        </div>
        <div *ngIf="projectList.length > 0">
          <dx-select-box
            class="select_project_box"
            width="100%"
            [dataSource]="projectList"
            [displayExpr]="'name'"
            [searchEnabled]="true"
            [searchExpr]="'name'"
            [valueExpr]="'id'"
            placeholder="Select Project"
            [showClearButton]="true"
            [(value)]="selectedProjectId"
          >
            <dx-validator validationGroup="selectValidationGrp">
              <dxi-validation-rule
                type="required"
                message="select ngo required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
        <div
          *ngIf="projectList.length > 0"
          class="button_container d-flex justify-content-between"
        >
          <button class="yellow-button-lg" (click)="closeModal()">
            Cancel
          </button>
          <button class="blue-button-lg" (click)="form()">Continue</button>
        </div>
        <div
          *ngIf="projectList.length === 0"
          class="button_container d-flex justify-content-between"
        >
          <button class="yellow-button-lg" (click)="closeModal()">
            Cancel
          </button>
          <button
            class="blue-button-lg"
            routerLink="/dashboard/manage/create-new-project"
            (click)="closeModal()"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </dx-popup>
</div>
