<div class="main_div">
  <div class="breadcrumb">
    <span class="donation_title"> Donation Management / </span>
    <span> All Donor </span>
  </div>

  <div class="header_text">All Donor</div>
  <div
    class="full_panel row"
    [ngStyle]="{ 'justify-content': activeTab === 1 ? 'space-between' : 'end' }"
  >
    <div class="col-sm-12 col-lg-6 panel_right_side" *ngIf="activeTab === 1">
      <dx-date-range-box
        class="time_line"
        [startDate]="startDate"
        [endDate]="endDate"
        labelMode="floating"
        [min]="oneYearAgo"
        [max]="today"
        displayFormat="dd MMM, yyyy"
        (onValueChanged)="filterDonations($event)"
      ></dx-date-range-box>
    </div>
    <div class="col-sm-12 col-lg-6 tab_div">
      <div class="tab" [class.active]="activeTab === 1" (click)="activeTab = 1">
        <img
          [src]="
            '/assets/images/icon/' +
            (activeTab === 1
              ? 'dashboard_active_icon.svg'
              : 'dashboard_inactive_icon.svg')
          "
        />Dashboard
      </div>
      <div class="tab" [class.active]="activeTab === 2" (click)="activeTab = 2">
        <img
          [src]="
            'assets/images/icon/' +
            (activeTab === 2
              ? 'lists_icon_active.svg'
              : 'lists_icon_inactive.svg')
          "
        />All Donor
      </div>
    </div>
  </div>

  @if (activeTab === 1){
  <div class="row mb_32">
    <div class="col-4">
      <div class="card">
        <div class="d-flex justify-content-between">
          <div class="total_donation">Total Donation</div>
          <img src="/assets/images/icon/donation_1_icon.svg" />
        </div>
        <div class="number">
          ৳{{ dashboardDetails?.total_donation | number }}
        </div>
        <!-- <div class="header text-dark">
          <span class="growth" data-value="+3">+3.21% </span
          ><span>growth last year</span>
        </div> -->
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="d-flex justify-content-between">
          <div class="total_donation">Total Donors</div>
          <img src="/assets/images/icon/donation_year_icon.svg" />
        </div>
        <div class="number">{{ dashboardDetails?.total_donor | number }}</div>
        <!-- <div class="header text-dark">
          <span class="growth" data-value="+3">+3.21% </span
          ><span>growth last year</span>
        </div> -->
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="d-flex justify-content-between">
          <div class="total_donation">Average Donations</div>
          <img src="/assets/images/icon/donate_avarage_icon.svg" />
        </div>
        <div class="number">
          ৳{{ dashboardDetails?.average_donation | number }}
        </div>
        <!-- <div class="header text-dark">
          <span class="growth" data-value="-3">-3% </span
          ><span>growth last year</span>
        </div> -->
      </div>
    </div>
  </div>

  <div class="row mb_32">
    <div class="col-8">
      <div class="top_donor_div pr-0">
        <div class="d-flex justify-content-between mb-3">
          <div class="top_donor fw_500">Recently Donated</div>
          <div (click)="showRecentDonationPopUp = true">
            <img class="cursor" src="/assets/images/icon/maximize_icon.svg" />
          </div>
        </div>

        <div class="donor_div">
          <dx-data-grid
            class="chart_div data_grid"
            [dataSource]="donationHistory"
            [showBorders]="false"
            [showColumnLines]="false"
            [showRowLines]="false"
            [allowColumnResizing]="true"
            columnResizingMode="widget"
            [focusedRowEnabled]="false"
            [rowAlternationEnabled]="false"
            rowTemplate="rowTemplateName"
            [wordWrapEnabled]="true"
            [columnAutoWidth]="true"
            [allowColumnReordering]="false"
          >
            <dxi-column caption="Name" dataField="name"></dxi-column>
            <dxi-column caption="Campaign" dataField="campaign_title">
            </dxi-column>
            <dxi-column caption="Date" dataField="last_donation_date">
            </dxi-column>
            <dxi-column caption="Amount" alignment="right" dataField="amount">
            </dxi-column>

            <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
              <tr class="custom-row">
                <td>
                  <div
                    class="left_side_property cursor"
                    style="margin: 12px"
                    (click)="
                      row.data?.user_id
                        ? navigateToDonorProfile(row.data.user_id)
                        : navigateToDonorProfile(row.data.email)
                    "
                  >
                    <img
                      class="profile_img"
                      loading="lazy"
                      [src]="'./assets/images/round_profile.png'"
                      alt="profile_pic"
                    />
                    <div class="name_amount">
                      <div class="name">
                        {{
                          row?.data?.name ? row?.data?.name : "not available"
                        }}
                      </div>
                      <div class="amount">#1943736193749</div>
                    </div>
                  </div>
                  <!-- {{ row?.data?.last_donation_date | date: "dd MMM yyyy" }} -->
                </td>
                <td class="campaign_name">
                  {{ row?.data?.campaign_title }}
                </td>
                <td>
                  <div class="date_div">
                    {{ row?.data?.last_donation_date | date : "dd MMM yyyy" }}
                  </div>
                </td>
                <td>
                  <div class="amount_div">
                    ৳{{ row?.data?.amount | number }}
                  </div>
                </td>
              </tr>
            </tbody>
          </dx-data-grid>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="top_donor_div pr-0">
        <div class="d-flex justify-content-between mb_32">
          <div class="top_donor fw_500">Top Donor</div>
          <div (click)="showDonationPopUp = true">
            <img class="cursor" src="/assets/images/icon/maximize_icon.svg" />
          </div>
        </div>

        <div class="donor_div">
          <div class="loop_div_container">
            <div
              class="loop_div"
              *ngFor="let list of donorFiltering; let i = index"
            >
              <div class="fundraiser_card">
                <div
                  class="left_side_property cursor"
                  (click)="navigateToDonorProfile(list.user_id)"
                >
                  @if(i <= 2){
                  <img
                    class="trophy_img"
                    loading="lazy"
                    [src]="'./assets/images/icon/trophy_' + i + '.svg'"
                    alt="trophy"
                  />
                  }@else {
                  <div class="trophy_img_">{{ i + 1 }}</div>
                  }
                  <img
                    class="profile_img"
                    loading="lazy"
                    [src]="
                      list.profile_img || './assets/images/round_profile.png'
                    "
                    alt="profile_pic"
                  />
                  <div class="name_amount">
                    <div class="name">{{ list?.name || "Anonymous" }}</div>
                    <div class="amount">
                      ৳ {{ (list?.total_amount | number) || 0 }}
                    </div>
                  </div>
                </div>
                <div class="right_side_property">
                  <img
                    class="heart"
                    loading="lazy"
                    [src]="'../../../../../assets/images/icon/heart_hand.svg'"
                    alt="heart"
                  />
                  <div class="number_donor">
                    ({{ list.total_donation | number }})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  } @if(activeTab === 2) {
  <app-donor-list></app-donor-list>
  }
</div>

<dx-popup
  class="popUp"
  [wrapperAttr]="{ class: 'popUp' }"
  [(visible)]="showDonationPopUp"
  [maxWidth]="802"
  [maxHeight]="1142"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [showTitle]="true"
  [title]="'Top Donors'"
  [dragEnabled]="false"
>
  <div class="popup_container">
    <div class="popup_div" *ngFor="let list of topDonors; let i = index">
      <div class="popup_card">
        <div
          class="left_side_property cursor"
          (click)="navigateToDonorProfile(list.user_id)"
        >
          @if(i <= 2){
          <img
            class="trophy_img"
            loading="lazy"
            [src]="'./assets/images/icon/trophy_' + i + '.svg'"
            alt="trophy"
          />
          }@else {
          <div class="trophy_img_">{{ i + 1 }}</div>
          }
          <img
            class="profile_img"
            loading="lazy"
            [src]="list?.profile_img || './assets/images/round_profile.png'"
            alt="profile_pic"
          />
          <div class="name_amount">
            <div class="name">{{ list?.name || "Anonymous" }}</div>
            <div class="amount_donor">৳{{ list.total_amount | number }}</div>
          </div>
        </div>
        <div class="right_side_property">
          <img
            class="heart"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/heart_hand.svg'"
            alt="heart"
          />
          <div class="number_donor">({{ list.total_donation | number }})</div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  class="recentDonationPopup"
  className="recentDonationPopup"
  [wrapperAttr]="{ class: 'recentDonationPopup' }"
  [(visible)]="showRecentDonationPopUp"
  [maxWidth]="1482"
  [maxHeight]="1142"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [showTitle]="true"
  [dragEnabled]="false"
>
  <div class="popup_container">
    <div class="header_recent_donation">Recently Donated</div>

    <div class="recent_donation_data_grid">
      <dx-data-grid
        class="recently_donated_data_grid"
        [dataSource]="recentDonationDataSource"
        [showBorders]="false"
        [showColumnLines]="false"
        [showRowLines]="false"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        [focusedRowEnabled]="false"
        [rowAlternationEnabled]="false"
        rowTemplate="rowRecentDonationName"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="true"
      >
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager
          [visible]="true"
          [showPageSizeSelector]="false"
          [allowedPageSizes]="[10]"
          [showInfo]="true"
          [showNavigationButtons]="true"
        >
        </dxo-pager>
        <dxi-column caption="Donor name" dataField="name"></dxi-column>
        <dxi-column
          caption="Campaign"
          dataField="campaign_title"
          alignment="left"
        >
        </dxi-column>
        <dxi-column
          caption="Time and Date"
          dataField="last_donation_date"
          alignment="center"
        >
        </dxi-column>
        <dxi-column caption="Payment Method" alignment="center"> </dxi-column>
        <dxi-column
          caption="Donation Amount"
          alignment="right"
          dataField="amount"
        >
        </dxi-column>
        <dxi-column caption="Receipt" alignment="center"> </dxi-column>

        <tbody class="dx-row" *dxTemplate="let row of 'rowRecentDonationName'">
          <tr class="custom-row">
            <td>
              <div
                class="left_side_property cursor"
                (click)="
                  row.data?.user_id
                    ? navigateToDonorProfile(row.data.user_id)
                    : navigateToDonorProfile(row.data.email)
                "
                style="margin: 12px 12px 12px 0px"
              >
                <img
                  class="profile_img"
                  loading="lazy"
                  [src]="'./assets/images/round_profile.png'"
                  alt="profile_pic"
                />
                <div class="name_amount">
                  <div class="name">{{ row?.data?.name }}</div>
                  <!-- <div class="amount">#1943736193749</div> -->
                </div>
              </div>
              <!-- {{ row?.data?.last_donation_date | date: "dd MMM yyyy" }} -->
            </td>
            <td class="campaign_name">
              {{ row?.data?.campaign_title }}
            </td>
            <td>
              <div class="">
                <div class="date">
                  {{ row?.data?.updated_at | date : "dd MMM yyyy" }}
                </div>
                <div class="time">
                  {{ row?.data?.updated_at | date : "shortTime" }}
                </div>
              </div>
            </td>
            <td>
              <div class="payment_div">{{ "Shohay Payment" }}</div>
            </td>
            <td>
              <div class="amount_div">৳ {{ row?.data?.amount | number }}</div>
            </td>
            <td>
              <div class="text-end"><a class="download_div cursor"> Download Receipt </a></div>
            </td>
          </tr>
        </tbody>
      </dx-data-grid>
    </div>
    <div class="w-100 justify-content-start load_more_div">
      <button (click)="getRecentDonations()">Load More</button>
    </div>
  </div>
</dx-popup>
