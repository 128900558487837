<div class="main_div">
  <div class="breadcrumb">
    <span class="donation_title"> Campaigns</span>
    <span> / Donation Campaigns</span>
  </div>

  <div class="header">
    <div class="mb-2 d-flex justify-content-between">
      <div>Donation Campaigns</div>
      <div>
        <button class="blue-button-md" routerLink="/admin/create-new-campaign">
          Create Campaign
        </button>
        <img
          style="padding: 10px"
          src="../../../../../assets/images/icon/more_vertical_icon.svg"
          alt="form-options"
          title="form-options"
        />
      </div>
    </div>
  </div>

  <!-- panel-title -->
  <div
    class="full_panel row d-flex justify-content-between align-items-center"
    *ngIf="allCampaign?.length > 0"
  >
    <div class="col d-flex p-0">
      <dx-tab-panel
        (onSelectionChanged)="onTabChange($event)"
        style="box-shadow: none"
        [swipeEnabled]="true"
      >
        <dxi-item title="Recent" [icon]="getIcon(0)"> </dxi-item>
        <dxi-item title="My Campaigns" [icon]="getIcon(1)"> </dxi-item>
        <dxi-item title="Favorite" [icon]="getIcon(2)"> </dxi-item>
      </dx-tab-panel>
    </div>

    <div class="col panel_right_side d-flex gap-2 justify-content-end p-0">
      <dx-text-box
        class="search_box"
        placeholder="Filter By keyword"
        [showClearButton]="true"
        (onInput)="onSearchKeywordChanged($event)"
        [buttons]="[
          {
            name: 'searchForm',
            location: 'before',
            options: {
              icon: 'find',
              elementAttr: {
                class: 'searchForm'
              }
            }
          }
        ]"
      >
        <dx-validator validationGroup="validationGrp">
          <dxi-validation-rule></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
  </div>
  <!-- panel-title-end -->

  <!-- tab-panel-content -->
  <div class="panel_content_for_my_form">
    <div *ngIf="allCampaign.length">
      <div class="list_view_section">
        <div *ngFor="let campaign of activeDataSource">
          <div
            class="list_item d-flex justify-content-between align-items-center bg-white"
          >
            <div class="projects_name_div">
              <img
                src="./assets/images/icon/donation_colorful_icon.svg"
                alt="donation-image"
                title="form-options"
              />
              <div class="campaign_name">
                <div class="appeal_title">{{ campaign?.campaign_title }}</div>
                <div class="appeal_subtitle">
                  {{ campaign.appeal_subtitle }}
                </div>
              </div>
            </div>
            <div class="status_div">
              <span style="color: #939393">Status: </span>
              <span>
                {{ campaign?.status === "RUNNING" ? "In Progress" : "Stopped" }}
              </span>
            </div>

            <div class="date_div">
              <span class="form_date"
                >Created on: {{ formatDate(campaign?.created_at) }}</span
              >
              <img
                class="cursor"
                src="../../../../../assets/images/icon/kid_star_icon.svg"
                alt="campaign-options"
                title="form-options"
              />
              <dx-drop-down-button
                [dropDownOptions]="{ width: 184 }"
                [showArrowIcon]="false"
                [icon]="'./assets/images/icon/more_vertical_icon.svg'"
                displayExpr="text"
                [items]="[
                  {
                    text:
                      campaign?.status === 'RUNNING'
                        ? 'Stop Campaign'
                        : 'Start Campaign',
                    icon:
                      campaign?.status === 'RUNNING'
                        ? './assets/images/icon/stop_icon.svg'
                        : './assets/images/icon/start_icon.svg'
                  },
                  { text: 'Edit', icon: './assets/images/icon/editIcon.svg' },
                  { text: 'Delete', icon: './assets/images/icon/delete.svg' },
                  { text: 'Donations', icon: './assets/images/icon/donate_icon_black.svg' },
                  { text: 'Share', icon: './assets/images/icon/share_icon.svg' }
                ]"
                (onItemClick)="buttonClick($event, campaign)"
              ></dx-drop-down-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- tab-panel-content-end -->

  <div
    *ngIf="allCampaign.length === 0"
    class="No_form_container d-flex flex-column align-items-center"
    style="margin-top: 50px"
  >
    <img
      src="../../../../../assets/images/icon/project_icon_colorful.svg"
      alt="add"
      title="add campaign"
    />
    <span class="no_form_workspace">No campaign in workspace yet</span>
    <span class="select_form_text">Create a new campaign to get started</span>
    <button
      class="blue-button-md form_button"
      routerLink="/admin/create-new-campaign"
    >
      <img
        src="../../../../../assets/images/icon/add_circle_icon.svg"
        alt="add"
        title="add"
      />
      New Campaign
    </button>
  </div>
</div>
