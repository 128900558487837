export class pledgeDonationModel {
  name: string = null;
  email: string = null;
  comment: string = null;
  transaction_fees: number = 0;
  is_video_call_active: boolean = null;
  whatsapp_no: string = null;
  is_shohay_user: boolean = null;
  user_id: string = null;
  admin_id: string = null;
  pledge_day: string = null;
  pledge_month: string = null;
  donor_id: string = null;
  is_hidden_name: boolean = null;
  is_hidden_comment: boolean = null;
  can_contact: boolean = null;
  is_hidden_name_and_amount: boolean = null;
  ngo_id: string = null;
  ngo_name: string = null;
  campaign_id: string = null;
  campaign_title: string = null;
  fundraiser_id: string = null;
  project_id: string = null;
  team_id: string = null;
  amount: number = 0;
  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}

export class pledgePaymentModel {
    received_amount: number = 0;
    payment_method: string = null;
    pledging_status: string = null;
    transaction_id: string = null;
    admin_id: string = null;
  
    constructor(obj?: any) {
      if (obj) {
        Object.keys(this).forEach((key) => {
          if (obj.hasOwnProperty(key)) {
            this[key] = obj[key];
          }
        });
      }
    }
  }
  