import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { FundraiserService } from 'src/app/shared/services/fundraiser/fundraiser.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fundraiser-profile',
  templateUrl: './fundraiser-profile.component.html',
  styleUrl: './fundraiser-profile.component.scss',
})
export class FundraiserProfileComponent implements OnInit {
  public openContactDetails: boolean = false;
  public openFundraisingTeams: boolean = false;
  public openFundraisingMembers: boolean[] = [];

  public campaigns: any = new Map();
  public selectedCampaign: any;
  public selectedTeam: any;

  private fundraiserId: string;
  public fundraiserDetails: any;
  public fundraisingTeams: any[] = [];

  public teamDetails: any;
  public teamChart: any = new Map();

  public raised: number = 0;
  public target: number = 0;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly utilitiesService: UtilitiesService,
    private readonly fundraiserService: FundraiserService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.fundraiserId = params['id'];
      if (this.fundraiserId) {
        this.getFundraiserDetails();
        this.getTeamFundraisingHistory('','');
      }
    });
  }

  private getFundraiserDetails(): void {
    this.fundraiserService
      .getFundraiserProfile(this.fundraiserId)
      .subscribe((result: any) => {
        this.fundraiserDetails = result;
        this.fundraisingTeams = result.team_info;
        this.modTEamData();
      });
  }

  private modTEamData(): void {
    this.fundraisingTeams.forEach((team) => {
      this.openFundraisingMembers.push(false);

      team.team_logo = this.utilitiesService.jsonParse(team.team_logo)?.url;
      team.member_info.forEach((member) => {
        member.profile_img = this.utilitiesService.jsonParse(
          member.profile_img
        )?.url;
      });

      const { member_info, ...campaignInfo } = team;
      const teamInfo = campaignInfo;
      if (this.campaigns.has(team?.campaign_id)) {
        this.campaigns.get(team?.campaign_id).teams.push(teamInfo);
      } else {
        teamInfo.teams = [teamInfo];
        this.campaigns.set(team?.campaign_id, teamInfo);
      }
    });

    this.campaigns = Array.from(this.campaigns.values());
    this.selectedCampaign = this.campaigns[0];
    this.selectedTeam = this.selectedCampaign?.teams[0];
  }

  public campaignSelected(e: any): void {
    if (e.value) {
      this.selectedTeam = this.selectedCampaign?.teams[0];
    }
  }

  public teamSelected(e: any): void {
    if (e.value) {
      this.getTeamFundraisingHistory(e.value.campaign_id, e.value.team_id);
    }
  }

  private getTeamFundraisingHistory(campaignId: string, teamId: string): void {
    // this.fundraiserService
    //   .getFundraisingTeamHistory(this.fundraiserId, campaignId, teamId)
    //   .subscribe((result: any) => {
    this.teamDetails = this.result;
    //     this.teamDetails = result;
        this.raised = this.result.total_fund_raised;
        this.target = this.result.fundraising_target;
    this.getTeamChartData();
    //   });
  }

  private getTeamChartData(): void {
    const chartMap = new Map();
    this.teamDetails?.donors?.forEach((donors: any) => {
      const month = this.formatDate(donors.donation_month);
      if (chartMap.has(month)) {
        chartMap.set(month, chartMap.get(month) + donors?.amount);
      } else {
        chartMap.set(month, donors?.amount);
      }
    });

    this.teamChart = [];
    chartMap.forEach((value, key) => {
      this.teamChart.push({ key, value });
    });

    this.teamChart.sort(
      (a, b) => new Date(a.key).getTime() - new Date(b.key).getTime()
    );
    console.log(this.teamChart)
  }

  private formatDate(value: Date): string {
    const date = new Date(value);
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);

    return `${month}, ${year}`;
  }

  public activeTabIndex = 0;

  public onTabChange(event: any) {
    this.activeTabIndex = event.component.option('selectedIndex');
  }

  result = {
    total_fund_raised: 50000,
    fundraising_target: 90000,
    total_donors: 3,
    donors: [
      {
        id: 'd9e9f6b2-43c5-4b31-9017-dc7585f3ed58',
        name: 'Anonymous',
        amount: 0,
        status: 'RECEIVED',
        donation_day: '2024-12-18',
        donation_month: '2024-12',
      },
      {
        id: 'a7c6f5e1-12b4-4d8a-b987-87b93ed2f7b3',
        name: 'John Doe',
        amount: 20000,
        status: 'RECEIVED',
        donation_day: '2024-12-17',
        donation_month: '2024-12',
      },
      {
        id: 'c3e7f8b5-76a2-431a-b56b-93f85a7ed2c9',
        name: 'Jane Smith',
        amount: 30000,
        status: 'RECEIVED',
        donation_day: '2024-12-16',
        donation_month: '2024-12',
      },
      {
        id: 'e1a5f7c4-91b2-4e4d-a312-f0c875f7a89b',
        name: 'Alice Johnson',
        amount: 15000,
        status: 'PENDING',
        donation_day: '2024-11-10',
        donation_month: '2024-11',
      },
      {
        id: 'b7f6e3a5-82c1-4a6d-b412-df7c93a6f1c8',
        name: 'Bob Lee',
        amount: 25000,
        status: 'RECEIVED',
        donation_day: '2024-11-12',
        donation_month: '2024-11',
      },
      {
        id: 'a3f6e9b7-92c4-4e3b-a124-cf7b94f8c3d7',
        name: 'Clara Zhang',
        amount: 10000,
        status: 'PENDING',
        donation_day: '2024-10-05',
        donation_month: '2024-10',
      },
      {
        id: 'f4b2c8d6-84a5-4b31-b587-ef4c5a7e3f9d',
        name: 'David Kim',
        amount: 40000,
        status: 'RECEIVED',
        donation_day: '2024-09-22',
        donation_month: '2024-09',
      },
      {
        id: 'b6d4c8f3-85a9-4c3d-b472-df7e93c2f4d8',
        name: 'Ella Brown',
        amount: 5000,
        status: 'RECEIVED',
        donation_day: '2024-08-15',
        donation_month: '2024-08',
      },
      {
        id: 'a5c8f3d6-94e7-4c1a-a543-ef4d83b2c5a7',
        name: 'Frank White',
        amount: 12000,
        status: 'RECEIVED',
        donation_day: '2024-07-19',
        donation_month: '2024-07',
      },
      {
        id: 'c8f4d6a5-72b9-4d8c-b412-f0c5a7e3b9f2',
        name: 'Grace Green',
        amount: 8000,
        status: 'PENDING',
        donation_day: '2024-06-10',
        donation_month: '2024-06',
      },
      {
        id: 'd4a8f3c5-61b3-4d8a-b231-e9c7f5a8c3d1',
        name: 'Henry Black',
        amount: 22000,
        status: 'RECEIVED',
        donation_day: '2024-05-28',
        donation_month: '2024-05',
      },
      {
        id: 'e5f7a9c3-94d2-4c8a-a531-cf7e83b2a5c4',
        name: 'Ivy Blue',
        amount: 27000,
        status: 'RECEIVED',
        donation_day: '2024-04-02',
        donation_month: '2024-04',
      },
      {
        id: 'f7c4a5d8-83b9-4d7c-b214-df7e93c4f8d2',
        name: 'Jack Silver',
        amount: 35000,
        status: 'RECEIVED',
        donation_day: '2024-03-14',
        donation_month: '2024-03',
      },
      {
        id: 'b5d8f3a6-72e9-4d1c-b342-e9c7a5f8c3d4',
        name: 'Karen Gold',
        amount: 18000,
        status: 'PENDING',
        donation_day: '2024-02-27',
        donation_month: '2024-02',
      },
      {
        id: 'd8c3f4a5-82b1-4a6c-b234-f0c7d5a9c4e7',
        name: 'Liam White',
        amount: 29000,
        status: 'RECEIVED',
        donation_day: '2024-01-12',
        donation_month: '2024-01',
      },
    ],
  };
}
