import { Component } from '@angular/core';
import { DonationService } from '../../services/donation/donation.service';
import { UtilitiesService } from '../../services/utilities/utilities.service';

@Component({
  selector: 'app-campaign-wall',
  templateUrl: './campaign-wall.component.html',
  styleUrl: './campaign-wall.component.scss',
})
export class CampaignWallComponent {
  public comment_reply: any[] = [];
  public displayedComments: any[] = [];

  public imgsrc = '../../../../assets/images/winter_help.png';

  constructor(
    private readonly donationService: DonationService,
    public readonly utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    this.getComments();
  }

  public getComments() {
    this.donationService.getDonationWallComment().subscribe({
      next: (result) => {
        if (result.length > 0) {
          this.comment_reply = result.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          );
        }
      },
    });
  }

  // get commentsFiltering(): any[] {
  //   return (this.displayedComments = this.comment_reply.slice(0, 3));
  // } 
}
