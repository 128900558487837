export enum ApprovalCategoryEnum {
  NGO = 'NGO_APPROVAL',
  CAMPAIGN = 'CAMPAIGN_APPROVAL',
  FEATURED_CAMPAIGN = 'FEATURE_CAMPAIGN_APPROVAL'
}

export enum ApprovalStatusEnum {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  SUSPENDED = 'SUSPENDED'
}
