<div class="main_div">
  <div class="breadcrumb">
    <span class="donation_title">Donation Management / All Fundraiser / </span>
    <span> Fundraiser Profile </span>
  </div>

  <div class="main_body">
    <div class="contact_profile">
      <div class="upper_part">
        <img
          [src]="
            fundraiserDetails?.profile_img ||
            './assets/images/round_profile.png'
          "
          alt="Profile_pic"
        />
        <div class="donor_name">{{ fundraiserDetails?.name }}</div>
        <div class="donations bottom">
          Joining Date -
          {{ fundraiserDetails?.joining_date | date : "dd-M-yyyy | hh:mm a" }}
        </div>
        <div class="email">{{ fundraiserDetails?.email }}</div>
      </div>
      <div class="lower_part">
        <div class="w-100">
          <div
            class="w-100 d-flex justify-content-between align-items-center mb-3 title_card cursor"
            (click)="openContactDetails = !openContactDetails"
          >
            <span>Contact Details</span>
            <img
              [src]="
                openContactDetails
                  ? './assets/images/icon/up_arrow_icon_inactive.svg'
                  : './assets/images/icon/down_arrow_icon_inactive.svg'
              "
            />
          </div>
          <div [ngClass]="openContactDetails ? 'visible' : 'hidden'">
            <div class="w-100 d-flex gap-2 align-items-center mb-2 title_card">
              <img src="./assets/images/icon/whats_app_bg_icon.svg" />
              <div>{{ fundraiserDetails?.contact_no }}</div>
            </div>
            <div class="w-100 d-flex gap-2 align-items-center title_card">
              <img src="./assets/images/icon/location_bg_icon.svg" />
              <div>{{ fundraiserDetails?.address }}</div>
            </div>
          </div>
        </div>

        <div class="w-100">
          <div
            class="w-100 d-flex justify-content-between align-items-center mb-3 title_card cursor"
            (click)="openFundraisingTeams = !openFundraisingTeams"
          >
            <span>Fundraising Teams</span>
            <img
              [src]="
                openFundraisingTeams
                  ? './assets/images/icon/up_arrow_icon_inactive.svg'
                  : './assets/images/icon/down_arrow_icon_inactive.svg'
              "
            />
          </div>
          <div [ngClass]="openFundraisingTeams ? 'visible' : 'hidden'">
            <div
              class="w-100 d-flex flex-column justify-content-center gap-2 mb-2 title_card"
              *ngFor="let team of fundraisingTeams; let i = index"
            >
              <div
                class="w-100 d-flex align-items-center justify-content-between cursor"
                (click)="openFundraisingMembers[i] = !openFundraisingMembers[i]"
              >
                <div class="d-flex gap-2 align-items-center">
                  <img
                    class="team_logo"
                    [src]="team?.team_logo"
                    alt="Team Logo"
                  />
                  <div class="name">{{ team?.team_name }}</div>
                </div>
                <div class="d-flex gap-2 align-items-center">
                  <div>
                    {{ team?.member_info?.length }}
                    {{ team?.member_info?.length > 1 ? "Members" : "Member" }}
                  </div>
                  <img
                    [src]="
                      openFundraisingTeams
                        ? './assets/images/icon/up_arrow_icon_inactive.svg'
                        : './assets/images/icon/down_arrow_icon_inactive.svg'
                    "
                  />
                </div>
              </div>
              <div [ngClass]="openFundraisingMembers[i] ? 'visible' : 'hidden'">
                <div
                  class="w-100 d-flex gap-3 align-items-center mb-2 title_card"
                  style="margin-left: 50px"
                  *ngFor="let member of team?.member_info"
                >
                  <img
                    class="team_logo name fw-normal"
                    [src]="
                      member?.profile_img || './assets/images/round_profile.png'
                    "
                    alt="Profile Img"
                  />
                  <div class="name fw-normal">
                    {{ member?.fundraiser_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recent_donations">
      <div class="payment_cards">
        <div class="total_fund_raised">
          <div class="title_amount">
            <div class="title_card">Total fund raised</div>
            <div class="amount">
              ৳ {{ fundraiserDetails?.total_fund_raised | number }}
            </div>
          </div>
          <div class="icon">
            <img
              src="../../../../../../assets/images/icon/dollar-hand.svg"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="total_supporters">
          <div class="title_amount">
            <div class="title_card">Total Supporters</div>
            <div class="amount">
              {{ fundraiserDetails?.total_supporters | number }}
            </div>
          </div>
          <div class="icon">
            <img
              src="../../../../../../assets/images/icon/multiple-user.svg"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
      <div class="story">
        <div class="donor_name">Anis's Story</div>
        <div class="story_body">
          {{ fundraiserDetails?.story }}
        </div>
      </div>
      <div class="title_main_body">Overview</div>

      <div
        class="full_panel row d-flex justify-content-between align-items-center"
      >
        <div class="col d-flex p-0">
          <dx-tab-panel
            (onSelectionChanged)="onTabChange($event)"
            [selectedIndex]="activeTabIndex"
            style="box-shadow: none"
            [swipeEnabled]="true"
          >
            <dxi-item
              title="Recent"
              [icon]="
                activeTabIndex === 0
                  ? './assets/images/icon/statistics.svg'
                  : './assets/images/icon/approve-inactive.svg'
              "
            >
            </dxi-item>
            <dxi-item
              title="All"
              [icon]="
                activeTabIndex === 1
                  ? './assets/images/icon/lists_icon_active.svg'
                  : './assets/images/icon/lists_icon_inactive.svg'
              "
            >
            </dxi-item>
          </dx-tab-panel>
        </div>
      </div>

      @if(activeTabIndex === 0){
      <div class="select_section">
        <div class="select_campaign">
          <div class="select_box_title">
            <div class="select_cam">Select Campaign:</div>
            <dx-select-box
              class="input_field"
              [dataSource]="campaigns"
              [(ngModel)]="selectedCampaign"
              displayExpr="campaign_title"
              (onValueChanged)="campaignSelected($event)"
            >
            </dx-select-box>
          </div>
        </div>
        <div class="select_team">
          <div class="select_box_title">
            <div class="select_cam">Select Team:</div>
            <dx-select-box
              class="input_field"
              [dataSource]="selectedCampaign?.teams"
              [(ngModel)]="selectedTeam"
              displayExpr="team_name"
              (onValueChanged)="teamSelected($event)"
            >
            </dx-select-box>
          </div>
        </div>
      </div>
      <div class="analysis_section">
        <div class="grid-container">
          <div class="col-12">
            <div class="top_donor_div pr-0">
              <div class="donor_div">
                <dx-data-grid
                  class="chart_div data_grid"
                  [dataSource]="teamDetails?.donors"
                  [showBorders]="false"
                  [showColumnLines]="false"
                  [showRowLines]="false"
                  [allowColumnResizing]="true"
                  columnResizingMode="widget"
                  [focusedRowEnabled]="false"
                  [rowAlternationEnabled]="false"
                  rowTemplate="rowTemplateName"
                  [wordWrapEnabled]="true"
                  [columnAutoWidth]="true"
                >
                  <dxo-paging
                    *ngIf="teamDetails?.member_info?.length > 5"
                    [pageSize]="5"
                  ></dxo-paging>
                  <dxo-pager
                    *ngIf="teamDetails?.member_info?.length > 5"
                    [visible]="true"
                    [showPageSizeSelector]="false"
                    allowedPageSizes="6"
                    [showInfo]="true"
                  >
                  </dxo-pager>
                  <dxi-column
                    caption="Member"
                    alignment="left"
                    dataField="name"
                  >
                  </dxi-column>
                  <dxi-column
                    caption="Date"
                    dataField="donation_day"
                  ></dxi-column>
                  <dxi-column
                    max-width="107"
                    caption="Amount"
                    alignment="right"
                    dataField="amount"
                  >
                  </dxi-column>

                  <tbody
                    class="dx-row"
                    *dxTemplate="let row of 'rowTemplateName'"
                  >
                    <tr class="custom-row">
                      <td style="text-align: left">
                        <span class="member-td">{{
                          row?.data?.name
                        }}</span>
                      </td>
                      <td>
                        <div class="date_div bold">
                          {{ row?.data?.donation_day | date : "dd-MM-yyyy" }}
                          <!-- <span class="date_div">
                            {{ row?.data?.updated_at | date : "hh.mm a" }}</span
                          > -->
                        </div>
                      </td>
                      <td>
                        <div class="amount_div">
                          ৳ {{ row?.data?.amount | number }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </dx-data-grid>
              </div>
            </div>
          </div>
        </div>
        <div class="right_down_section">
          <div class="total_raised">
            <div class="first_row">
              <div class="title_raised">Total Raised</div>
              <div class="donor_number">
                <img
                  src="../../../../../../assets/images/icon/multiple-user.svg"
                  alt="num"
                  srcset=""
                />
                <span class="don_num">{{
                  teamDetails?.total_donors | number
                }}</span>
              </div>
            </div>
            <div class="first_row">
              <div class="blue">
                ৳ {{ teamDetails?.total_fund_raised | number }}
              </div>
              <div class="goal">
                raised of ৳ {{ teamDetails?.fundraising_target | number }}
                goal
              </div>
            </div>

            <dx-progress-bar
              class="progress"
              [min]="0"
              [max]="target"
              [value]="raised"
              [showStatus]="false"
            ></dx-progress-bar>
          </div>
          <div class="bar_chart">
            <dx-chart id="chart" class="chart_div_" [dataSource]="teamChart">
              <dxo-zoom-and-pan argumentAxis="both"></dxo-zoom-and-pan>
              <dxo-scroll-bar [visible]="true"></dxo-scroll-bar>
              <dxo-animation
                easing="linear"
                [duration]="500"
                [maxPointCountSupported]="300"
              >
              </dxo-animation>
              <dxo-legend
                verticalAlignment="top"
                horizontalAlignment="right"
                position="inside"
                backgroundColor="none"
              >
              </dxo-legend
              ><dxi-series
                argumentField="key"
                valueField="value"
                name="Fund raised"
                type="bar"
                color="#0054a6"
                barWidth="28"
                cornerRadius="0px"
              >
              </dxi-series>
              <dxo-argument-axis
                [visualRange]="{
                  length: 5
                }"
              >
              </dxo-argument-axis>
              <dxo-common-series-settings
                argumentField="key"
                type="bar"
                hoverMode="allArgumentPoints"
                selectionMode="allArgumentPoints"
              >
                <dxo-label
                  [visible]="true"
                  [format]="{
                    type: 'fixedPoint',
                    precision: 0
                  }"
                >
                </dxo-label>
              </dxo-common-series-settings>
            </dx-chart>
          </div>
        </div>
      </div>
      }@else {
      <div>nooknoknok</div>
      }
    </div>
  </div>
</div>
