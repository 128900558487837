import { Component } from '@angular/core';
import { DonationService } from '../../services/donation/donation.service';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hall-of-fame',
  templateUrl: './hall-of-fame.component.html',
  styleUrl: './hall-of-fame.component.scss',
})
export class HallOfFameComponent {
  public donation: any[] = 
  [
  ];

  public fundRaiserDonor: any = [
  ]

  public year: string = '2024';
  // public fundRaiserDonor: any = [];
  public topCampaign: any = [];
  public topDonor: any = [];
  // public filteredFundRaiserDonor:any = [];
  // public filteredDonar:any = [];
  public showDonationPopUp: boolean = false;
  public topFundraiserListPopUp: boolean = false;
  public topDonationListPopUp: boolean = false;
  public topCampaignPopUp: boolean = false;

  constructor(
    private readonly donationService: DonationService,
    public readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.getTopFundRaiser();
  }

  public getTopFundRaiser() {
    this.donationService.getTopCampaignWithDonation(this.year).subscribe({
      next: (result) => {
        this.fundRaiserDonor = result.top_fund_raiser;
        this.topCampaign = result.top_campaign.map((item) => {
          return {
            ...item,
            cover_img_vdo: JSON.parse(item.cover_img_vdo),
          };
        });
        this.donation = result.top_donor;
      },
    });
  }

  get topCampaignFiltering(): any[] {
    return this.topCampaign.slice(0, 3);
  }

  get fundRaiserDonorFiltering(): any[] {
    // return this.fundRaiserDonor.slice(0, 5);
    return this.fundRaiserDonor.slice(0, 5);
  }

  get donorFiltering(): any[] {
    return this.donation.slice(0, 5);
  }

  public showPopup(cardTitle: string) {
    this.showDonationPopUp = true;
    if (cardTitle === 'fundraiser') {
      this.topFundraiserListPopUp = true;
    } else if (cardTitle === 'donor') {
      this.topDonationListPopUp = true;
    } else if (cardTitle === 'campaign') {
      this.topCampaignPopUp = true;
    }
  }

  public closePopup() {
    this.topFundraiserListPopUp = false;
    this.topDonationListPopUp = false;
    this.showDonationPopUp = false;
    this.topCampaignPopUp = false;
  }

  public navigateToCampaign(article: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      ngo_id: article?.ngo_id,
      project_id: article?.project_id,
      id: article?.id,
    });

    this.router.navigate(['/campaign'], {
      queryParams: { data: encryptedParams },
    });
  }

  get popupTitle(): string {
    if (this.topFundraiserListPopUp) {
      return 'Top Fundraisers';
    } else if (this.topDonationListPopUp) {
      return 'Top Donors';
    } else if (this.topCampaignPopUp) {
      return 'Top Campaigns';
    }
    return '';
  }
}
