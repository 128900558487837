import { Injectable } from '@angular/core';
import { UrlEnum } from '../../enum/url.enum';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { map, Observable } from 'rxjs';
import { GalleryModel } from '../../model/gallery.model';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  private galleryApi = `${UrlEnum.SHOHAY_NGO}/gallery`;
  private fileUploadApi = `${UrlEnum.SHOHAY_FILE}/aws-bucket-files`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public createGallery(body: GalleryModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.galleryApi}/create`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getGalleryItemCount(campaignId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.galleryApi}/get-by-campaign/${campaignId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getGallery(galleryId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.fileUploadApi}/get-by-gallery/${galleryId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
