import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsArticleTypeEnum } from 'src/app/shared/enum/news-article.enum';
import {
  NewsArticleBodyModel,
  PostCommentModel,
} from 'src/app/shared/model/article.model';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';
import ValidationEngine from 'devextreme/ui/validation_engine';

@Component({
  selector: 'app-article-news',
  templateUrl: './article-news.component.html',
  styleUrl: './article-news.component.scss',
})
export class ArticleNewsComponent implements OnInit {
  public commentSortingType = 1;
  public sessionUser: any = null;
  public comment: string;

  public newsArticleId: string;
  public newsArticle: NewsArticleBodyModel = new NewsArticleBodyModel();
  public newsArticleTypeEnum = NewsArticleTypeEnum;
  public activeSectionId: number = 1;

  private articleNewsOriginalList: any[] = [];
  public articleNewsList: any[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly utilitiesService: UtilitiesService,
    private readonly shohayBlogService: ShohayBlogService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const encryptedString = params['data'];
      const decryptedParams =
        this.localStorageService.decryptObjectUrl(encryptedString);
      if (decryptedParams) {
        this.newsArticleId = decryptedParams.id;
        if (this.newsArticleId) {
          this.loadArticleById(this.newsArticleId);
        }
        this.loadArticleNews();
      }
    });

    this.sessionUser = this.localStorageService.getSessionUser();
  }

  private loadArticleById(articleId: string): void {
    this.shohayBlogService
      .getArticleById(articleId)
      .subscribe((result: any) => {
        console.log(result);
        this.newsArticle = new NewsArticleBodyModel(result);
        console.log(this.newsArticle);
        if (this.newsArticle.blog_type === this.newsArticleTypeEnum.NEWS) {
          this.newsArticle.table_of_contents = [
            { content_order: 1, description: this.newsArticle.content },
          ];
        }
      });
  }
  public sortComments(e: any): void {
    if (this.newsArticle.comments) {
      this.newsArticle.comments.sort((a, b) => {
        return (
          e.selectedItem.order *
          (new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
        );
      });
    }
  }

  public async postComments() {
    const { isValid } = ValidationEngine.validateGroup('validationGroup');
    if (isValid) {
      const response = await this.utilitiesService.showSwalAndReturn({
        type: 'warning',
        title: `Are you sure?`,
        message: 'You want to post this comment?',
        confirmButtonText: 'Post',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      });

      if (response.isConfirmed) {
        const commentBody = new PostCommentModel();
        commentBody.comment = this.comment;
        commentBody.user_id = this.sessionUser.id;
        commentBody.user_name = this.sessionUser.name;
        commentBody.user_profile_img = this.sessionUser?.profile_img || null;

        this.shohayBlogService
          .postComment(commentBody)
          .subscribe((result: any) => {
            const ids = this.newsArticle.comments.map((item) => item.id);
            ids.push(result?.data?.id);

            this.shohayBlogService
              .addCommentToNewsArticle(this.newsArticle.id, ids)
              .subscribe((res: any) => {
                this.loadArticleById(this.newsArticleId);
                ValidationEngine.resetGroup('validationGroup');
              });
          });
      }
    }
  }

  private loadArticleNews(): void {
    this.shohayBlogService.getArticleNews(1, 12).subscribe((result) => {
      this.articleNewsOriginalList = result.data.splice(0, 4);
      this.articleNewsList = JSON.parse(
        JSON.stringify(this.articleNewsOriginalList)
      );
    });
  }

  public getUrl(url: string): string {
    return url?.replace(
      /^.*\/uploads\//,
      'https://shohay.net/shohay-blog/images/'
    );
  }

  public getLink(url: any): string {
    return url?.url;
  }

  public onChange(contentID: number) {
    this.activeSectionId = contentID;
    this.scrollToSection(contentID);
  }

  public scrollToSection(contentID: number) {
    this.activeSectionId = contentID;
    const element = document.getElementById(contentID.toString());

    if (element) {
      const offsetPosition =
        element.getBoundingClientRect().top + window.scrollY - 75;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  //progressbar
  public getScrollProgress(): number {
    const scrollPosition = window.scrollY;
    const totalHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    return (scrollPosition / totalHeight) * 100;
  }
}
