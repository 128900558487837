import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { DonorService } from 'src/app/shared/services/donor/donor.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-donor-dashboard',
  templateUrl: './donor-dashboard.component.html',
  styleUrl: './donor-dashboard.component.scss',
})
export class DonorDashboardComponent {
  public utilitiesService = UtilitiesService;

  public activeTab: number = 1;
  public showDonationPopUp = false;
  public showRecentDonationPopUp = false;

  public today: Date = new Date();
  public oneYearAgo: Date = new Date(
    this.today.getFullYear() - 1,
    this.today.getMonth(),
    this.today.getDate(),
    this.today.getHours(),
    this.today.getMinutes(),
    this.today.getSeconds(),
    this.today.getMilliseconds()
  );

  public startDate: Date = this.oneYearAgo;
  public endDate: Date = this.today;

  public dashboardDetails: any;
  public topDonors: any[] = [];
  public donationHistory: any[] = [];
  public recentDonationDataSource: any[] = [];
  public donationHistoryPageNo: number = 0;

  constructor(
    private readonly router: Router,
    private readonly donorService: DonorService,
    private readonly donationService: DonationService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getDonorDashboardDetails(this.oneYearAgo, this.today);
  }

  private getDonorDashboardDetails(startDate: Date, endDate: Date): void {
    this.donorService
      .getDonorDashboardDetails(startDate, endDate)
      .subscribe((result: any) => {
        this.dashboardDetails = result;

        result.donation_history.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );

        result.top_donors.forEach((dnr) => {
          dnr.profile_img = UtilitiesService.getUrl(dnr?.profile_img);
        });
        this.topDonors = result.top_donors;
        this.donationHistory = result.donation_history;
        this.getRecentDonations();
      });
  }

  public getRecentDonations(): void {
    this.donationHistoryPageNo += 1;
    this.donationService
      .getRecentDonations(this.donationHistoryPageNo, 10)
      .subscribe((result: any) => {
        this.recentDonationDataSource = this.recentDonationDataSource.concat(
          result.data
        );
      });
  }

  public filterDonations(e: any): void {
    this.getDonorDashboardDetails(new Date(e?.value[0]), new Date(e?.value[1]));
  }

  public get donorFiltering(): any[] {
    return this.topDonors.slice(0, 6);
  }

  public navigateToDonorProfile(user_id: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      user_id: user_id,
    });
    this.router.navigate(['/admin/donation-management/donor-profile'], {
      queryParams: { data: encryptedParams },
    });
  }
}
