<div class="main_div">
  <div class="page_name">User Profile</div>
  <div class="main_body">
    <div class="upper_part">
      <div class="title_img">
        <div class="img">
          <img
            class="img1"
            src="./assets/images/mobashwir_bhai.png"
            alt="pro_pic"
          />
          <img
            class="img2"
            src="./assets/images/icon/upload_camera.svg"
            alt="cam_icon"
          />
        </div>
        <div class="title_edit">
          <div class="title">Abdullah Al Noman</div>
          <div class="edit_mode">
            <img
              (click)="toggleEditMode()"
              class="cursor"
              [src]="
                editMode
                  ? '/assets/images/icon/pencil-edit-blue.svg'
                  : '/assets/images/icon/pencil-edit.svg'
              "
            />
          </div>
        </div>
      </div>
      <div class="about_connected">
        <div class="about_title_desc">
          <div class="about">About me</div>
          <div class="desc">
            Stop sifting through clunky spreadsheets. With Give butter's custom
            filters, contact tags, table sorting, and live search, you can find
            the donor segment you need in moments. You can even save your search
            to get this information again with just one click. You can even save
            your search to get this information again with just one click.
          </div>
        </div>
        <div class="connected">
          <div class="about">Connected to</div>
          <div class="connected_list">
            <div class="connected_list_" *ngFor="let list of connectedList">
              <img src="{{ list.img }}" alt="" />
            </div>
            <!-- @for(list of connectedList;track list.id){
            } -->
          </div>
        </div>
      </div>
    </div>
    <div class="lower_part">
      <div
        class="full_panel row d-flex justify-content-between align-items-center"
      >
        <div class="col d-flex p-0">
          <dx-tab-panel
            (onSelectionChanged)="onTabChange($event)"
            [selectedIndex]="activeTabIndex"
            style="box-shadow: none"
            [swipeEnabled]="true"
          >
            <dxi-item title="Basic Information">
              <!-- [icon]="
                        activeTabIndex === 0
                          ? './assets/images/icon/pending-acitve.svg'
                          : './assets/images/icon/pending-inactive.svg'
                      " -->
            </dxi-item>
            <dxi-item title="Profile and Privacy Settings">
              <!-- [icon]="
                        activeTabIndex === 1
                          ? './assets/images/icon/approve-active.svg'
                          : './assets/images/icon/approve-inactive.svg'
                      " -->
            </dxi-item>
            <dxi-item
              title="Account Actions
                      "
            >
              <!-- [icon]="
                        activeTabIndex === 2
                          ? './assets/images/icon/closed-active.svg'
                          : './assets/images/icon/closed-inactive.svg'
                      " -->
            </dxi-item>
          </dx-tab-panel>
        </div>
      </div>
    </div>
    @if(activeTabIndex === 0){
    <div class="details_part">
      <div class="left_details">
        <div class="profile_name">
          <div class="profile_title">Name</div>
          @if(editMode){
          <dx-text-box class="custom-fill" placeholder="Enter your name">
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Name is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
          }@else {
          <div class="profile_desc">Abdullah Al Noman</div>
          }
        </div>
        <div class="profile_name">
          <div class="profile_title">Address</div>
          @if(editMode){
          <dx-text-area
            class="custom-fill"
            placeholder="Write your address here"
            [height]="80"
            [maxLength]="500"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule type="required" message="this is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-text-area>
          }@else {
          <div class="profile_desc">
            QC9H+98W, PGCB Head Office Building, Aftab Nagar Beside NLDC
            building, Dhaka 1212
          </div>

          }
        </div>
        <div class="profile_name">
          <div class="profile_title">Gender</div>
          @if(editMode){
          <dx-select-box
            class="input_field"
            [dataSource]="['Male', 'Female', 'Custom']"
            placeholder="Select..."
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Date is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
          }@else {
          <div class="profile_desc">male</div>
          }
        </div>
      </div>
      <div class="right_details">
        <div class="profile_name">
          <div class="profile_title">Email</div>
          @if(editMode){
          <dx-text-box
            class="custom-fill"
            placeholder="Enter your email address"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule type="required" message="Email is required">
              </dxi-validation-rule>
              <dxi-validation-rule
                type="email"
                message="Email is invalid. Ex: jone@gmail.com"
              >
              </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
          }@else{
          <div class="profile_desc">
            Mobashwir&#64;hohay.net <span class="verified">Verified</span>
          </div>
          }
        </div>
        <div class="profile_name">
          <div class="profile_title">Occupation</div>
          @if(editMode){
          <dx-text-area
            class="custom-fill"
            placeholder="Write your occupation here"
            [height]="80"
            [maxLength]="500"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule type="required" message="this is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-text-area>
          }@else {
          <div class="profile_desc">
            Product Manager, Bengal Mobile QA Solution. Dhaka Bangladsh
          </div>
          }
        </div>
        <div class="profile_name">
          <div class="profile_title">Date Of Birth</div>
          @if(editMode){
          <dx-date-box
            class="input_field"
            width="100%"
            placeholder="dd/mm/yy"
            [displayFormat]="'dd/MM/yyyy'"
            [showClearButton]="true"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Start Date is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-date-box>
          }@else {
          <div class="profile_desc">08-11-1990</div>
          }
          <div class="button_container">
            <dx-button *ngIf="editMode" class="blue-button-lg">Save</dx-button>
          </div>
        </div>
      </div>
    </div>
    } @if(activeTabIndex === 1){
    <div class="details_part">
      <div class="left_details">
        <div class="left_details_title">Profile Visibility</div>
        <div class="profile_name">
          <div class="profile_title">
            Control who can view your profile information
          </div>
          @if(editMode){
          <dx-select-box
            class="input_field"
            [dataSource]="['Public', 'Private', 'Custom']"
            placeholder="Select..."
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="select an option"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
          }@else {
          <div class="profile_desc">Public</div>
          }
        </div>
        <div class="profile_name">
          <div class="profile_title">Activity Visibility</div>
          @if(editMode){
          <div class="switch_list">
            <div
              class="toggle-container cursor"
              (click)="
                onToggleChange(
                  'recentDonationSwitchButton',
                  !toggleStates.recentDonationSwitchButton
                )
              "
            >
              <img
                [ngSrc]="
                  toggleStates.recentDonationSwitchButton
                    ? '../../../../../assets/images/icon/toggle_active.svg'
                    : '../../../../../assets/images/icon/toggle_inactive.svg'
                "
                width="31"
                height="31"
                alt="toggle_button"
              />
              <span>Show my recent donations</span>
            </div>
            <div
              class="toggle-container"
              (click)="
                onToggleChange(
                  'campaignsSwitchButton',
                  !toggleStates.campaignsSwitchButton
                )
              "
            >
              <img
                [ngSrc]="
                  toggleStates.campaignsSwitchButton
                    ? '../../../../../assets/images/icon/toggle_active.svg'
                    : '../../../../../assets/images/icon/toggle_inactive.svg'
                "
                width="31"
                height="31"
                alt="toggle_button"
              />
              <span>Campaigns</span>
            </div>
            <div
              class="toggle-container"
              (click)="
                onToggleChange(
                  'activityToOtherUserSwitchButton',
                  !toggleStates.activityToOtherUserSwitchButton
                )
              "
            >
              <img
                [ngSrc]="
                  toggleStates.activityToOtherUserSwitchButton
                    ? '../../../../../assets/images/icon/toggle_active.svg'
                    : '../../../../../assets/images/icon/toggle_inactive.svg'
                "
                width="31"
                height="31"
                alt="toggle_button"
              />
              <span>Activities to other users.</span>
            </div>
          </div>
          }@else {
          <div class="profile_desc">Show my recent donations</div>
          }
        </div>
      </div>
      <div class="right_details">
        <div class="left_details_title">Notifications and Preferences</div>
        <div class="profile_name">
          <div class="profile_title">Language Preference</div>
          @if(editMode){
          <dx-select-box
            class="input_field"
            [dataSource]="['English', 'Bangla', 'Custom']"
            placeholder="Select..."
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="select an option"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
          }@else {
          <div class="profile_desc">English</div>
          }
        </div>
        <div class="profile_name">
          <div class="profile_title">Email Notification</div>
          @if(editMode){
          <div class="switch_list">
            <div
              class="toggle-container"
              (click)="
                onToggleChange(
                  'receiveShohayUpdateSwitchButton',
                  !toggleStates.receiveShohayUpdateSwitchButton
                )
              "
            >
              <img
                [ngSrc]="
                  toggleStates.receiveShohayUpdateSwitchButton
                    ? '../../../../../assets/images/icon/toggle_active.svg'
                    : '../../../../../assets/images/icon/toggle_inactive.svg'
                "
                width="31"
                height="31"
                alt="toggle_button"
              />
              <span>Receive Shohay platform updates</span>
            </div>
            <div
              class="toggle-container"
              (click)="
                onToggleChange(
                  'campaignAlertsSwitchButton',
                  !toggleStates.campaignAlertsSwitchButton
                )
              "
            >
              <img
                [ngSrc]="
                  toggleStates.campaignAlertsSwitchButton
                    ? '../../../../../assets/images/icon/toggle_active.svg'
                    : '../../../../../assets/images/icon/toggle_inactive.svg'
                "
                width="31"
                height="31"
                alt="toggle_button"
              />
              <span>Campaign alerts</span>
            </div>
          </div>
          }@else {
          <div class="profile_desc">Show my recent donations</div>
          }
        </div>
        <div class="profile_name">
          <div class="profile_title">SMS Notification</div>
          @if(editMode){
          <div class="switch_list">
            <div
              class="toggle-container"
              (click)="
                onToggleChange(
                  'smsNotificationSwitchButton',
                  !toggleStates.smsNotificationSwitchButton
                )
              "
            >
              <img
                [ngSrc]="
                  toggleStates.smsNotificationSwitchButton
                    ? '../../../../../assets/images/icon/toggle_active.svg'
                    : '../../../../../assets/images/icon/toggle_inactive.svg'
                "
                width="31"
                height="31"
                alt="toggle_button"
              />
              <span>Receive SMS alerts for campaigns</span>
            </div>
            <div
              class="toggle-container"
              (click)="
                onToggleChange(
                  'milestonesSwitchButton',
                  !toggleStates.milestonesSwitchButton
                )
              "
            >
              <img
                [ngSrc]="
                  toggleStates.milestonesSwitchButton
                    ? '../../../../../assets/images/icon/toggle_active.svg'
                    : '../../../../../assets/images/icon/toggle_inactive.svg'
                "
                width="31"
                height="31"
                alt="toggle_button"
              />
              <span>Milestones</span>
            </div>
            <div
              class="toggle-container"
              (click)="
                onToggleChange(
                  'donationRemindersSwitchButton',
                  !toggleStates.donationRemindersSwitchButton
                )
              "
            >
              <img
                [ngSrc]="
                  toggleStates.donationRemindersSwitchButton
                    ? '../../../../../assets/images/icon/toggle_active.svg'
                    : '../../../../../assets/images/icon/toggle_inactive.svg'
                "
                width="31"
                height="31"
                alt="toggle_button"
              />
              <span>Donation reminders</span>
            </div>
          </div>
          }@else {
          <div class="profile_desc">Show my recent donations</div>
          }
        </div>
        <div class="button_container">
          <dx-button *ngIf="editMode" class="blue-button-lg">Save</dx-button>
        </div>
      </div>
    </div>
    }@if(activeTabIndex === 2){
    <div class="details_part">
      <div class="left_details">
        <div class="left_details_title">Profile Visibility</div>
        <div class="profile_name">
          @if(editMode){
          <dx-radio-group
            class="radio_group_field"
            [items]="[{ name: 'Deactivate Account', value: true }]"
            displayExpr="name"
            valueExpr="value"
            layout="horizontal"
          >
            <dx-validator validationGroup="validationGrp"> </dx-validator>
          </dx-radio-group>
          <p class="deactivate_desc">
            Deactivating your account is temporary. Your account and profile
            will be deactivated and your name and will be removed from most
            campaigns .
          </p>
          }@else {
          <div class="profile_title">Deactivate Account</div>
          <div class="profile_desc">Public</div>
          }
        </div>
      </div>
      <div class="right_details last_right">
        <div class="left_details_title">Profile Visibility</div>
        <div class="profile_name">
          @if(editMode){
          <dx-radio-group
            class="radio_group_field"
            [items]="[{ name: 'Deactivate Account', value: true }]"
            displayExpr="name"
            valueExpr="value"
            layout="horizontal"
          >
            <dx-validator validationGroup="validationGrp"> </dx-validator>
          </dx-radio-group>
          <p class="deactivate_desc">
            Deleting your account is permanent. When you delete your Shohay
            account, you won't be able to retrieve the content or information
            you've shared on Shohay.
          </p>
          }@else {
          <div class="profile_title">Deactivate Account</div>
          <div class="profile_desc">Public</div>
          }
        </div>
        <div class="button_container">
          <dx-button (click)="deleteButton()" class="yellow-button-lg">
            Cancel
          </dx-button>
          <dx-button (click)="deactivateButton()" class="red-button-md">
            Continue to Account Deactivation
          </dx-button>
        </div>
      </div>
    </div>
    }
  </div>
</div>

<dx-popup
  [maxWidth]="721"
  [height]="'auto'"
  [(visible)]="showDeactivatePopup"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="true"
>
  <!-- [(visible)]="isSuccessful" -->
  <div class="root">
    <div class="bookmarks">
      <img
        class="icons8-no-bookmark-100-1"
        loading="lazy"
        alt=""
        src="../../../../assets/images/icon/flight-mode 1.svg"
      />
    </div>
    <section class="you-havent-bookmarked">
      Are you really want to Deactivate your account ?
    </section>
    <div class="feedback">You can recover anytime</div>
    <!-- [routerLink]="['dashboard/form-preview']"
  [queryParams]="{ id: formIdOnParam }" -->
    <div class="bookmarks">
      <div class="yellow-button-lg cursor">
        <!-- (click)="cancelSuccessPopup()" -->
        Cancel
      </div>
      <div (click)="confirmDelete()" class="blue-button-lg cursor">
        <!-- (click)="cancelSuccessPopup()" -->
        Continue
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [maxWidth]="721"
  [height]="'auto'"
  [(visible)]="showDeletePopup"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="true"
>
  <!-- [(visible)]="isSuccessful" -->
  <div class="root">
    <div class="bookmarks">
      <img
        class="icons8-no-bookmark-100-1"
        loading="lazy"
        alt=""
        src="../../../../assets/images/icon/trash-bin.svg"
      />
    </div>
    <section class="you-havent-bookmarked">
      Are you really want to delete your account ?
    </section>
    <div class="feedback">Account can’t be restore</div>
    <!-- [routerLink]="['dashboard/form-preview']"
  [queryParams]="{ id: formIdOnParam }" -->
    <div class="bookmarks">
      <div class="yellow-button-lg cursor">
        <!-- (click)="cancelSuccessPopup()" -->
        Cancel
      </div>
      <div class="blue-button-lg cursor">
        <!-- (click)="cancelSuccessPopup()" -->
        Continue
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [maxWidth]="721"
  [height]="'auto'"
  [(visible)]="showConfirmDeletePopup"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="true"
>
  <!-- [(visible)]="isSuccessful" -->
  <div class="root">
    <!-- <div class="bookmarks">
      <img
        class="icons8-no-bookmark-100-1"
        loading="lazy"
        alt=""
        src="../../../../assets/images/icon/trash-bin.svg"
      />
    </div> -->
    <section class="you-havent-bookmarked">
      Please Confirm with your password
    </section>
    <!-- <div class="feedback">Account can’t be restore</div> -->
    <!-- [routerLink]="['dashboard/form-preview']"
  [queryParams]="{ id: formIdOnParam }" -->

    <!-- [mode]="isNewPasswordVisible ? 'text' : 'password'" -->
    <!-- label="Password" -->
    <dx-text-box
      class="custom-fill_"
      labelMode="static"
      [inputAttr]="{ 'aria-label': 'Password' }"
      [buttons]="[
        {
          name: 'togglePassword',
          location: 'after',
          options: {
            icon: 'eye',
            onClick: toggleNewPasswordVisibility,
            elementAttr: {
              class:
                'password-toggle ' + (isNewPasswordVisible ? 'visible' : '')
            }
          }
        }
      ]"
    >
      <dx-validator validationGroup="validationGrp">
        <dxi-validation-rule
          type="required"
          message="New Password is required"
        ></dxi-validation-rule>
        <!-- <dxi-validation-rule
        type="pattern"
        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
        message="Passwords must be at least 8 characters long and contain a mix of upper and lower case letters, numbers, and special characters(@$!%*?&)."
      ></dxi-validation-rule> -->
      </dx-validator>
    </dx-text-box>

    <div class="bookmarks">
      <div class="blue-button-lg cursor">
        <!-- (click)="cancelSuccessPopup()" -->
        Continue
      </div>
    </div>
  </div>
</dx-popup>
