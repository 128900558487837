import { environment } from "src/environments/environment";

export const STRAPI_URL = `${environment.apiURL}/shohay-blog/images/`;

export enum UrlEnum {
  SHOHAY_ELASTIC = 'shohay-elastic/api/v1',
  SHOHAY_AUTH = 'shohay-auth/api/v1',
  SHOHAY_BLOG = 'shohay-blog/api',
  SHOHAY_NGO = 'shohay-ngo/api/v1',
  SHOHAY_SURVEY = 'shohay-survey/api',
  SHOHAY_CAMPAIGN = 'shohay-ngo/api/v1',
  SHOHAY_FILE = 'shohay-file/api/v1',
}

