import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';
import { CommonSharedServiceService } from 'src/app/shared/shared-services/common-shared-service/common-shared-service.service';
import { I18nServiceService } from 'src/app/shared/shared-services/i18n-service/i18n-service.service';

@Component({
  selector: 'app-marketing-header',
  templateUrl: './marketing-header.component.html',
  styleUrls: ['./marketing-header.component.css'],
})
export class MarketingHeaderComponent implements OnInit {
  private viewportWidth: number;
  public sessionUser: any;

  public searchValue: string = '';
  public isModalOpen: boolean = false;
  public showSearchModal: boolean = false;
  public searchResults: any[] = [];

  public isLoggedIn: boolean = false;
  public showHamburger: boolean = false;
  public isMobileView: boolean = false;
  public showLangBtn: boolean = true;
  public verticalNavbar: boolean = false;

  public profileSettings: any[] = [
    { value: 1, name: 'Change Password', icon: 'edit' },
    { value: 2, name: 'Log Out', icon: 'runner' },
  ];

  constructor(
    public readonly router: Router,
    private readonly authService: AuthService,
    private readonly translate: TranslateService,
    private readonly i18nService: I18nServiceService,
    private readonly shohayBlogService: ShohayBlogService,
    private readonly localStorageService: LocalStorageService,
    private readonly sharedServices: CommonSharedServiceService
  ) {
    this.viewportWidth = window.innerWidth;
    this.showHamburger = this.viewportWidth < 1200 ? true : false;
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.viewportWidth = window.innerWidth;
    this.showHamburger = this.viewportWidth < 1081 ? true : false;
    this.showLangBtn = this.viewportWidth < 745 ? false : true;
    this.isMobileView = this.viewportWidth < 655 ? true : false;
    if (!this.showHamburger) {
      this.verticalNavbar = false;
    }
  }

  public ngOnInit(): void {
    this.sharedServices.isLoggedIn$.subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
      this.sessionUser = this.localStorageService.getSessionUser();
    });

    if (window.innerWidth < 455) {
      this.isMobileView = true;
    }
    this.i18nService.localeEvent.subscribe((lang) => this.translate.use(lang));
  }

  profileDropDownButtonClick(e: any): void {
    if (e.itemData.value === 1) {
      this.router.navigateByUrl('/auth/change-password');
    } else {
      this.authService.logout();
      this.isLoggedIn = false;
      this.sessionUser = null;
    }
  }

  searchModal(value: boolean): void {
    this.searchValue = '';
    this.showSearchModal = value;
    this.isModalOpen = value;
    this.shohayBlogService.getArticleNews(1, 12).subscribe((result) => {
      this.searchResults = result.data;
    });
  }

  get filteredResults(): any[] {
    return this.searchResults.filter(
      (item) =>
        item.title.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        item.topic.toLowerCase().includes(this.searchValue.toLowerCase())
    );
  }

  public seeAllResult(): void {
    this.showSearchModal = false;
    this.router.navigateByUrl('/all-news-articles');
  }

  public goToHomePage(): void {
    this.router.navigate(['/']);
  }

  public showVerticalNavbar(): void {
    this.verticalNavbar = !this.verticalNavbar;
  }

  public changeLang(language: string): void {
    this.i18nService.changeLanguage(language);
  }

  public getText(): string {
    if (this.isLoggedIn) {
      return this.translate.instant('Header.Upgrade');
    } else {
      return this.translate.instant('Header.Contact');
    }
  }

  public logOut(): void {
    this.authService.logout();
    this.isLoggedIn = false;
    this.router.navigateByUrl('/');
  }

  public searchIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/find_icon.svg',
    stylingMode: 'text',
  };

  public goToArticleNews(id: string, newsArticleId: string): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: id,
      newsArticleId: newsArticleId,
    });
    this.router.navigate(['/news-article'], {
      queryParams: { data: encryptedParams },
    });
    this.searchModal(false);
  }
}
