<div class="main_div">
  <div class="workplace">Workplace</div>
  <div class="manage_operations_text">Manage Operations</div>

  <div class="manage_tools_card_container row">
    <div
      class="manage_tools_card col-1 bg-white"
      style="cursor: pointer"
      [routerLink]="'/admin/campaign-management/all-donation-campaigns'"
    >
      <div class="col_content d-flex justify-content-center flex-column">
        <img
          style="width: fit-content !important"
          src="../../../assets/images/icon/donor_colorful_icon.svg"
        />
        <div class="name_arrow d-flex justify-content-between">
          <span>Donation Campaigns</span>
          <img src="./assets/images/icon/chevron-forward.svg"/>
        </div>
      </div>
    </div>
  </div>
</div>
