import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { CorporateCampaignListComponent } from './corporate-campaign/corporate-campaign-list/corporate-campaign-list.component';
import { CorporateRoutingModule } from './corporate-routing.module';
import { CorporateMenuComponent } from 'src/app/layout/logged-in-layout/corporate-menu/corporate-menu.component';
import { CorporateCreateCampaignComponent } from './corporate-campaign/corporate-create-campaign/corporate-create-campaign.component';
import { CorporateEmployeeManagementComponent } from './corporate-employee-management/corporate-employee-management.component';
import { CorporateInviteEmployeeComponent } from './corporate-invite-employee/corporate-invite-employee.component';
import { CorporateCreateEmployeeAccountComponent } from './corporate-create-employee-account/corporate-create-employee-account.component';

@NgModule({
  declarations: [CorporateMenuComponent, CorporateCampaignListComponent, CorporateCreateCampaignComponent, CorporateEmployeeManagementComponent, CorporateInviteEmployeeComponent, CorporateCreateEmployeeAccountComponent],
  imports: [
    CorporateRoutingModule,
    CommonModule,
    DevExtremeModule,
    FormsModule,
    SharedModule,
  ],
})
export class CorporateModule {}
