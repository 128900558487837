[
  {
    "index": 1,
    "id": null,
    "href": null,
    "routerLink": "/admin/workplace",
    "name": "Workplace",
    "activeIcon": "./assets/images/icon/home_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/home_icon_inactive.svg",
    "submenu": []
  },
  {
    "index": 2,
    "id": "submenu2",
    "href": "#submenu2",
    "routerLink": null,
    "name": "NGO Management",
    "activeIcon": "./assets/images/icon/learn_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/learn_icon_inactive.svg",
    "submenu": [
      {
        "id": "submenu2",
        "index": 21,
        "routerLink": "/admin/ngo-management/all-ngos",
        "name": "All NGO",
        "activeIcon": "./assets/images/icon/workplace_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/workplace_icon_inactive.svg"
      },
      {
        "id": "submenu2",
        "index": 22,
        "routerLink": "/admin/ngo-management/ngo-approval-request",
        "name": "Requested NGOs",
        "activeIcon": "./assets/images/icon/news_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/news_icon_inactive.svg"
      }
    ]
  },
  {
    "index": 3,
    "id": "submenu3",
    "href": "#submenu3",
    "routerLink": null,
    "name": "Campaign Management",
    "activeIcon": "./assets/images/icon/event 1.svg",
    "inactiveIcon": "./assets/images/icon/event2.svg",
    "submenu": [
      {
        "id": "submenu3",
        "index": 31,
        "routerLink": "/admin/campaign-management/all-donation-campaigns",
        "name": "All Campaign",
        "activeIcon": "./assets/images/icon/lists-active.svg",
        "inactiveIcon": "./assets/images/icon/lists.svg"
      },
      {
        "id": "submenu3",
        "index": 32,
        "routerLink": "/admin/campaign-management/all-featured-campaign",
        "name": "Featured Campaign",
        "activeIcon": "./assets/images/icon/featured-active.svg",
        "inactiveIcon": "./assets/images/icon/featured-inactive.svg"
      },
      {
        "id": "submenu3",
        "index": 33,
        "routerLink": "/admin/campaign-management/all-requested-campaign",
        "name": "Requested Campaigns",
        "activeIcon": "./assets/images/icon/request-active.svg",
        "inactiveIcon": "./assets/images/icon/request-inactive.svg"
      }
    ]
  },
  {
    "index": 4,
    "id": "submenu4",
    "href": "#submenu4",
    "routerLink": null,
    "name": "Donation Management",
    "activeIcon": "./assets/images/icon/charity_active_icon.svg",
    "inactiveIcon": "./assets/images/icon/charity_inactive_icon.svg",
    "submenu": [
      {
        "id": "submenu4",
        "index": 41,
        "routerLink": "/admin/donation-management/all-donor",
        "name": "All Donor",
        "activeIcon": "./assets/images/icon/lists-active.svg",
        "inactiveIcon": "./assets/images/icon/lists.svg"
      },
      {
        "id": "submenu4",
        "index": 42,
        "routerLink": "/admin/donation-management/all-fundraisers",
        "name": "All Fundraiser",
        "activeIcon": "./assets/images/icon/fundraiser_active_icon.svg",
        "inactiveIcon": "./assets/images/icon/fundraiser_inactive_icon.svg"
      },
      {
        "id": "submenu4",
        "index": 43,
        "routerLink": "/admin/donation-management/all-donations",
        "name": "All Donations",
        "activeIcon": "./assets/images/icon/lists-active.svg",
        "inactiveIcon": "./assets/images/icon/lists.svg"
      }
    ]
  },
  {
    "index": 5,
    "id": null,
    "href": null,
    "routerLink": "/admin/contact-requests",
    "name": "Call/Message Requests",
    "activeIcon": "./assets/images/icon/news_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/news_icon_inactive.svg",
    "submenu": [
    ]
  }
]