import { Component } from '@angular/core';

@Component({
  selector: 'app-corporate-create-employee-account',
  templateUrl: './corporate-create-employee-account.component.html',
  styleUrl: './corporate-create-employee-account.component.scss',
})
export class CorporateCreateEmployeeAccountComponent {
  public allFundraisers = [
    {
      slNo: 1,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 2,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 3,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 4,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 5,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 6,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 7,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 8,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 9,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 10,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 11,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
  ];

  public showCredentials: boolean = false;
  public credentials: any;

  constructor() {}
}
