<div class="main_div">
  <div class="breadcrumb">
    <span class="donation_title">Donation Management / All Donor/ </span>
    <span> {{ donorInfo.name }} </span>
  </div>

  <div class="nav_section cursor">
    <div
      class="counter-wrapper"
      [routerLink]="'/admin/donation-management/all-donor'"
    >
      <img
        class="arrow-back-icon cursor"
        loading="lazy"
        alt=""
        src="../../../../../../assets/images/icon/arrow_back_icon.svg"
      />
      <a class="back-to-question cursor">Back to List</a>
    </div>
  </div>

  <div class="main_body">
    <div class="contact_profile">
      <div class="title_main_body">Contact Profile</div>
      <div class="upper_part">
        <div class="profile-avatar">
          <img
            class="profile_img"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/profile_icon_gray.png'"
            alt="profile_pic"
          />
        </div>
        <div class="donor_name">{{ donorInfo.name }}</div>
        <div class="donations">
          {{ donorInfo.total_donation }} Donation - ৳
          {{ donorInfo.total_amount }}
        </div>
        <div class="donations bottom">
          Last donation -
          {{ donorInfo?.updated_at | date : "MM-dd-yyyy  h:mm a" }}
        </div>
        <div class="email">{{ donorInfo.email }}</div>
      </div>
      <div class="lower_part">
        <!-- [icon]="'./assets/images/icon/more_vertical_icon.svg'"  src="./assets/images/icon/whatsapp_icon.svg"-->
        <dx-drop-down-button
          class="drop_down_button"
          width="100%"
          [dropDownOptions]="{ width: '100%' }"
          [showArrowIcon]="true"
          text="Donors Contact Details"
          displayExpr="text"
          [items]="[
            {
              text: donorInfo?.number || 'Not Available',
              icon: './assets/images/icon/whatsapp_icon.svg'
            }
          ]"
        ></dx-drop-down-button>
        <dx-drop-down-button
          class="drop_down_button"
          width="100%"
          [dropDownOptions]="{ width: '100%' }"
          [showArrowIcon]="true"
          text="Fundraising Team"
          displayExpr="text"
          [items]="teamItems"
        ></dx-drop-down-button>
        <dx-drop-down-button
          class="drop_down_button"
          width="100%"
          [dropDownOptions]="{ width: '100%' }"
          [showArrowIcon]="true"
          text="Donation Payment Method"
          displayExpr="text"
          [items]="[
            {
              text: 'Bkash',
              icon: './assets/images/icon/whatsapp_icon.svg'
            }
          ]"
        ></dx-drop-down-button>
      </div>
    </div>
    <div class="recent_donations">
      <div class="title_main_body">Recent Donations</div>
      <div class="grid-container">
        <div class="col-12">
          <div class="top_donor_div pr-0">
            <div class="donor_div">
              <dx-data-grid
                class="chart_div data_grid"
                [dataSource]="recentDonations"
                [showBorders]="false"
                [showColumnLines]="false"
                [showRowLines]="false"
                [allowColumnResizing]="true"
                columnResizingMode="widget"
                [focusedRowEnabled]="false"
                [rowAlternationEnabled]="false"
                rowTemplate="rowTemplateName"
                [wordWrapEnabled]="true"
                [columnAutoWidth]="true"
              >
                <dxi-column
                  width="211"
                  caption="Date"
                  dataField="date"
                ></dxi-column>
                <dxi-column
                  max-width="292"
                  caption="Campaign"
                  dataField="campaign"
                >
                </dxi-column>
                <dxi-column
                  max-width="300"
                  caption="Comments"
                  dataField="Comments"
                >
                </dxi-column>
                <dxi-column max-width="107" caption="Amount" alignment="center">
                </dxi-column>

                <tbody
                  class="dx-row"
                  *dxTemplate="let row of 'rowTemplateName'"
                >
                  <tr class="custom-row">
                    <td>
                      <div class="date_div bold">
                        {{ row?.data?.created_at | date : "MM-dd-yyyy" }}
                      </div>
                      <div class="date_div">
                        {{ row?.data?.created_at | date : "h:mm a" }}
                      </div>
                    </td>
                    <td class="campaign_name">
                      {{ row?.data?.campaign_title }}
                    </td>
                    <td>
                      {{ row?.data?.comment }}
                      <!-- <div class="left_side_property" style="margin: 12px">
                        <div class="name_amount">
                          <div class="name">{{ row?.data?.comments }}</div>
                        </div>
                      </div>
                      {{ row?.data?.created_at }} -->
                    </td>
                    <td>
                      <div class="amount_div">৳ {{ row?.data?.amount }}</div>
                    </td>
                  </tr>
                </tbody>
              </dx-data-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
