import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
@Component({
  selector: 'app-donor-list',
  templateUrl: './donor-list.component.html',
  styleUrl: './donor-list.component.scss',
})
export class DonorListComponent {
  public currentPage = 1;
  public totalPages = 1;
  public donorList: any[] = [];

  constructor(
    private readonly router: Router,
    private readonly spinner: NgxSpinnerService,
    private readonly donationService: DonationService,
    private readonly localStorageService: LocalStorageService, 
  ) {}

  ngOnInit(): void {
    this.loadDonorPage(this.currentPage);
  }

  public getTeams(teams: any[]): any[] {
    return teams.slice(0, 4);
  }

  public makeUrlParsed(teamsLogo: string): string {
    return JSON.parse(teamsLogo).url;
  }

  private loadDonorPage(page: number) {
    this.spinner.show();
    this.donationService.getAllDonorWithPagination(page, 7).subscribe({
      next: (response) => {
        this.totalPages = Math.ceil(response.total / 7);
        this.donorList = response.data;
        this.spinner.hide();
      }
    });
  }

  public navigateToDonorProfile(user_id: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      user_id: user_id,
    });
    this.router.navigate(['/admin/donation-management/donor-profile'], {
      queryParams: { data: encryptedParams },
    });
  }

  public goToPage(page: number) {
    if (page > 0 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadDonorPage(this.currentPage);
    }
  }

  public goToPrevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadDonorPage(this.currentPage);
    }
  }

  public goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loadDonorPage(this.currentPage);
    }
  }

  public get pages() {
    const pages = [];
    const maxVisiblePages = 3;

    if (this.totalPages <= 10) {
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (this.currentPage > maxVisiblePages + 1) {
        pages.push('....');
      }

      const startPage = Math.max(2, this.currentPage - 2);
      const endPage = Math.min(this.totalPages - 1, this.currentPage + 2);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (this.currentPage < this.totalPages - (maxVisiblePages + 1)) {
        pages.push('...');
      }
      pages.push(this.totalPages);
    }
    return pages;
  }
}
