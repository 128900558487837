import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { NgoService } from 'src/app/shared/services/ngo/ngo.service';

@Component({
  selector: 'app-ngo-search',
  templateUrl: './ngo-search.component.html',
  styleUrls: ['./ngo-search.component.css'],
})
export class NgoSearchComponent implements OnInit {
  ngoSearchQueryTitle: string = '';
  ngoSearchQuery: string = '';
  ngoList: any[] = [];

  constructor(
    private readonly ngoService: NgoService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.ngoSearchQueryTitle = this.ngoSearchQuery = params['query'];
      if (this.ngoSearchQuery) {
        this.onSearchClick();
      }
    });
  }

  public onSearchClick(): void {
    if (!this.ngoSearchQuery) {
      return;
    }

    this.updateQueryParams(this.ngoSearchQuery);

    this.ngoList = [];
    this.ngoService.searchNgo(this.ngoSearchQuery).subscribe((result) => {
      if (result) {
        this.ngoList = result;
      }
    });
  }

  private updateQueryParams(query: string) {
    const urlTree = this.router.parseUrl(this.router.url);

    urlTree.queryParams['query'] = query;

    const updatedUrl = this.router.serializeUrl(urlTree);
    this.router.navigateByUrl(updatedUrl);
  }

  public goToDetailsPage(ngo: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: ngo.id,
    });
    this.router.navigate(['/ngo'], {
      queryParams: { data: encryptedParams },
    });
  }
}
