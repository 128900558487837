<div *ngif="commentsFiltering.length > 0" class="root">
  <div class="news-container">
    <h1 class="stay-updated-with">Campaign wall</h1>
    <div class="empowering-ngos-in">
      View words of support from donors and pictures or videos of their donation
      experience
    </div>
  </div>
  <section class="news-cards">
    <div *ngFor="let comment of commentsFiltering" class="cards">
      <div class="main_body">
        <div class="profile_container">
          <div class="profile">
            <img
              class="img_profile"
              src="../../../../../assets/images/icon/profile_icon_gray.png"
              alt="pro pic"
              loading="lazy"
            />
          </div>
          <div class="name_donation">
            <div class="user_name">
              {{ comment.name }}
            </div>
            <div class="donation_time">
              <div class="donation_amount">৳ {{ comment.amount }}</div>
              <div class="amount_time">{{ comment.date }}</div>
            </div>
          </div>
        </div>
        <div class="body_comment">
          {{ comment.comment }}
        </div>
      </div>
    </div>
  </section>
  <div class="load_comments_container">
    <dx-button class="blue-button-md"> Load More </dx-button>
  </div>
</div>
<!-- (click)="loadMoreComments()" -->
<!-- [disabled]="loadMoreDisabled" -->

