import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NewsArticleTypeEnum } from 'src/app/shared/enum/news-article.enum';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';

@Component({
  selector: 'app-hp2-article-news',
  templateUrl: './hp2-article-news.component.html',
  styleUrls: ['./hp2-article-news.component.css'],
})
export class Hp2ArticleNewsComponent implements OnInit {
  @ViewChild('articleButton') articleButton!: ElementRef;
  @ViewChild('newsButton') newsButton!: ElementRef;

  // private articleNewsOriginalList: any[] = [];
  public articleNewsList: any[] = [];
  public articleList: any[] = [];
  public newsList: any[] = [];

  public newsArticleTypeEnum = NewsArticleTypeEnum;

  constructor(
    private router: Router,
    private readonly shohayBlogService: ShohayBlogService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getArticleNewsList();
  }

  private getArticleNewsList(): void {
    this.shohayBlogService.getArticleNews(1, 12).subscribe((result) => {
      // this.articleNewsOriginalList = result.data.splice(0, 3);

      this.articleList = result.data.filter(
        (item) => item.blog_type === this.newsArticleTypeEnum.ARTICLE
      );

      this.newsList = result.data.filter(
        (item) => item.blog_type === this.newsArticleTypeEnum.NEWS
      );

      this.articleNewsList = this.articleList.slice(0, 3);
      // this.articleNewsList = JSON.parse(
      //   JSON.stringify(this.articleNewsOriginalList)
      // );
    });
  }

  public toggleArticleNews(
    filterValue: string,
    class1: string,
    class2: string
  ): void {
    if (filterValue === this.newsArticleTypeEnum.ARTICLE) {

      this.newsButton.nativeElement.classList.remove(class1);
      this.newsButton.nativeElement.classList.add(class2);
      this.articleButton.nativeElement.classList.remove(class2);
      this.articleButton.nativeElement.classList.add(class1);

      this.articleNewsList = this.articleList.slice(0, 3);

    } else if (filterValue === this.newsArticleTypeEnum.NEWS) {

      this.newsButton.nativeElement.classList.remove(class1);
      this.newsButton.nativeElement.classList.add(class2);
      this.articleButton.nativeElement.classList.remove(class2);
      this.articleButton.nativeElement.classList.add(class1);

      this.articleNewsList = this.newsList.slice(0, 3);
    }
    // this.articleNewsList = this.articleNewsOriginalList.filter(
    //   (article) => article.blog_type === filterValue
    // );
  }

  public goToArticleNews(id: number, newsArticleId: string): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      newsArticleId: newsArticleId,
      id: id,
    });
    this.router.navigate(['/news-article'], {
      queryParams: { data: encryptedParams },
    });
  }
}
