[
  {
    "index": 1,
    "id": null,
    "href": null,
    "routerLink": "/dashboard",
    "name": "Home",
    "activeIcon": "./assets/images/icon/home_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/home_icon_inactive.svg",
    "submenu": []
  },
  {
    "index": 2,
    "id": "submenu2",
    "href": "#submenu2",
    "routerLink": null,
    "name": "Learn",
    "activeIcon": "./assets/images/icon/learn_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/learn_icon_inactive.svg",
    "submenu": [
      {
        "id": "submenu2",
        "index": 21,
        "routerLink": "/dashboard/learn/all-ngo",
        "name": "All NGO",
        "activeIcon": "./assets/images/icon/workplace_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/workplace_icon_inactive.svg"
      },
      {
        "id": "submenu2",
        "index": 22,
        "routerLink": "/dashboard/learn/news-article",
        "name": "News & Articles",
        "activeIcon": "./assets/images/icon/news_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/news_icon_inactive.svg"
      }
    ]
  },
  {
    "index": 3,
    "id": "submenu3",
    "href": "#submenu3",
    "routerLink": null,
    "name": "Donate",
    "activeIcon": "./assets/images/icon/donate_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/donate_icon_inactive.svg",
    "submenu": [
      {
        "id": "submenu3",
        "index": 31,
        "routerLink": "/dashboard/donate/all-campaigns",
        "name": "All Campaigns",
        "activeIcon": "./assets/images/icon/mike_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/mike_icon_inactive.svg"
      },
      {
        "id": "submenu3",
        "index": 32,
        "routerLink": "/dashboard/donate/donation-history",
        "name": "Donation History",
        "activeIcon": "./assets/images/icon/clock_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/clock_icon_inactive.svg"
      },
      {
        "id": "submenu3",
        "index": 33,
        "routerLink": "/dashboard/donate/fundraiser-history",
        "name": "Fundraiser History",
        "activeIcon": "./assets/images/icon/donate_icon1_active.svg",
        "inactiveIcon": "./assets/images/icon/donate_icon1_inactive.svg"
      }
    ]
  },
  {
    "index": 4,
    "id": "submenu4",
    "href": "#submenu4",
    "routerLink": null,
    "name": "Manage",
    "activeIcon": "./assets/images/icon/manage_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/manage_icon_inactive.svg",
    "submenu": [
      {
        "id": "submenu4",
        "index": 41,
        "routerLink": "/dashboard/manage/manage-ngo-npo",
        "name": "My Ngo/NPO",
        "activeIcon": "./assets/images/icon/workplace_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/workplace_icon_inactive.svg"
      },
      {
        "id": "submenu4",
        "index": 42,
        "routerLink": "/dashboard/manage/workplace",
        "name": "Workplace",
        "activeIcon": "./assets/images/icon/workplace_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/workplace_icon_inactive.svg"
      },
      {
        "id": "submenu4",
        "index": 43,
        "routerLink": "/dashboard/manage/all-projects",
        "name": "Projects",
        "activeIcon": "./assets/images/icon/lists-active.svg",
        "inactiveIcon": "./assets/images/icon/lists.svg"
      },
      {
        "id": "submenu4",
        "index": 44,
        "routerLink": "/dashboard/manage/form",
        "name": "Forms",
        "activeIcon": "./assets/images/icon/workplace_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/workplace_icon_inactive.svg"
      },
      {
        "id": "submenu4",
        "index": 45,
        "routerLink": "/dashboard/manage/campaign",
        "name": "Donation Campaigns",
        "activeIcon": "./assets/images/icon/donation_campaigns_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/donation_campaigns_icon_inactive.svg"
      }
    ]
  },
  {
    "index": 5,
    "id": "submenu5",
    "href": "#submenu5",
    "routerLink": null,
    "name": "Donor Portal",
    "activeIcon": "./assets/images/icon/chart_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/chart_icon_inactive.svg",
    "submenu": [
      {
        "id": "submenu5",
        "index": 51,
        "routerLink": "/dashboard/donor/dashboard",
        "name": "Donor Dashboard",
        "activeIcon": "./assets/images/icon/chart_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/chart_icon_inactive.svg"
      },
      {
        "id": "submenu5",
        "index": 52,
        "routerLink": "/dashboard/donor/donation-history",
        "name": "Donation History",
        "activeIcon": "./assets/images/icon/lists-active.svg",
        "inactiveIcon": "./assets/images/icon/lists.svg"
      },
      {
        "id": "submenu5",
        "index": 53,
        "routerLink": "/dashboard/donor/visual-donation",
        "name": "Visual Donation",
        "activeIcon": "./assets/images/icon/donate_hand_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/donate_hand_icon_inactive.svg"
      },
      {
        "id": "submenu5",
        "index": 54,
        "routerLink": "/dashboard/donor/hall-of-fame",
        "name": "Hall of Fame",
        "activeIcon": "./assets/images/icon/trophy_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/trophy_icon_inactive.svg"
      },
      {
        "id": "submenu5",
        "index": 55,
        "routerLink": "/dashboard/donor/campaign-wall",
        "name": "Campaign Wall",
        "activeIcon": "./assets/images/icon/mike_tilted_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/mike_tilted_icon_inactive.svg"
      }
    ]
  },
  {
    "index": 6,
    "id": "submenu6",
    "href": "#submenu6",
    "routerLink": null,
    "name": "Donor CRM",
    "activeIcon": "./assets/images/icon/donor_crm_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/donor_crm_icon_active.svg",
    "submenu": [
      {
        "id": "submenu6",
        "index": 61,
        "routerLink": "/dashboard/donor-crm/dashboard",
        "name": "Donor Dashboard",
        "activeIcon": "./assets/images/icon/dashboard_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/dashboard_icon_inactive.svg"
      },
      {
        "id": "submenu6",
        "index": 62,
        "routerLink": "/dashboard/donor-crm/donor-management",
        "name": "Donor Management",
        "activeIcon": "./assets/images/icon/management_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/management_icon_inactive.svg"
      },
      {
        "id": "submenu6",
        "index": 63,
        "routerLink": "/dashboard/donor-crm/donor-analytics",
        "name": "Donor Analytics",
        "activeIcon": "./assets/images/icon/analyze_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/analyze_icon_inactive.svg"
      },
      {
        "id": "submenu6",
        "index": 64,
        "routerLink": "/dashboard/donor-crm/communication-tools",
        "name": "Communication Tools",
        "activeIcon": "./assets/images/icon/communication_icon_active.svg",
        "inactiveIcon": "./assets/images/icon/communication_icon_inactive.svg"
      }
    ]
  },
  {
    "index": 7,
    "id": null,
    "href": null,
    "routerLink": "/dashboard/donor-profile",
    "name": "Donor Profile",
    "activeIcon": "./assets/images/icon/person-active.svg",
    "inactiveIcon": "./assets/images/icon/person.svg",
    "submenu": []
  }
]
